
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { Loading } from '../loader/Loading';
import { fetchExternalPlacements } from './campaignIdeasAPI';
import SelectCategories from './SelectCategories';
import './campaignIdeas.scss';
import FilterName from './FilterName';
import FilterClient from './FilterClient';
import FilterPlacements from './FilterPlacements';
import FilterDate from './FilterDate';
import FilterCategory from './FilterCategory';
import { useDispatch, useSelector } from 'react-redux';
import { fetchExternalCampaigns, getExternalCampaigns, getLoading, getPerPage, getTotalRows, setPerPage, updateExternalCampaign, setLoading, fetchCategories, getParamsForURLQuery, setColumn, setSortDirection, resetParams } from './campaignIdeasSlice';
import AddLinks from './AddLinks';
import Nav from '../../components/Nav';
import AddCampaign from './AddCampaign';
import { setError } from '../error-handling/errorHandlingSlice';

export default function CampaignIdeas() {

	const dispatch = useDispatch();
	const cancelToken = axios.CancelToken;
    const source = cancelToken.source();
	const [selectedRow, setSelectedRow] = useState(null);
	const [externalPlacements, setExternalPlacements] = useState([]);
	const [showTab, setShowTab] = useState(false);
	const [loadingTab, setLoadingTab] = useState(false);
	const externalCampaigns = useSelector(getExternalCampaigns);
	const perPage = useSelector(getPerPage);
	const totalRows = useSelector(getTotalRows);
	const loading = useSelector(getLoading);
	const params = useSelector(getParamsForURLQuery);
	const [isAddingLinks, setIsAddingLinks] = useState(false);
	const [resetModal, setResetModal] = useState(false);

	useEffect(() => {
		loadData();
		return (() => {
			dispatch(resetParams());
            source.cancel('axios request canceled');
        })
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (selectedRow?.id && !selectedRow?.addingLinks) {
			getExternalPlacements(selectedRow.id);
		}
		return (() => {
            source.cancel('axios request canceled');
			setExternalPlacements([]);
        })
	}, [selectedRow?.id, selectedRow?.addingLinks]); // eslint-disable-line react-hooks/exhaustive-deps
	

	const columns = [{
			name: 'Name',
			selector: row => row.name,
			sortable: true,
			key: 'name',
			cell: row => row.id === 0 ? (<FilterName />) : 
				(<div className="wrapper-row-name"><div onClick={() => onClickNameRow(row)} className="row-name">{row.name}</div></div>),
			width: '700px'
		},
		{
			name: 'Client',
			selector: row => row.client,
			sortable: true,
			key: 'client',
			cell: row => row.id === 0 ? (<FilterClient />) : row.client,
			width: '200px'
		},
		{
			name: 'No. of placements',
			selector: row => row.no_of_placements,
			sortable: true,
			key: 'no_of_placements',
			cell: row => row.id === 0 ? (<FilterPlacements />) : row.no_of_placements,
			width: '150px'
		},
		{
			name: 'First Link Date',
			selector: row => row.first_link_date,
			sortable: true,
			key: 'first_link_date',
			cell: row => row.id === 0 ? (<FilterDate />) : row.first_link_date,
			width: '200px'
			
		},
		{
			name: 'Categories',
			cell: row =>  row.id === 0 ? (<FilterCategory />) : (<SelectCategories  selectedCategories={row.categories} key={row.id} id={row.id}/>),
			style: {
				width: '100%',
				minWidth: '200px'
			},
		}
	];

	const loadExternalCampaigns = async ({page, perPage, sortDirection = null, column = null, searchName = null, searchClient = null, loadParallel = false}) => {
		try {
			await dispatch(fetchExternalCampaigns({perPage, page, source, sortDirection, column, searchName, searchClient, loadParallel})).unwrap();
		} catch (error) {
            if (error?.message !== 'axios request canceled') {
				dispatch(setError(error?.message));
            }
        }
	};

	const loadCategories = async (loadParallel = false) => {
		try {
			await dispatch(fetchCategories({source, loadParallel})).unwrap();
		} catch (error) {
            if (error?.message !== 'axios request canceled') {
                dispatch(setError(error?.message));
            }
        }
	};

	const handlePageChange = page => {
		dispatch(fetchExternalCampaigns({
			...params,
            page: page,
            source}));
	};

	const handlePerRowsChange = (newPerPage, page) => {
		dispatch(fetchExternalCampaigns({
            ...params,
			perPage: newPerPage,
            page: page,
            source}));
		setPerPage(newPerPage);
	};

	const handleSort = (column, sortDirection) => {
		dispatch(fetchExternalCampaigns({
			...params,
            page: 1,
            sortDirection: sortDirection,
            column: column.key,
            source}));
		dispatch(setColumn(column.key));
		dispatch(setSortDirection(sortDirection));
	};

	const loadData = async () => {
		try {
            dispatch(setLoading(true))
            await Promise.all([loadCategories(true), loadExternalCampaigns({page: 1, perPage, loadParallel: true})]);
            dispatch(setLoading(false))
        } catch (error) {
            if (error?.message !== 'axios request canceled') {
                dispatch(setLoading(false))
            }
        }
	}

	const onClickNameRow = async (row) => {
		setIsAddingLinks(false);
		handleRowClicked(row);
		setSelectedRow({...row, 'addingLinks' : false});
		setShowTab('placements');
	}

	const onClickAddingLinks = (row) => {
		setLoadingTab(false);
		handleRowClicked(row);
		setSelectedRow({...row, 'addingLinks' : true});
		setShowTab('adding-links');
		setIsAddingLinks(true);
	}

	const getExternalPlacements = async (id) => {
		try {
			setLoadingTab(true);
			const res = await fetchExternalPlacements(id, source);
			if (res) {
				setLoadingTab(false);
			}
			setExternalPlacements(res);
		} catch (error) {
			// if (selectedRow.addingLinks) {
			// 	setLoadingTab(false);
			// }
			if (error?.message !== 'axios request canceled') {
				dispatch(setError(error?.message));
				setLoadingTab(false);
			}
		}
	}

	const conditionalRowStyles = [
		{
		  when: row => row.toggleSelected,
		  style: {
			border: "2px solid black",
			borderTopLeftRadius: "15px",
			borderBottomLeftRadius: "15px",
			// userSelect: "none",
			// pointerEvents: "none"
		  }
		}
	];

	const handleRowClicked = row => {
		if (row.id !== selectedRow?.id || !externalCampaigns.find(e => e.id === row.id).toggleSelected) {
			const updatedData = externalCampaigns.map(item => {
			if (row.id !== item.id) {
				return {
					...item,
					toggleSelected: false
				};
			}
		
			return {
				...item,
				toggleSelected: !item.toggleSelected
			};
			});
		
			dispatch(updateExternalCampaign(updatedData));
		}
	};
	
	const onCloseTab = () => {
		const updatedData = externalCampaigns.map(item => {
			return {
				...item,
				toggleSelected: false
			};
		});
		dispatch(updateExternalCampaign(updatedData));
		setShowTab(false);
	}

	return (<>
        <AddCampaign resetModal={resetModal} />
		<Nav selectedListItem={{'name': 'Idea Machine'}} spaceBetween={false}>
			{!loading ? <button className="btn btn-primary ms-auto" data-bs-toggle="modal" data-bs-target="#new_campaign" onClick={() => {setResetModal(!resetModal); onCloseTab();}}>Add new campaign</button> : ''}
		</Nav>
		<div style={{ display: showTab ? 'block' : 'none'}} className="wrapper-tab">
			<div className="d-flex ms-2 mt-2 align-baseline">
				<button type="button" className="btn-close me-3 ms-1" onClick={() => onCloseTab()}></button>
				<h5>{`${selectedRow?.name}  -  ${selectedRow?.no_of_placements}`}</h5>
				<button className='btn btn-primary ms-auto' onClick={() => onClickAddingLinks(selectedRow)}>Add Links</button>
			</div>
			<div className="d-flex flex-column ms-3">
				<h6>{selectedRow?.client}</h6>
			</div>
			{!loadingTab ? '' :  <Loading fullScreen="true" />}
			{!loadingTab && isAddingLinks && showTab === 'adding-links' ? <AddLinks selectedRow={selectedRow} setLoadingTab={setLoadingTab} setError={setError} onClickNameRow={onClickNameRow}/> : ''}
			{showTab === 'placements' ? <>
				{externalPlacements?.length > 0 ? <>	
					<div className="table-responsive" style={{margin: '1rem'}}>
					<table className="table table-striped table-campaign-ideas-tab">
						<thead className="sticky-header-table">
							<tr>
								<th scope="col">Date</th>
								<th scope="col" className="text-center">DR</th>
								<th scope="col">Link</th>
							</tr>
						</thead>
						<tbody>
							{externalPlacements?.map((d, index) =>
								<tr key={index}>
									<td>{d.date}</td>
									<td className="text-center">{d.dr}</td>
									<td><a href={d.link} target="_blank" rel="noreferrer">{d.link}</a></td>
								</tr>
							)}
						</tbody>
					</table>
				</div></> : 'Not found'}
			</> : ''}
		</div>
		<DataTable
			className="data-table-ideas"
			title=""
			columns={columns}
			data={externalCampaigns}
			progressPending={loading}
			pagination
			paginationServer
			paginationTotalRows={totalRows}
			paginationPerPage={params.perPage}
			paginationRowsPerPageOptions={[10, 30, 50, 100, 250]}
			// expandableRows 
			// expandableRowsComponent={ExpandedComponent}
			onChangeRowsPerPage={handlePerRowsChange}
			onChangePage={handlePageChange}
			sortServer
			onSort={handleSort}
			// onRowClicked={handleRowClicked}
        	conditionalRowStyles={conditionalRowStyles}
			persistTableHead
			progressComponent={<Loading fullScreen="true" />}
			defaultSortFieldId={3}
			defaultSortAsc={false}
		/>
		</>
	);
}
