import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchItemById, menuItems, resetItems, updateItems, loading } from '../lists/listNavigationSlice';
import { fetchJournalist } from '../lists/listNavigationSlice';
import { useParams } from 'react-router';
import { Loading } from '../loader/Loading';
import AsyncSelect from 'react-select/async';
import {
    fetchCampaignById,
    updateCampaignById,
    pushCampaignsEmails,
    updateCampaignsEmails,
    fetchCampaignsRun,
    resetCampaignsEmails,
    resetCampaign,
    setCampaign,
    unsavedCampaignsEmails
} from './campaignsSlice';
import Nav from '../../components/Nav';
import Select from 'react-select';
import apiClient from '../../services/api';
import axios from 'axios';
import Modal from '../../components/Modal';
import Wrapper from '../../components/Wrapper';
import SidebarRight from '../../components/SidebarRight';
import PreviewEmail from '../../components/PreviewEmail';
import { Statistics } from './Statistics';
import { TableStartedCampaign } from './TableStartedCampaign';
import { fetchPressReleasesById } from '../press-releases/pressReleasesSlice';
import { TableCampaign } from './TableCampaigns';
import { useRef } from 'react';
import { _getUser } from '../auth/loginSlice';
import { setError } from '../error-handling/errorHandlingSlice';

export function Campaigns() {
    const dispatch = useDispatch();
    const [isLoading, setLoading] = useState(false);
    const [isPRLoading, setPRLoading] = useState(false);
    const [isLoadingList, setLoadingList] = useState(false);
    const [isCampaignPRLoading, setCampaignPRLoading] = useState(false);
    const localUnsavedCampaignsEmails = useSelector(unsavedCampaignsEmails);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [clickTracking, setClickTracking] = useState(0);
    const [selectedPressRelease, setPressRelease] = useState('');
    const [pressReleaseOptions, setPressReleaseOptions] = useState(null);
    const [listsOptions, setListsOptions] = useState(null);
    const [selectJournalistValue, setSelectJournalistValue] = useState('');
    const [isRunLoaded, setRunLoaded] = useState(0);
    const [sendDelay, setSendDelay] = useState(2);
    const [sendOnSa, setSendOnSa] = useState(false);
    const [sendOnSu, setSendOnSu] = useState(false);
    const { id } = useParams();
    const [sendUntil, setSendUntil] = useState('');
    const [sendTimes, setSendTimes] = useState([]);
    const cancelToken = axios.CancelToken;
    let source = cancelToken.source();
    const user = useSelector(_getUser);
    const [startDate, setStartDate] = useState('');
    const [selectedCampaign, setSelectedCampaign] = useState(null);
    const localMenuItems = useSelector(menuItems);
    const localLoadingMenuItems = useSelector(loading);

    useEffect(() => {
        const interval = setInterval((selectedCampaign) => {
            if (id && selectedCampaign?.start && !selectedCampaign?.finish) {
                getCampaign(id, localMenuItems);
            }
        }, 600000, selectedCampaign);
        return (() => {
            clearInterval(interval);
        });
    }, [selectedCampaign]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        document.title = 'Campaigns - JournoResearch';
        return (() => {
            setPressReleaseOptions('');
            dispatch(resetItems());
            dispatch(resetCampaignsEmails());
            dispatch(resetCampaign());
        });
    }, [dispatch]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (id) {
            getCampaign(id, localMenuItems);
        } else {
            getCampaign(0, localMenuItems);
        }
        return (() => {
            source.cancel('axios request canceled');
            dispatch(resetCampaignsEmails());
            dispatch(resetCampaign());
            setPressRelease(null);
            setSendUntil('');
            setStartDate('');
            setSendTimes([]);
            dispatch(setError(''));
            setSendDelay(2);
            setSelectedCampaign(null);
            setClickTracking(0);
            setSendOnSa(false);
            setSendOnSu(false);
        });
    }, [dispatch, id, isRunLoaded]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (selectedCampaign?.press_release_id && pressReleaseOptions) {
            getPressRelease(selectedCampaign.press_release_id);
        }
    }, [pressReleaseOptions, selectedCampaign]) // eslint-disable-line react-hooks/exhaustive-deps

    const getCampaign = async (id, menuItems) => {
        try {
            let campaignSelected = menuItems?.children.find(c => c.id === parseInt(id));
            let responseItemById = null;
            if (!campaignSelected || campaignSelected.is_folder) {
                responseItemById = await dispatch(fetchItemById({ id: id, typeOfList: 'campaigns', source})).unwrap();
                campaignSelected = responseItemById?.children.find(c => c.id === parseInt(id));
            }
            if (campaignSelected?.is_folder === 0) {
                setLoading(true);
                const campaigns = await dispatch(fetchCampaignById({ id, source })).unwrap();
                setSelectedCampaign(campaigns);
                if (!campaigns?.start && !campaigns?.finish) {
                    getPressReleases(id);
                }
                setName(campaigns?.from_name || user?.name);
                setEmail(campaigns?.from_email || user.email);
                setClickTracking(campaigns?.click_tracking);
                if (campaigns?.send_delay) {
                    setSendDelay(campaigns.send_delay === 0 ? 2 : campaigns.send_delay);
                }
                setSendOnSa(campaigns?.send_on_sa);
                setSendOnSu(campaigns?.send_on_su);
                if (campaigns?.send_until) {
                    setSendUntil(formatDateShort(campaigns.send_until));
                }
                if (campaigns?.start_date) {
                    setStartDate(formatDateTime(campaigns.start_date, true));
                }
                if (campaigns?.send_times) {
                    const times = campaigns.send_times.map(d => formatTime(d));
                    setSendTimes(times);
                }
                if (campaigns) {
                    setLoading(false);
                }
            }

        } catch (error) {
            if (error?.message !== 'axios request canceled') {
                setLoading(false);
                dispatch(setError(error?.message));
            }
        }
    }

    const getPressReleases = async (id) => {
        try {
        const pressReleaseResponse = await apiClient.get(`/api/press-releases-by-client?campaign_id=${id}`, { cancelToken: source.token});
        const items = pressReleasesFormatAsOptions(pressReleaseResponse?.data);
        setPressReleaseOptions(items);
        } catch (error) {
            dispatch(setError(error?.message));
        }
        
    }

    const modalProp = {
        nameSaveButton: 'Save',
        target: 'lists',
        title: 'Add from list',
        add: () => {
            let lists = listValues?.current?.getValue();
            if (lists?.length > 0) {
                let ids = lists.map(l => l.value);
                return setCampaignsEmailFromLists(ids);
            }

        }
    }

    const sendTimesModalProp = {
        target: 'send_times',
        title: 'Set send times',
        add: async () => {
            const data = {send_times: sendTimes.map(d => formatTimeUTC(d))}
            setLoading(true);
            try {
                const response = await apiClient.put(`/api/campaigns/updateSendTimes/${id}`, data)
                const campaigns = await dispatch(fetchCampaignById({ id, source })).unwrap();
                setCampaign(campaigns);
                const times = response.data.send_times.map(d => formatTime(d));
                setSendTimes(times);
                setLoading(false);
            } catch (error) {
                setLoading(false);
                dispatch(setError(error?.message));
            }
        }
    }

    const setCampaignsEmailFromLists = async (ids) => {
        setLoadingList(true);
        if (ids) {
            try {
                const listItems = await apiClient.get(`/api/list-items/lists/${ids}`);
                let acc = [];
                listItems.data.forEach((l) => {
                    const foundEmail = localUnsavedCampaignsEmails.find(e => (e.email.toLowerCase().trim() === l.email.toLowerCase().trim()));
                    const foundEmailAcc = acc.find(e => (e.email.toLowerCase().trim() === l.email.toLowerCase().trim()));
                    if (!foundEmail && !foundEmailAcc) {
                        acc.push({ first_name: l.first_name, last_name: l.last_name, email: l.email, deleted: 0, notSaved: 1, type: 1, object_id: l.list_id });
                    }
                });
                dispatch(pushCampaignsEmails(acc));
                setLoadingList(false);
            } catch (error) {
                setLoadingList(false);
                dispatch(setError(error?.response?.data?.message));
            }
        }
    };

    const getPressReleaseValue = (id, options) => {
        let value = id;
        if (selectedPressRelease?.id) {
            value = selectedPressRelease.id;
        }
        let option = options.find(o => o.value === value);
        return option;
    }

    const formatObjCampaign = () => {
        return {
            'from_email': email,
            'from_name': name,
            'name': selectedCampaign.name,
            'parent_id': selectedCampaign.parent_id,
            'user_id': selectedCampaign.user_id,
            'press_release_id': getPressReleaseValue(selectedCampaign.press_release_id, pressReleaseOptions)?.value,
            'is_folder': 0,
            'click_tracking': clickTracking,
            'send_delay': sendDelay,
            'send_on_sa': sendOnSa,
            'send_on_su': sendOnSu,
            'send_until': selectedPressRelease?.relentless_mode && sendUntil?.length !== 0 ? formatDateShort(sendUntil) : null,
            'start_date': startDate?.length !== 0 ? formatDateTime(startDate) : null
        }
    }

    const saveCampaignDetails = async () => {
        const data = formatObjCampaign();
        setLoading(true);
        try {
            await dispatch(updateCampaignById({ id, data })).unwrap();
            await saveCampaignEmails();
            dispatch(updateItems({id: id, count: localUnsavedCampaignsEmails.filter(l => !l.deleted).length}))
            setLoading(false);
        } catch (error) {
            setLoading(false);
            dispatch(setError(error?.message));
        }
    };

    const saveCampaignEmails = async () => {
        await dispatch(updateCampaignsEmails({ id: id, data: localUnsavedCampaignsEmails})).unwrap();
    }

    const sendCampaignsEmails = async () => {
        setLoading(true);
        const data = formatObjCampaign();
        data.before_send = 1;
        try {
            await dispatch(updateCampaignById({ id, data })).unwrap();
            await saveCampaignEmails();
            await dispatch(fetchCampaignsRun(id)).unwrap();
            await getCampaign(id, localMenuItems);
            dispatch(updateItems({id: id, start: 1, count: localUnsavedCampaignsEmails.filter(l => !l.deleted).length}));
            setLoading(false);
        } catch (error) {
            if (error?.message !== 'axios request canceled') {
                setLoading(false);
                dispatch(setError(error?.message));
            }
        }
    };

    const resetListSelect = () => {
        listValues.current.clearValue();
    }

    const itemsFormatAsOptions = (items) => {
        if (!items) return [];
        return items.reduce( (acc, item) => acc =[...acc, { name: item.name, value: item.id, label: item.name + item.path, count: item.count, path: item.path }], [])
    };

    const pressReleasesFormatAsOptions = (items) => {
        if (!items) return [];
        return items.reduce( (acc, item) => acc =[...acc, { name: item.name, value: item.id, label: item.name + item.path, path: item.path, relentless_mode: item.relentless_mode}], [])
    };

    const formatOptionLabel = ({ count, path, name }) => (
        <div>
            <div className="path-select">{path}</div>
            <div style={{ display: "flex" }}>
                <div>{name}</div>
                <div style={{ marginLeft: "auto", whiteSpace: "nowrap" }}>
                    {count}<i className="ms-1 bi bi-person-fill"></i>
                </div>
            </div>
        </div>
    );

    const formatOptionJournalistLabel = ({ first_name, last_name, outlet }) => (
        <div style={{ display: "flex" }}>
            <div className="wrapper-outlet">{first_name}, {last_name} {outlet ? <><div className='ellipsis-select'>({outlet}</div>)</>: ''}</div>
            <div style={{ marginLeft: "auto" }}>
                <i className="ms-1 bi bi-person-fill"></i>
            </div>
        </div>
    );

    const formatOptionPressReleaseLabel = ({label, path, name}) => (
        <div>
            <div className="path-select">{path}</div>
            <div>{name}</div>
        </div>
    );

    const formatDateTime = (d, input = false) => {
        let date = new Date(d);
        let string;
        if (input) {
            date.setMinutes(date.getMinutes() - date.getTimezoneOffset());
            string = date.toISOString();
            string = string.substring(0, (string.indexOf("T")|0) + 6|0);

        } else {
            string = date.toISOString();
        }
        return string;
    }

    const formatTime = (time) => {
        const data = new Date(formatDateShort() + 'T' + time + ':00.000000Z')
        const hour = ("0" + data.getHours()).slice(-2);
        const minutes = ("0" + data.getMinutes()).slice(-2);
        return hour + ':' + minutes;
    }

    const formatTimeUTC = (time) => {
        const data = new Date(formatDateShort() + 'T' + time + ':00')
        const hour = ("0" + data.getUTCHours()).slice(-2);
        const minutes = ("0" + data.getUTCMinutes()).slice(-2);
        return hour + ':' + minutes;
    }

    const selectJournalist = {
        title: 'Add journalist',
        target: 'select_journalist',
        nameSaveButton: 'Add',
        add: () => {
            if (selectJournalistValue?.email) {
                const foundEmail = localUnsavedCampaignsEmails.find(e => (e?.email?.toLowerCase().trim() === selectJournalistValue.email.toLowerCase().trim()));
                if (!foundEmail) {
                    dispatch(pushCampaignsEmails([{ 
                        first_name: selectJournalistValue.first_name,
                        last_name: selectJournalistValue.last_name,
                        email: selectJournalistValue.email,
                        deleted: 0,
                        notSaved: 1,
                        type: 2,
                        object_id: selectJournalistValue.id 
                    }]));
                }
            }
        }
    }

    const formatDateShort = (d) => {
        let date = d ? new Date(d) : new Date();
        let day = ("0" + date.getDate()).slice(-2);
        let month = ("0" + (date.getMonth() + 1)).slice(-2);
        return date.getFullYear()+"-"+(month)+"-"+(day) ;
    }

    const disableSendButton = () => {
        return localUnsavedCampaignsEmails?.filter(l => !l.deleted)?.length === 0 || (new Date(sendUntil).setHours(0,0,0,0) < new Date().setHours(0,0,0,0) && sendUntil?.length !== 0) || sendDelay < 0 || sendDelay > 5 || !selectedPressRelease?.email_body;
    }

    const updateSendTimes = (value, idx) => {
        let send_times = [...sendTimes];
        if (send_times.length > 0) {
            send_times[idx] = value;
            setSendTimes(send_times);
        }
    }

    const modalStopCampaignProp = {
        title: 'Stop campaign',
        target: 'stop_campaign',
        nameSaveButton: 'Stop',
        add: async () => {
            try {
                setLoading(true);
                await apiClient.get(`/api/campaigns/stop/${id}`);
                dispatch(updateItems({id: id, finish: 1}));
                setRunLoaded(!isRunLoaded);
                setLoading(false);
            } catch (error) {
                setLoading(false);
                dispatch(setError(error?.response?.data?.message));
            }
        }
    }

    const modalUpdatePrProp = {
        title: 'Update PR',
        target: 'update_pr',
        nameSaveButton: 'Update',
        add: async () => {
            try {
                setCampaignPRLoading(true);
                
                const res = await apiClient.post(`/api/campaigns/update-pr/${id}`);
                if (res.data) {
                    setSelectedCampaign({...selectedCampaign,
                        signature: res.data.signature,
                        email_body: res.data.email_body,
                        email_subjects: res.data.email_subjects,
                        press_release_name: res.data.name 
                    })
                }
                setCampaignPRLoading(false);
            } catch (error) {
                setCampaignPRLoading(false);
                dispatch(setError(error?.response?.data?.message));
            }
        }
    }

    const modalResendCampaignProp = {
        title: 'Resend campaign',
        target: 'resend_campaign',
        nameSaveButton: 'Resend',
        add: async () => {
            try {
                setLoading(true);
                await apiClient.get(`/api/campaigns/resend/${id}`);
                dispatch(updateItems({id: id, start: 1}));
                setRunLoaded(!isRunLoaded);
                setLoading(false);
            } catch (error) {
                setLoading(false);
                dispatch(setError(error?.response?.data?.message));
            }
        }
    }

    const getPressRelease = async (id) => {
        try {
            setPressRelease(null);
            setPRLoading(true);
            const press_release = await dispatch(fetchPressReleasesById({ id, source })).unwrap();
            setPressRelease(press_release);
            setPRLoading(false);
        } catch (error) {
            if (error?.message !== 'axios request canceled') {
                //dispatch(setError(err?.message));
                setPRLoading(false);
            }
        }
    }

    const promiseOptions = async (inputValue) => {
        setSelectJournalistValue('');
        if (inputValue.length < 3) {
            return;
        }
        
        //Check if there are any previous pending requests
        if (typeof source != typeof undefined) {
            source.cancel("Operation canceled due to new request.")
        }
        //Save the cancel token for the current request
        source = cancelToken.source();

        try {
            const res = await dispatch(fetchJournalist({ inputValue, source })).unwrap();
            if (res) {
                return res.map(r => ({...r, label: r.first_name + r.last_name + r.email + r.outlet}));
            }
        } catch (e) {
            if (e?.message !== 'Operation canceled due to new request.') {
                dispatch(setError(e?.message));
            }
        }
    };

    const listPromiseOptions = async (inputValue) => {
        if (inputValue.trim().length < 3) {
            return;
        }
        //Check if there are any previous pending requests
        if (typeof source != typeof undefined) {
            source.cancel("Operation canceled due to new request.")
        }
        //Save the cancel token for the current request
        source = cancelToken.source();

        try {
            const listsResponse = await apiClient.get(`/api/allLists/${inputValue}`, { cancelToken: source.token});
            let itemsOptions = itemsFormatAsOptions(listsResponse?.data);
            setListsOptions(itemsOptions);
            return itemsOptions;
        } catch (e) {
            if (e?.message !== 'Operation canceled due to new request.') {
                dispatch(setError(e?.message));
            }
        }
    }

    const listValues = useRef();

    return (!isLoading ? <>
        {localLoadingMenuItems ? <Loading fullScreen="true"/> : ''}
        {selectedCampaign?.start && selectedCampaign?.is_folder === 0 ?
            <><Nav selectedListItem={selectedCampaign} spaceBetween={false}>
                <div className="d-flex align-items-center badge-in-nav">
                    <div className='d-flex'>
                        <div className='m-1'>{selectedCampaign.is_deleted ? <span className="ms-2 badge bg-danger">DELETED</span> : ''}</div>
                        <div className='m-1'>{selectedCampaign.finish ? <span className="ms-2 badge bg-success">Done</span> : <span className="ms-2 badge bg-warning">In Progress</span>}</div>
                    </div>
                    <div>
                        <Modal props={modalUpdatePrProp}>
                             Are you sure you want to update pr?
                        </Modal>
                        {!selectedCampaign.finish ? <>
                            <Modal props={modalStopCampaignProp}>
                                Are you sure you want to stop the campaign?
                            </Modal>
                            <button className="btn btn-dark me-2" data-bs-toggle="modal" data-bs-target="#stop_campaign">Stop</button>
                        </> : <></>}
                        {selectedCampaign.finish && !selectedCampaign.relentless_mode && !selectedCampaign.is_deleted ? <>
                            <Modal props={modalResendCampaignProp}>
                                Are you sure you want to resend the campaign?
                            </Modal>
                            <button className="btn btn-dark me-2" data-bs-toggle="modal" data-bs-target="#resend_campaign">Resend</button>
                        </> : <></>}
                        <button className="btn btn-primary me-2" data-bs-toggle="offcanvas" data-bs-target="#offcanvasEnd" aria-controls="offcanvasEnd">Details</button>
                    </div>
                </div>
            </Nav>
            <Statistics is_folder={selectedCampaign?.is_folder} relentless_mode={selectedCampaign?.relentless_mode} start_date={selectedCampaign?.start_date} max_steps={selectedCampaign?.max_steps}/>
            </> : ''}
        {selectedCampaign?.start && selectedCampaign?.is_folder === 0 ? <div className="row pt-1">
            <div className="col-lg-12 wrapper-table-responsive">
                <div className="table-responsive table-max-height">
                    { selectedCampaign ? <TableStartedCampaign clickTracking={clickTracking} selectedCampaign={selectedCampaign} /> : '' }
                </div>
            </div>
            <SidebarRight title='Details'>
                <div className="col-lg-12">
                    {isCampaignPRLoading ? <Loading fullScreen="true" /> : 
                        <div className="box-details">
                            <label>Name</label>
                            <input className="form-control mb-3" value={name} readOnly></input>
                            <label>Email</label>
                            <input className="form-control mb-3" value={email} readOnly></input>
                            <label>Press Releases</label>
                            <input className="form-control mb-3" value={selectedCampaign?.press_release_name ? selectedCampaign.press_release_name : ''} readOnly></input>
                            <label>Delay between steps:{sendDelay === 1 ? ' (day)' : ' (days)'}</label>
                            <input type="number" className="form-control mb-3" min="1" value={sendDelay} readOnly></input>
                            { selectedPressRelease?.relentless_mode ?
                                <>
                                <label>Send until</label>
                                <input type="date" id="date_picker" className="form-control" name="trip-start" value={sendUntil}  readOnly/>
                                </> : <></>
                            }
                            <div className="form-check form-switch mt-3">
                                <input className="form-check-input" type="checkbox" role="switch" disabled id="send_on_sa" checked={sendOnSa}/>
                                <label className="form-check-label" htmlFor="click_tracking" readOnly>Send on Saturday</label>
                            </div>
                            <div className="form-check form-switch mt-3">
                                <input className="form-check-input" type="checkbox" role="switch" disabled id="send_on_su" checked={sendOnSu} />
                                <label className="form-check-label" htmlFor="send_on_su" readOnly>Send on Sunday</label>
                            </div>
                            <div className="form-check form-switch mt-3">
                                <input className="form-check-input" type="checkbox" role="switch" disabled id="click_tracking" checked={clickTracking} />
                                <label className="form-check-label" htmlFor="click_tracking" readOnly>Click Tracking</label>
                            </div>
                            <div className="form-check form-switch mt-3 mb-3">
                                <input className="form-check-input" type="checkbox" role="switch" disabled id="relentless_mode" checked={selectedCampaign.relentless_mode} />
                                <label className="form-check-label" htmlFor="relentless_mode" readOnly>Relentless Mode</label>
                            </div>
                            <label>Start Date</label>
                            <input type="datetime-local" className="form-control mb-3" name="trip-start" value={startDate}  readOnly/>
                            {selectedCampaign && selectedCampaign.finish_date ? <><label>Finish Date</label>
                            <input type="date" className="form-control mb-3" name="trip-start" value={formatDateShort(selectedCampaign.finish_date)}  readOnly/></> : <></>}
                            <Modal props={sendTimesModalProp}>
                                {sendTimes.map((time, idx) => 
                                {return !selectedCampaign.steps[idx]?.done ?
                                    <Wrapper key={idx}>
                                    <label>{`S${idx + 1}:`}</label>
                                    <input className="form-control" type="time" onChange={(e) => updateSendTimes(e.target.value, idx)} value={time}></input>
                                    </Wrapper> : <Wrapper key={`${idx}-nr`}></Wrapper>}
                                )}
                            </Modal>
                            {selectedCampaign?.send_times && selectedCampaign.relentless_mode && !selectedCampaign.finish ? <button className="btn btn-primary mb-2 me-2" data-bs-toggle="modal" data-bs-target="#send_times">Set send times</button> : ''}
                            {selectedCampaign.email_body ? <PreviewEmail 
                                signature={selectedCampaign.signature}
                                emailBody={selectedCampaign.email_body}
                                emailSubject={selectedCampaign.email_subjects}
                                button={'Preview Email'} /> : ''}
                            {selectedCampaign?.press_release_id ? <a className="btn btn-secondary mb-2 me-2" href={`/press-releases/${selectedCampaign.press_release_id}`} target="_blank" rel="noreferrer">View Press Release</a> : ''}
                            {((selectedCampaign.finish && !selectedCampaign.relentless_mode && !selectedCampaign.is_deleted) || (!selectedCampaign.finish && !selectedCampaign.is_deleted)) ? 
                                <button className="btn btn-dark mb-2 me-2" data-bs-toggle="modal" data-bs-target="#update_pr">Update PR</button>
                            : <></>}
                        </div>
                    }
                </div>                    
            </SidebarRight>
        </div> : ''}

        { id && pressReleaseOptions && selectedCampaign?.finish !== 1 && selectedCampaign?.is_folder === 0 && selectedCampaign?.start === 0 ?
            <>
                <Nav selectedListItem={selectedCampaign}>
                    <button className="btn btn-primary me-2" data-bs-toggle="modal" data-bs-target="#select_journalist" onClick={() => setSelectJournalistValue('')}>Add journalist</button>
                    <button className="btn btn-primary me-2" data-bs-toggle="modal" data-bs-target="#lists" onClick={() => resetListSelect()}>Add from list</button>
                    <button className={`btn btn-primary me-2`} onClick={() => saveCampaignDetails()}>Save</button>
                    <button className={`btn btn-dark ${disableSendButton() ? 'disabled' : ''}`} onClick={() => sendCampaignsEmails()}>Send</button>
                </Nav>
                <p style={{color: 'red', fontStyle: 'italic'}}>You can only have 5,000 emails in one full sending list. Please consider better targeting with the journalists.</p>
                <div className="row">
                    <div className="col-lg-9">
                        <Modal props={modalProp}>
                            <AsyncSelect
                                cacheOptions
                                ref={listValues}
                                isMulti
                                loadOptions={listPromiseOptions}
                                placeholder={'Type to search'}
                                formatOptionLabel={formatOptionLabel}
                            />
                        </Modal>
                        <Modal props={selectJournalist}>
                            <AsyncSelect
                                cacheOptions
                                loadOptions={promiseOptions}
                                placeholder={'Type to search'}
                                onChange={setSelectJournalistValue}
                                formatOptionLabel={formatOptionJournalistLabel}
                                onFocus={() => setSelectJournalistValue('')}
                                value={selectJournalistValue}
                            />
                        </Modal>
                        <div className="table-responsive">
                            <TableCampaign listsOptions={listsOptions} isLoadingList={isLoadingList} id={id}/>
                        </div>
                    </div>
                    <div className="col-lg-3  h-100">
                        <div className="box-details">
                            <h5>Details</h5>
                            <label>Name</label>
                            <input className="form-control mb-3" onChange={(e) => setName(e.target.value)} value={name} />
                            <label>Email</label>
                            <input className="form-control mb-3" onChange={(e) => setEmail(e.target.value)} value={email} />
                            { isPRLoading ? <Loading fullScreen='true'/> :
                            <>
                                <label>Press Releases</label><br/>
                                {pressReleaseOptions?.length > 0 && selectedCampaign ? <Select options={pressReleaseOptions} onChange={(e) => getPressRelease(e.value)} value={selectedPressRelease} formatOptionLabel={formatOptionPressReleaseLabel}/> : 'Before send emails, please create a PR.'}
                                <div className="mt-3">
                                    <label className="form-label" htmlFor="date_picker" readOnly>Schedule start (optional)</label>
                                    <input type="datetime-local" id="date_picker" min={formatDateShort(new Date().toISOString())} className="form-control" name="trip-start" value={startDate}  onChange={(date) => setStartDate(date.target.value)}/>
                                </div>
                                { selectedPressRelease?.relentless_mode ?
                                <div className="mt-3">
                                    <label>Delay between steps:{sendDelay === 1 ? ' (day)' : ' (days)'}</label>
                                    <input type="number" className="form-control mb-3" min="1" onChange={(e) => setSendDelay(e.target.value)} value={sendDelay}></input>
                                    <label className="form-label" htmlFor="date_picker" readOnly>Send until (optional)</label>
                                    <input type="date" id="date_picker" min={formatDateShort(new Date().toISOString())} className="form-control" name="trip-start" value={sendUntil}  onChange={(date) => setSendUntil(date.target.value)}/>
                                </div> : ''}
                                <div className="form-check form-switch mt-3">
                                    <input className="form-check-input" type="checkbox" role="switch" id="send_on_sa" checked={sendOnSa} onChange={() => setSendOnSa(!sendOnSa)}/>
                                    <label className="form-check-label" htmlFor="click_tracking">Send on Saturday</label>
                                </div>
                                <div className="form-check form-switch mt-3">
                                    <input className="form-check-input" type="checkbox" role="switch" id="send_on_su" checked={sendOnSu} onChange={(e) => setSendOnSu(!sendOnSu)}/>
                                    <label className="form-check-label" htmlFor="send_on_su">Send on Sunday</label>
                                </div>
                                <div className="form-check form-switch mt-3">
                                    <input className="form-check-input" type="checkbox" role="switch" id="click_tracking" checked={clickTracking} onChange={(e) => setClickTracking(!clickTracking)}/>
                                    <label className="form-check-label" htmlFor="click_tracking">Click Tracking</label>
                                </div>
                                { selectedPressRelease ?
                                <>
                                    <div className="form-check form-switch mt-3">
                                        <input className="form-check-input" type="checkbox" role="switch" disabled id="relentless_mode" checked={selectedPressRelease.relentless_mode} />
                                        <label className="form-check-label" htmlFor="relentless_mode" readOnly>Relentless Mode</label>
                                    </div>
                                    <div className='mt-3'>
                                        {selectedPressRelease ?
                                        <PreviewEmail
                                            button={'Preview Email'}
                                            signature={selectedPressRelease.signature}
                                            emailBody={selectedPressRelease.email_body}
                                            emailSubject={selectedPressRelease.email_subjects}
                                        /> : ''}
                                        {selectedPressRelease?.id ? <a className="btn btn-secondary mb-2" href={`/press-releases/${selectedPressRelease.id}`} target="_blank" rel="noreferrer">View Press Release</a> : ''}
                                    </div>
                                </> : <></>
                                }
                            </>}
                        </div>
                    </div>
                </div>
            </>
            : ''
        }
    </> : <Loading fullScreen="true"></Loading>)
}
