import React, { useEffect, useState } from 'react';
import Select from 'react-select';

export function Status({selectedItem, setSelectedItem, statusKey}) {
  const statuses = [{value: 0, label: 'Unset'}, {value: 1, label: 'In Progress'}, {value: 100, label: 'Approved/Completed'}];
  const [selectedStatus, setSelectedStatus] = useState(selectedItem[statusKey] !== undefined ? statuses.find(s => s.value === selectedItem[statusKey]) : statuses[0]);
  
  useEffect(() => {
    if (selectedStatus) {
      setSelectedItem({...selectedItem, [statusKey]: selectedStatus.value});
    }
  }, [selectedStatus]) // eslint-disable-line react-hooks/exhaustive-deps
  
  return (<>
    <Select options={statuses} onChange={(e) => setSelectedStatus(e)} value={selectedStatus} defaultValue={selectedStatus} menuPlacement={'top'}/>
  </>
  );
}
