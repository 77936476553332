import apiClient from '../../services/api';

export async function fetchBlockedEmails(per_page, page, searchTerm = null ,source) {
    try {
        let params = new URLSearchParams({page, per_page});
        if (searchTerm) {
            params.append('searchTerm', searchTerm);
        }
        const response = await apiClient.get(`/api/blocked-emails?${params}`, { cancelToken: source.token });
        return response.data;
    } catch (error) {
        if (error?.response?.data?.message) {
            throw new Error(error?.response?.data?.message);
        }
    }
}

export async function blockEmail(data) {
    try {
        const response = await apiClient.post(`/api/blocked-emails`, data);
        return response.data;
    } catch (error) {
        if (error?.response?.data?.message) {
            throw new Error(error?.response?.data?.message);
        }
    }
}

export async function deleteBlockedEmail(id) {
    try {
        const response = await apiClient.delete(`/api/blocked-emails/${id}`);
        return response.data;
    } catch (error) {
        if (error?.response?.data?.message) {
            throw new Error(error?.response?.data?.message);
        }
    }
}
