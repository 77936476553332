import apiClient from '../../services/api';

export async function fetchCampaigns(source, per_page, page, searchTerm, filterByDate, filterByStatus, showHidden) {
    try {
        let params = new URLSearchParams({page, per_page});
        if (searchTerm) {
            params.append('searchTerm', searchTerm);
        }
        if (filterByDate) {
            params.append('date', filterByDate);
        }
        if (filterByStatus) {
            params.append('status', filterByStatus);
        }
        if (showHidden) {
            params.append('show_hidden', showHidden ? 1 : 0);
        }
        const response = await apiClient.get(`/api/campaigns-manager/get-campaigns?${params}`, { cancelToken: source.token });
        return response.data;
    } catch (error) {
        if (error?.response?.data?.message) {
            throw new Error(error?.response?.data?.message);
        }
    }
}

export async function fetchCampaign(id, source) {
    try {
        const response = await apiClient.get(`/api/campaigns-manager/campaign/${id}`, { cancelToken: source.token });
        return response.data;
    } catch (error) {
        if (error?.response?.data?.message) {
            throw new Error(error?.response?.data?.message);
        }
    }
}

export async function fetchNotionCampaigns(client, source) {
    try {
        const response = await apiClient.get(`/api/campaigns-manager/get-notion-campaigns/${client}`, { cancelToken: source.token });
        return response.data;
    } catch (error) {
        if (error?.response?.data?.message) {
            throw new Error(error?.response?.data?.message);
        }
    }
}


export async function fetchUsers(source, per_page, page, searchTerm, filterByStatus, showHidden) {
    try {
        let params = new URLSearchParams({page, per_page});
        if (searchTerm) {
            params.append('searchTerm', searchTerm);
        }
        if (filterByStatus) {
            params.append('status', filterByStatus);
        }
        if (showHidden) {
            params.append('show_hidden', showHidden ? 1 : 0);
        }
        const response = await apiClient.get(`/api/campaigns-manager/get-users?${params}`, { cancelToken: source.token });
        return response.data;
    } catch (error) {
        if (error?.response?.data?.message) {
            throw new Error(error?.response?.data?.message);
        }
    }
}

export async function updateCampaign(data, id, source) {
    try {
        const response = await apiClient.put(`/api/campaigns-manager/campaign/${id}`, { cancelToken: source.token, ...data });
        return response.data;
    } catch (error) {
        if (error?.response?.data?.message) {
            throw new Error(error?.response?.data?.message);
        }
    }
}

export async function disableClient(id, source) {
    try {
        const response = await apiClient.post(`/api/disable-client/${id}`, { cancelToken: source.token });
        return response.data;
    } catch (error) {
        if (error?.response?.data?.message) {
            throw new Error(error?.response?.data?.message);
        }
    }
}

export async function splitCampaign(id, source) {
    try {
        const response = await apiClient.post(`/api/campaigns-manager/split-campaign/${id}`, { cancelToken: source.token});
        return response.data;
    } catch (error) {
        if (error?.response?.data?.message) {
            throw new Error(error?.response?.data?.message);
        }
    }
}
