
import React, { useEffect, useState } from 'react';
import apiClient from '../services/api';
import axios from 'axios';
import { Line } from 'react-chartjs-2';
import { Loading } from '../features/loader/Loading';

export default function TeamSuccessfulCampaigns() {

    const [loading, setLoading] = useState(false);
    const [successfulCampaigns, setSuccessfulCampaigns] = useState([]);
    const [revenue, setRevenue] = useState([]);
    const [breakEven, setBreakEven] = useState([]);
    const cancelToken = axios.CancelToken;
    const source = cancelToken.source();

    useEffect(() => {
        getTeamSuccessfulCampaign();
        return (() => {
            source.cancel('axios request canceled');
            setLoading(false);
            setSuccessfulCampaigns([]);
            setRevenue([]);
            setBreakEven([]);
        });
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const getTeamSuccessfulCampaign = async () => {
        try {
            setLoading(true);
            const res = await apiClient.get('/api/campaigns-manager/team-successful-campaigns', { cancelToken: source.token });
            setSuccessfulCampaigns(res.data.successfulCampaigns);
            setRevenue(res.data.revenue.data);
            setBreakEven(res.data.revenue.break_even);
            setLoading(false);
        } catch (error) {
            setLoading(false)
        }
    }

    const options = {
        interaction: {
            mode: "index",
            intersect: false,
        },
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            y: {
            min: 0
            },
            x: {
            ticks: {
                // For a category axis, the val is the index so the lookup via getLabelForValue is needed
                callback: function (val) {
                return formatTime(this.getLabelForValue(val));
                },
            },
            },
        },
    };

    const month = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"];

    const formatTime = (time, resolution) => {
        const data = new Date(time)
        return month[data.getMonth()] + ' ' + data.getFullYear();
    }

    const setLineChartData = (
        chartData,
        label,
        color,
        borderWidth = 2,
        pointRadius = 1,
        borderDash = [],
        hoverPointRadius = 3
      ) => {
        return {
          label: label,
          borderColor: color,
          borderWidth: borderWidth,
          pointRadius: pointRadius,
          hoverPointRadius: hoverPointRadius,
          borderDash: borderDash,
          fill: false,
          data: chartData?.map((s) => ({ x: formatDate(s.x), y: s.y })),
        };
    };

    const formatDate = (date) => {
        const dateFormat = new Date(date);
        return new Intl.DateTimeFormat("en-GB", {
          dateStyle: "medium",
          timeStyle: "medium",
        }).format(dateFormat);
    };

    return (loading ? <Loading fullScreen="true" /> :
        <>
        <div
            className="wrapper-sends-stats"
            style={{
            width: "1000px",
            display: "flex",
            justifyContent: "center",
            flex: "1 1",
            marginBottom: "2rem"
            }}
        >
            <Line
            type="line"
            data={{
                datasets: [
                setLineChartData(
                    successfulCampaigns,
                    "Campaigns",
                    "blue"
                ),
                ],
            }}
            options={options}
            style={{ width: "100%", height: "370px" }}
            height={370}
            />            
        </div>
        <div
            className="wrapper-sends-stats"
                style={{
                width: "1000px",
                display: "flex",
                justifyContent: "center",
                flex: "1 1",
                marginBottom: "2rem"
                }}
            >
                <Line
                type="line"
                data={{
                    datasets: [
                    setLineChartData(
                        revenue,
                        "Campaigns revenue",
                        "blue"
                    ),
                    setLineChartData(
                        breakEven,
                        "Break-even",
                        "orange",
                        2,
                        0,
                        [5, 5],
                        0
                    ),
                    ],
                }}
                options={options}
                style={{ width: "100%", height: "370px" }}
                height={370}
                />            
            </div>
        </>
    )
}