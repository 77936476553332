import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiClient from '../../services/api';


export const fetchManagers = createAsyncThunk(
  'managers',
  async ({source, itemType}) => {
    const response = await apiClient.get(`/api/approval/managers/${itemType}`, { cancelToken: source.token });
    // The value we return becomes the `fulfilled` action payload
    return response.data;
  }
);


export const fetchSites = createAsyncThunk(
  'sites',
  async ({source}) => {
    const response = await apiClient.get(`/api/approval/sites`, { cancelToken: source.token });
    // The value we return becomes the `fulfilled` action payload
    return response.data;
  }
);

export const storeApprovalItem = createAsyncThunk(
  'storeApprovalItem',
  async ({source, data}) => {
    try {
    const response = await apiClient.post(`/api/approval-items`, { cancelToken: source.token, ...data });
    // The value we return becomes the `fulfilled` action payload
    return response.data;
    }
    catch (error) {
      if (error?.response?.data?.message) {
        throw new Error(error?.response?.data?.message);
      }
    }
  }
);

export const updateApprovalItem = createAsyncThunk(
  'updateApprovalItem',
  async ({source, data, id}) => {
    try {
    const response = await apiClient.put(`/api/approval-items/${id}`, { cancelToken: source.token, ...data });
    // The value we return becomes the `fulfilled` action payload
    return response.data;
    }
    catch (error) {
      if (error?.response?.data?.message) {
        throw new Error(error?.response?.data?.message);
      }
    }
  }
);

export const fetchApprovalItem = createAsyncThunk(
  'fetchApprovalItem',
  async ({source, itemType, showAll}) => {
    const response = await apiClient.get(`/api/approval-items/${itemType}?show-all=${showAll ? 1 : 0}`, { cancelToken: source.token });
    // The value we return becomes the `fulfilled` action payload
    return response.data;
  }
);

export const addStatus = createAsyncThunk(
  'addStatus',
  async ({source, data, id}) => {
    const response = await apiClient.post(`/api/approval-items/add-status/${id}`, {cancelToken: source.token, ...data});
    return response.data;
  }
);

export const removeStatus = createAsyncThunk(
  'removeStatus',
  async ({source, id}) => {
    const response = await apiClient.delete(`/api/approval-items/delete-status/${id}`, {cancelToken: source.token});
    return response.data;
  }
);

export const fetchCategories = createAsyncThunk(
  'api/approval-item-categories',
  async ({source, itemType}) => {
    const categoryType = [2, 3];
    try {
      const response = await apiClient.get(`/api/categories?type=${categoryType[itemType - 1]}`, { cancelToken: source.token });
      return response.data;
    } catch (error) {
      if (error?.response?.data?.message) {
        throw new Error(error?.response?.data?.message);
      }
    }
  }
);

export const removeApprovalItem = createAsyncThunk(
  'removeApprovalItem',
  async ({source, id}) => {
    try {
      await apiClient.delete(`/api/approval-items/${id}`, { cancelToken: source.token });
      return id;
    } catch (error) {
      if (error?.response?.data?.message) {
        throw new Error(error?.response?.data?.message);
      }
    }
  }
);

export const fetchApprovalItemQuestions = createAsyncThunk(
  'fetchApprovalItemQuestions',
  async ({source, itemType}) => {
    const response = await apiClient.get(`/api/approval-items/questions/${itemType}`, { cancelToken: source.token });
    // The value we return becomes the `fulfilled` action payload
    return response.data;
  }
);

export const fetchClients = createAsyncThunk(
  'fetchClients',
  async ({source, site}) => {
    const response = await apiClient.get(`/api/approval/clients/${site}`, { cancelToken: source.token });
    return response.data;
  }
);

const initialState = {
  clients: [],
  sites: [],
  approvalItems: [],
  loading: false,
  loadingSites: false,
  error: '',
  loadingRemoveStatus: false,
  managers: [],
  loadingManagers: false,
  categories: [],
  loadingCategories: false,
  questions: [],
  loadingQuestions: false,
  loadingClients: false
};

const approvalItemsSlice = createSlice({
  name: "approval-items",
  initialState,
  reducers: {
    
  },
  extraReducers(builder) {
    builder
      .addCase(fetchSites.pending, (state, action) => {
        state.loadingSites = true;
        state.sites = [];
      })
      .addCase(fetchSites.fulfilled, (state, action) => {
        state.loadingSites = false;
        state.sites = action.payload;
      })
      .addCase(fetchSites.rejected, (state, action) => {
        state.loadingSites = false;
        if (action.error.message !== 'axios request canceled') {
          state.error = action.error.message;
        }
      })
      .addCase(storeApprovalItem.pending, (state, action) => {
        state.loading = true;
        state.error = '';
      })
      .addCase(storeApprovalItem.fulfilled, (state, action) => {
        state.loading = false;
        state.approvalItems = [action.payload, ...state.approvalItems];
      })
      .addCase(storeApprovalItem.rejected, (state, action) => {
        state.loading = false;
        if (action.error.message !== 'axios request canceled') {
          state.error = action.error.message;
        }
      })
      .addCase(fetchApprovalItem.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(fetchApprovalItem.fulfilled, (state, action) => {
        state.loading = false;
        state.approvalItems = action.payload;
      })
      .addCase(addStatus.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(addStatus.fulfilled, (state, action) => {
        state.loading = false;
        let index = state.approvalItems.findIndex(s => s.id === action.payload.id);
        state.approvalItems[index] = action.payload;
      })
      .addCase(addStatus.rejected, (state, action) => {
        state.loading = false;
        if (action.error.message !== 'axios request canceled') {
          state.error = action.error.message;
        }
      })
      .addCase(removeStatus.pending, (state, action) => {
        state.loadingRemoveStatus = true;
      })
      .addCase(removeStatus.fulfilled, (state, action) => {
        state.loadingRemoveStatus = false;
        let index = state.approvalItems.findIndex(s => s.id === action.payload.id);
        state.approvalItems[index] = action.payload;
        // let index = state.approvalItems.findIndex(s => s.id === action.payload.id);
        // state.approvalItems[index] = action.payload;
      })
      .addCase(fetchManagers.pending, (state, action) => {
        state.loadingManagers = true;
        state.managers = [];
      })
      .addCase(fetchManagers.fulfilled, (state, action) => {
        state.loadingManagers = false;
        state.managers = action.payload;
      })
      .addCase(fetchManagers.rejected, (state, action) => {
        state.loadingManagers = false;
        if (action.error.message !== 'axios request canceled') {
          state.error = action.error.message;
        }
      })
      .addCase(updateApprovalItem.pending, (state, action) => {
        state.loading = true;
        state.error = '';
      })
      .addCase(updateApprovalItem.fulfilled, (state, action) => {
        state.loading = false;
        let index = state.approvalItems.findIndex(a => a.id === action.payload.id);
        if (index > -1) {
          state.approvalItems[index]['assigned_user'] = action.payload.assigned_user;
          state.approvalItems[index]['assigned_name'] = action.payload.assigned_name;
        }
      })
      .addCase(updateApprovalItem.rejected, (state, action) => {
        state.loading = false;
        if (action.error.message !== 'axios request canceled') {
          state.error = action.error.message;
        }
      })
      .addCase(fetchCategories.pending, (state, action) => {
        state.loadingCategories = true;
        state.categories = [];
      })
      .addCase(fetchCategories.fulfilled, (state, action) => {
        state.loadingCategories = false;
        state.categories = action.payload;
      })
      .addCase(fetchCategories.rejected, (state, action) => {
        state.loadingCategories = false;
        if (action.error.message !== 'axios request canceled') {
          state.error = action.error.message;
        }
      })
      .addCase(removeApprovalItem.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(removeApprovalItem.fulfilled, (state, action) => {
        state.loading = false;
        state.approvalItems = state.approvalItems.filter(s => s.id !== action.payload);
      })
      .addCase(fetchApprovalItemQuestions.pending, (state, action) => {
        state.loadingQuestions = true;
        state.questions = [];
      })
      .addCase(fetchApprovalItemQuestions.fulfilled, (state, action) => {
        state.loadingQuestions = false;
        state.questions = action.payload;
      })
      .addCase(fetchApprovalItemQuestions.rejected, (state, action) => {
        state.loadingQuestions = false;
        if (action.error.message !== 'axios request canceled') {
          state.error = action.error.message;
        }
      })
      .addCase(fetchClients.pending, (state, action) => {
        state.loadingClients = true;
        state.clients = [];
      })
      .addCase(fetchClients.fulfilled, (state, action) => {
        state.loadingClients = false;
        state.clients = action.payload;
      })
      .addCase(fetchClients.rejected, (state, action) => {
        state.loadingClients = false;
        if (action.error.message !== 'axios request canceled') {
          state.error = action.error.message;
        }
      })
  },
});
export const getSites = (state) => state.approvalItems.sites;
export const getManagers = (state) => state.approvalItems.managers;
export const getApprovalItems = (state) => state.approvalItems.approvalItems;
export const getLoadingSites = (state) => state.approvalItems.loadingSites;
export const getLoadingManagers = (state) => state.approvalItems.loadingManagers;
export const getLoadingRemoveStatus = (state) => state.approvalItems.loadingRemoveStatus;
export const getLoadingQuestions = (state) => state.approvalItems.loadingQuestions;
export const getLoadingClients = (state) => state.approvalItems.loadingClients;
export const getClients = (state) => state.approvalItems.clients;
export const getLoading = (state) => state.approvalItems.loading;
export const getError = (state) => state.approvalItems.error;
export const getLoadingCategories = (state) => state.approvalItems.loadingCategories;
export const getCategories = (state) => state.approvalItems.categories;
export const getQuestions = (state) => state.approvalItems.questions;
export default approvalItemsSlice.reducer;