import axios from 'axios';
import React from 'react';
import { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { fetchDataWarehouse, getCategories, getParamsForURLQuery, getSearchCategory, setSearchCategory, getLoadingCategories } from './dataWarehouseSlice';


export default function FilterCategory() {

    const dispatch = useDispatch();
    const searchCategory = useSelector(getSearchCategory);
    const categories = useSelector(getCategories);
    const params = useSelector(getParamsForURLQuery);
    const cancelToken = axios.CancelToken;
    const source = cancelToken.source();
    const categoryValues = useRef();
    const loadingCategories = useSelector(getLoadingCategories);
    
    
    const onBlurSelect = () => {
        const currentValue = categoryValues.current.getValue();
        if (JSON.stringify(searchCategory) !== JSON.stringify(currentValue)) {
            dispatch(setSearchCategory(currentValue));
            dispatch(fetchDataWarehouse({
                page: 1,
                ...params,
                searchCategory: currentValue.map(c => c.value).join(),
                source}));
        }
	}
    
    return (<>
        <Select
            ref={categoryValues}
            className='categories-select'
            isLoading={loadingCategories}
            cacheOptions
            defaultOptions
            isMulti
            isClearable
            options={categories?.map(c => ({'label': c.name, 'value': c.id}))}
            defaultValue={searchCategory}
            onBlur={onBlurSelect}
            onKeyDown={(e) => e.key === 'Enter' && onBlurSelect()}
        />
        </>)
}