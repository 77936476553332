import apiClient from '../../services/api';

export async function fetchExternalPlacements(id, source) {
    try {
        const response = await apiClient.get(`/api/external-placements/${id}`, { cancelToken: source.token });
        return response.data;
    } catch (error) {
        if (error?.message) {
            throw new Error(error?.message);
        }
        if (error?.response?.data?.message) {
            throw new Error(error?.response?.data?.message);
        }
    }
}


export async function createCategory(data, source) {
    try {
        const response = await apiClient.post(`/api/categories`, { cancelToken: source.token, ...data });
        return response.data;
    } catch (error) {
        if (error?.response?.data?.message) {
            throw new Error(error?.response?.data?.message);
        }
    }
}

export async function syncCategories(categories, id, source) {
    try {
        const response = await apiClient.put(`/api/external-campaigns/${id}/sync-categories?categories=${categories}`, { cancelToken: source.token });
        return response.data;
    } catch (error) {
        if (error?.response?.data?.message) {
            throw new Error(error?.response?.data?.message);
        }
    }
}

export async function insertPlacements(data, source) {
    try {
        const response = await apiClient.post(`/api/external-placements`, { cancelToken: source.token, ...data });
        return response.data;
    } catch (error) {
        if (error?.response?.data?.message) {
            throw new Error(error?.response?.data?.message);
        }
    }
}