import React, { useState, useEffect } from 'react';
import Select from 'react-select';

export function UserSelect({selectedItem, setSelectedItem, users, itemKey}) {
    const [data, setData] = useState(selectedItem[itemKey] ? selectedItem[itemKey]: []);
    const [selectedUser, setSelectedUser] = useState(null);

    useEffect(() => {
        setSelectedItem({...selectedItem, [itemKey]: data});
    }, [data]) // eslint-disable-line react-hooks/exhaustive-deps

    const createNewEntry = () => {
        if (selectedUser) {
            const foundName = data.find(e => (e.id === selectedUser.value));
            if (!foundName) {
            setData([{name: selectedUser.label, id: selectedUser.value}, ...data]);
            setSelectedUser(null);    
            }
        }
    };

    const deleteItem = (idx) => {
        let d = data.filter((v, index) => idx !== index);
        setData(d);
    }

    return (<>
        <div className="table-modal-work-flow mt-5" style={{width: '50%'}}>
        <label>Assign to person</label>
        {users ? 
            <div className='d-flex align-items-center'>
                <div style={{flex: 1}}><Select options={users} onChange={(e) => setSelectedUser(e)} value={selectedUser}/></div>
                <i className={`cursor-pointer bi bi-plus-lg text-primary ps-1 pe-1 ${selectedUser ? '' : 'disabled'}`} onClick={() => createNewEntry()}/>
            </div>
            : ''}
            <table className="table table-striped mt-2">
                <tbody>
                    {data?.map((data, index) =>
                        <tr key={index}>
                            <td>{data.name}</td>
                            <td className="text-center"><i className="bi bi-dash text-danger ps-1 pe-1" onClick={() => deleteItem(index)}/></td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    </>);
}
