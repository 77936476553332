import apiClient from '../../services/api';

export async function fetchPrReviews(per_page, page, source) {
    try {
        const response = await apiClient.get(`/api/pr-reviews?${new URLSearchParams({page, per_page})}`, { cancelToken: source.token });
        return response.data;
    } catch (error) {
        if (error?.response?.data?.message) {
            throw new Error(error?.response?.data?.message);
        }
    }
}

export async function createReview(data) {
    try {
        const response = await apiClient.post(`/api/pr-reviews`, data);
        return response.data;
    } catch (error) {
        if (error?.response?.data?.message) {
            throw new Error(error?.response?.data?.message);
        }
    }
}

export async function updateReview(data, id) {
    try {
        const response = await apiClient.put(`/api/pr-reviews/${id}`, data);
        return response.data;
    } catch (error) {
        if (error?.response?.data?.message) {
            throw new Error(error?.response?.data?.message);
        }
    }
}