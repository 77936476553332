import {Doughnut} from 'react-chartjs-2';
import 'chart.js/auto';

export function DoughnutChart(props) {
    const plugins = [{
        beforeDraw: function (chart) {
            var width = chart.width,
                height = chart.height,
                ctx = chart.ctx;
            ctx.restore();
            var fontSize = (height / 150).toFixed(2);
            ctx.font = fontSize + "em sans-serif";
            ctx.textBaseline = "top";
            var text = chart.config.data.datasets[0]['text'],
                textX = Math.round((width - ctx.measureText(text).width) / 2),
                textY = height / 2 - 5;
            ctx.fillText(text, textX, textY);
            ctx.save();
        },
    }]
    const options1 = {
        responsive: true,
        layout: {
            padding: 40
        },
        plugins: 
        { 
            legend: { display: false }, 
            cutout: '80%',
        },
    };
    return (
        <div className="doughnut">
            <Doughnut data={props.data} options={options1} height={120} plugins={plugins} />
        </div>
    )
}