import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Loading } from '../loader/Loading';
import { fetchLatestPlacements } from './clientInformationAPI';
import ReactHtmlParser from 'html-react-parser';
import { setError } from '../error-handling/errorHandlingSlice';
import { useDispatch } from 'react-redux';

export function LatestPlacementsTab({selectedTab, data, tab}) {
    const dispatch = useDispatch();
    const cancelToken = axios.CancelToken;
    const source = cancelToken.source();
    const [latestPlacements, setLatestPlacements] = useState([]);
    const [isLoading, setLoading] = useState(false);

    useEffect(() => {
        return (() => {
            source.cancel('axios request canceled');
        });
    }, [selectedTab]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (selectedTab === tab && data) {
            getLatestPlacements(data);
        }
    }, [selectedTab, data]) // eslint-disable-line react-hooks/exhaustive-deps
    
    const getLatestPlacements = async (client) => {
        try {
            setLoading(true);
            const res = await fetchLatestPlacements(client, source);
            setLatestPlacements(res);
            setLoading(false);
        } catch (error) {
            if (error?.message !== 'axios request canceled') {
                dispatch(setError(error?.message));
                setLoading(false);
            }
        }
    }

    return (<>
        {isLoading ? <Loading fullScreen="true" /> : <>
            {selectedTab === tab ? <>
                <div className="row mt-4 ms-3 me-3">
                    {latestPlacements?.length > 0 ? <div className="table-responsive">
                        <table className="table table-striped table-clients-details">
                            <thead>
                                <tr>
                                    <th scope="col" className='col-campaign-date'>Date</th>
                                    <th scope="col" className="col-campaign-lead">Campaign Name</th>
                                    <th scope="col" className="col-campaign-link text-center">Link</th>
                                    <th scope="col" className="col-no-links text-center">DR</th>
                                </tr>
                            </thead>
                            <tbody> 
                                {latestPlacements.map((c, index) => {
                                return (<tr key={index}>
                                        <td className="col-campaign-date"><div>{c.date}</div></td>
                                        <td className="col-campaign-name-placements"><div>{c.campaign_name}</div></td>
                                        <td className="col-campaign-link"><div><a href={c.link} target="_blank" rel="noreferrer">{ReactHtmlParser(c?.formatedLink ? c.formatedLink : '')}</a></div></td>
                                        <td className="col-no-links text-center">{c.dr}</td>
                                    </tr>)
                                })}
                            </tbody>
                        </table>
                    </div> : 'Not found'}
                </div>
            </> : ''}
        </>}
    </>
    );
}