import React from 'react';
import { UserSelect } from './UserSelect';
import { EditorTinyMCE } from './EditorTinyMCE';

export function IdeaCol({selectedItem, setSelectedItem, users}) {

    return (<>
        <EditorTinyMCE selectedItem={selectedItem} setSelectedItem={setSelectedItem} type={'idea_description'} label={'Idea description'}/>
        <UserSelect selectedItem={selectedItem} setSelectedItem={setSelectedItem} itemKey='idea_user' users={users}></UserSelect>
    </>);
}
