
import React from 'react';

export default function Tabs({tabs, selectedTab, setSelectedTab}) {
    return (
        <ul className="nav nav-tabs">
            {tabs?.map((t, idx) => (
                <li className="nav-item" key={idx}>
                    <div className={`nav-link ${selectedTab === t ? 'active' : ''}`} aria-current="page" onClick={() => setSelectedTab(t)}>{t}</div>
                </li>
            ))}
        </ul>
    )
}
