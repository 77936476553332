import React, { useEffect, useState } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { Loading } from '../loader/Loading';
import Alert from '../../components/Alert';
import Nav from '../../components/Nav';
import {
  getNotifications,
  getStatus,
  getError,
  markAllNotificationsAsRead,
  setSelectedNotification,
} from "./notificationsSlice";
import "./notifications.scss";
import ReactHtmlParser from 'html-react-parser';
import { getFirebaseToken, getFirebaseMessaging, setFirebaseOnMessage } from '../../firebase';
import {fetchFcmTokens, storeFcmToken, deleteFcmTokens} from './fcmTokensApi';

export default function Notifications() {
  const cancelToken = axios.CancelToken;
  const source = cancelToken.source();

  const dispatch = useDispatch();
  const notifications = useSelector(getNotifications);
  const status = useSelector(getStatus);
  const error = useSelector(getError);
  const [showReadMessage, setShowReadMessage] = useState(false);
  const [showEnablePushNotification, setShowEnablePushNotification] = useState('Notification' in window && Notification.permission === 'default');
  const [fcmTokens, setFcmTokens] = useState();
  // const [isLoading, setLoading] = useState(false);

  const onClickNotification = async (notification) => {
    dispatch(setSelectedNotification(notification));
  }

  useEffect(() => {
    getFcmTokens();
    return () => {
      source.cancel("axios request canceled");
      setFcmTokens(null);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleGetFirebaseToken =  () => {
    getFirebaseMessaging().then((messaging) => {
      console.log('messaging: ', messaging);
      getFirebaseToken(messaging).then((firebaseToken) => {
          setFirebaseOnMessage(messaging);
          console.log('Firebase token: ', firebaseToken);
          if (firebaseToken) {
            storeFcmToken(source, { token: firebaseToken, device: 'web'}).then(() => {
            setShowEnablePushNotification(false);
            setFcmTokens([...fcmTokens, { token: firebaseToken, device: 'web'}]);
            });
          }
        })
        .catch((err) => console.error('An error occurred while retrieving firebase token. ', err))
    })
  }

  const getFcmTokens = async () => {
    try {
      // setLoading(true);
      const obj = await fetchFcmTokens(source);
      setFcmTokens(obj);
      // setLoading(false);
    } catch (error) {
      if (error?.message !== "axios request canceled") {
        // setLoading(false);
      }
    }
  };

  const markAllAsRead = () => {
    dispatch(markAllNotificationsAsRead({source}));
  }

  const _deleteFcmTokens = async () => {    
    await deleteFcmTokens(source);
    setFcmTokens(null);
  }

  return (
    <>
      {error ? <Alert error={error}></Alert> : ""}
      {status !=='succeeded' ? (
        <Loading fullScreen="true" />
      ) : (
        <>
          <Nav
            selectedListItem={{ name: "Notifications" }}
            spaceBetween={false}
          >
            <div className="form-check form-switch pt-1">
              <input className="form-check-input" type="checkbox" id="flexSwitchCheckChecked" checked={showReadMessage} onChange={() => setShowReadMessage(!showReadMessage)} />
              <label className="form-check-label" htmlFor="flexSwitchCheckChecked">Show Read Messages</label>
            </div>
            <div className="ms-auto">
                <button className="btn btn-primary mr-2" onClick={() => markAllAsRead()}>Mark all as read</button>            
                { showEnablePushNotification && <button className="btn btn-secondary mx-2" onClick={handleGetFirebaseToken}>Enable push notification</button>}
                { fcmTokens && fcmTokens.length > 0 && <button className="btn btn-secondary mx-2" onClick={_deleteFcmTokens}>Logout All devices</button>}
            </div>
          </Nav>
          <div className="d-flex flex-wrap">
            {notifications?.map((n, index) => (
              showReadMessage || !n.read_at ? <div key={index} className={`card-notification ${!n.read_at ? 'unread' : ''}`} data-bs-toggle="modal" data-bs-target='#notification' onClick={() => onClickNotification(n)}>
              <div className="wrapper-header-card">
                <div className="wrapper-icons">{ReactHtmlParser(n.header ? n.header : '')}</div>
                <div className="wrapper-time">{n.created_at ? <div className="read-at">{n.created_at}</div> : ''}</div>
              </div>
              <div>
                {n.message?.length > 0 ? 
                  <div>
                    {n.isHTML ? ReactHtmlParser(n.message) : n.message}
                  </div> : ''}
              </div>
              </div> : ''
            ))}
          </div>
        </>
      )}
    </>
  );
}
