import React from "react";

export default function SidebarRight({title ,children}) {
  return (
    <div className="offcanvas offcanvas-end" tabIndex="-1" id="offcanvasEnd" aria-labelledby="offcanvasEndLabel">
      <div className="offcanvas-header">
        <h5 id="offcanvasEndLabel">{title}</h5>
        <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
      </div>
      <div className="offcanvas-body">
          {children}
      </div>
    </div>
  );
}
