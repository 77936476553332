import apiClient from '../../services/api';

export async function fetchCampaignDetails(client, source) {
    try {
        const response = await apiClient.get(`/api/campaign-information/${client}`, {cancelToken: source.token});
        return response.data;
    } catch (error) {
        if (error?.response?.data?.message) {
            throw new Error(error?.response?.data?.message);
        }
    }
}

export async function fetchCommunication(client, source) {
    try {
        const response = await apiClient.get(`/api/clients-communication/${client}`, {cancelToken: source.token});
        return response.data;
    } catch (error) {
        if (error?.response?.data?.message) {
            throw new Error(error?.response?.data?.message);
        }
    }
}

export async function createCommunication(data, source) {
    try {
        const response = await apiClient.post(`/api/clients-communication`, {cancelToken: source.token, ...data});
        return response.data;
    } catch (error) {
        if (error?.response?.data?.message) {
            throw new Error(error?.response?.data?.message);
        }
    }
}


export async function fetchLatestPlacements(client, source) {
    try {
        const response = await apiClient.get(`/api/campaign-information/latest-placements/${client}`, {cancelToken: source.token});
        return response.data;
    } catch (error) {
        if (error?.response?.data?.message) {
            throw new Error(error?.response?.data?.message);
        }
    }
}

export async function fetchLatestInvoices(client, source) {
    try {
        const response = await apiClient.get(`/api/campaign-information/latest-invoices/${client}`, {cancelToken: source.token});
        return response.data;
    } catch (error) {
        if (error?.response?.data?.message) {
            throw new Error(error?.response?.data?.message);
        }
    }
}