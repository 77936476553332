import axios from 'axios';
import React, { useRef } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DailyVideo from '../../components/DailyVideo';
import Modal from '../../components/Modal';
import { sleep } from '../../help/help';
import { getSelectedNotification, getTimeSleepModal, markNotificationAsRead } from './notificationsSlice';
import ReactHtmlParser from 'html-react-parser';

export default function NotificationModal() {
    const cancelToken = axios.CancelToken;
    const source = cancelToken.source();
    const selectedNotification = useSelector(getSelectedNotification);
    const timeSleepModal = useSelector(getTimeSleepModal);
    const dispatch = useDispatch();
    const [canCloseModal, setCanCloseModal] = useState(true);
    const modalProp = {
        title: selectedNotification?.header ? ReactHtmlParser(selectedNotification.header) : 'Notification',
        target: 'notification',
        nameSaveButton: selectedNotification?.read_at ? 'unset' : 'Mark as read',
        size: 'lg',
        add: () => {
            dispatch(markNotificationAsRead({id: selectedNotification?.id, source}));
        }
    }
    const messageAlert = 'You should watch the training video before close modal';
    const notificationRef = useRef(null);
    
    useEffect(() => {
        if (!selectedNotification?.video && selectedNotification) {
            sleepMarkAsRead(selectedNotification.close_delay);
        }
        if (timeSleepModal && selectedNotification?.video) {
            sleepMarkAsRead(timeSleepModal);
        }
    }, [selectedNotification, timeSleepModal]) // eslint-disable-line react-hooks/exhaustive-deps

    const sleepMarkAsRead = async (close_delay) => {
        if (close_delay && !selectedNotification?.read_at) {
            setCanCloseModal(false);
            await sleep(close_delay);
            setCanCloseModal(true);
        }
    }

    return (<>
        <Modal props={modalProp} canClose={canCloseModal} clickOutside={false} messsageAlert={selectedNotification?.video ? messageAlert : ''}>
            {selectedNotification?.isHTML ? ReactHtmlParser(selectedNotification?.message) : selectedNotification?.message}
            {selectedNotification?.video ? <>
            <DailyVideo trainingItem={selectedNotification.video}/>
            </> : ''}
        </Modal>
        <button className="d-none" data-bs-toggle="modal" data-bs-target="#notification" ref={notificationRef} id="notification-button-modal"></button>
    </>);
}