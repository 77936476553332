import axios from 'axios';
import React from 'react';

import CreatableSelect from 'react-select/creatable';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getCategories, getLoadingCategories, setSelectedCategories, createCategory } from './dataWarehouseSlice';

export default function SelectCategories({id, isDisabled= false, selectedCategories }) {
    const dispatch = useDispatch();
    const categories = useSelector(getCategories);
    const loading = useSelector(getLoadingCategories);
    const [loadingSelect, setLoadingSelect] = useState(false);
    const cancelToken = axios.CancelToken;
    const source = cancelToken.source();

    const handleChange = (newValue) => {
        dispatch(setSelectedCategories(newValue));
    };


    const categoriesOptions = () => {
        return categories?.map(c => ({'label': c.name, 'value': c.id}));
    }
    
    const addCategory = async (inputValue) => {
        try {
            setLoadingSelect(true);
            const newCat = {'name': inputValue, 'type': 3};
            dispatch(createCategory({data: newCat, source}));
            setLoadingSelect(false);
        } catch (error) {
            setLoadingSelect(false);
        }
    }

return (
    <>
    <CreatableSelect
        isSearchable
        isDisabled={isDisabled}
        cacheOptions
        className='categories-select'
        isClearable
        onChange={handleChange}
        isMulti
        options={categoriesOptions()}
        isLoading={loading || loadingSelect}
        value={selectedCategories}
        onCreateOption={addCategory}
        menuPosition={'fixed'}
        menuPlacement="auto"
    />
    
    </>
);

}