/* global Modernizr */
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './main.scss';
import App from './App';
import { store } from './app/store';
import { Provider } from 'react-redux';
//import * as serviceWorker from './serviceWorker';
import "bootstrap/dist/js/bootstrap.min.js";
import './modernizr-custom';
import * as Sentry from "@sentry/react";
import { useLocation, useNavigationType,matchRoutes, createRoutesFromChildren } from 'react-router-dom';
console.log(process.env);
process.env.NODE_ENV === 'production' && Sentry.init({
  dsn: "https://01e0388f15e4c9519013fa0469aae874@o4505885563486208.ingest.sentry.io/4505900638863360",
  debug: process.env.REACT_APP_DEBUG === 'true',
  release: "journoresearch@" + process.env.REACT_APP_VERSION,
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ["localhost", /(http|https):\/\/(.*)journoresearch\.org/],
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes
      ),
    }),
    new Sentry.Replay(),

  ],
  // Performance Monitoring
  tracesSampleRate: 0.1, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const html5Inputs = Modernizr.inputtypes['datetime-local'] && Modernizr.inputtypes.date;
function FallbackComponent() {
  return <div>An error has occurred</div>;
}

const myFallback = <FallbackComponent />;

ReactDOM.render(
  <React.StrictMode>
      {html5Inputs?
      <Provider store={store}>
        <Sentry.ErrorBoundary fallback={myFallback} >
          <App />
        </Sentry.ErrorBoundary>
      </Provider>
      :<><h1>Please update your browser! We recommend the latest version of Chrome</h1></>}
  </React.StrictMode>,
  document.getElementById('root')
);


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
///serviceWorker.register();
