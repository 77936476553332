import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import apiClient from '../../services/api';

export const fetchDataWarehouse = createAsyncThunk(
  'api/data-warehouse',
  async ({perPage, page, searchTerm = null, sortDirection = null, column = null, source, searchName = '', searchExplanation = '', searchSource = '', searchDateMin = null, searchDateMax = null, searchCategory = null, searchType = null}) => {
      try {
        let params = new URLSearchParams({page, per_page: perPage});
        if (searchTerm) {
            params.append('searchTerm', searchTerm);
        }
        if (searchName) {
          params.append('search_name', searchName);
        }
        if (searchSource) {
          params.append('search_source', searchSource);
        }
        if (searchExplanation) {
          params.append('search_explanation', searchExplanation);
        }
        if (sortDirection) {
            params.append('sort_direction', sortDirection);
        }
        if (column) {
            params.append('column', column);
        }
        if (searchDateMin) {
          params.append('search_date_min', searchDateMin);
        }
        if (searchDateMax) {
          params.append('search_date_max', searchDateMax);
        }
        if (searchCategory) {
          params.append('categories', searchCategory);
        }
        if (searchType) {
          params.append('types', searchType);
        }
        const response = await apiClient.get(`/api/data-warehouse?${params}`, { cancelToken: source.token });
        return response.data;
    } catch (error) {
        if (error?.response?.data?.message) {
            throw new Error(error?.response?.data?.message);
        }
    }
  }
);

export const fetchCategories = createAsyncThunk(
  'api/data-warehouse-categories',
  async ({source}) => {
    try {
      const response = await apiClient.get(`/api/categories?type=3`, { cancelToken: source.token });
      return response.data;
    } catch (error) {
      if (error?.response?.data?.message) {
        throw new Error(error?.response?.data?.message);
      }
    }
  }
);

export const fetchTypes = createAsyncThunk(
  'api/data-warehouse-types',
  async ({source}) => {
    try {
      const response = await apiClient.get(`/api/categories?type=4`, { cancelToken: source.token });
      return response.data;
    } catch (error) {
      if (error?.response?.data?.message) {
        throw new Error(error?.response?.data?.message);
      }
    }
  }
);

export const createDataWarehouse = createAsyncThunk(
  'api/create-data-warehouse',
  async ({source, data}) => {
    try {
      const response = await apiClient.post(`/api/data-warehouse`, { cancelToken: source.token, ...data });
      return response.data;
    } catch (error) {
      if (error?.response?.data?.message) {
        throw new Error(error?.response?.data?.message);
      }
    }
  }
);

export const updateDataWarehouse = createAsyncThunk(
  'api/update-data-warehouse',
  async ({source, data, id}) => {
    try {
      const response = await apiClient.put(`/api/data-warehouse/${id}`, { cancelToken: source.token, ...data });
      return response.data;
    } catch (error) {
      if (error?.response?.data?.message) {
        throw new Error(error?.response?.data?.message);
      }
    }
  }
);

export const createCategory = createAsyncThunk(
  'api/create-category-warehouse',
  async ({source, data}) => {
    try {
      const response = await apiClient.post(`/api/categories`, { cancelToken: source.token, ...data });
      return response.data;
    } catch (error) {
      if (error?.response?.data?.message) {
        throw new Error(error?.response?.data?.message);
      }
    }
  }
);

export const removeWarehouse = createAsyncThunk(
  'api/remove-warehouse',
  async ({source, id}) => {
    try {
      const response = await apiClient.delete(`/api/data-warehouse/${id}`, { cancelToken: source.token });
      return response.data;
    } catch (error) {
      if (error?.response?.data?.message) {
        throw new Error(error?.response?.data?.message);
      }
    }
  }
);



const initialState = {
  error: '',
  dataWarehouse: [],
  sortDirection: null,
  column: null,
  searchName: '',
  searchExplanation: '',
  searchSource: '',
  searchCategory: [],
  searchType: [],
  searchDateMin: null,
  searchDateMax: null,
  perPage: 100,
  totalRows: 0,
  loading: false,
  loadingCategories: false,
  categories: [],
  types: [],
  selectedCategories: [],
  selectedTypes: null
};

export const dataWarehouseSlice = createSlice({
  name: 'loading',
  initialState,
  reducers: {
    setSortDirection: (state, action) => {
      state.sortDirection = action.payload;
    },
    setColumn: (state, action) => {
      state.column = action.payload;
    },
    setSearchName: (state, action) => {
      state.searchName = action.payload;
    },
    setSearchExplanation: (state, action) => {
      state.searchExplanation = action.payload;
    },
    setSearchSource: (state, action) => {
      state.searchSource = action.payload;
    },
    setSearchCategory: (state, action) => {
      state.searchCategory = action.payload;
    },
    setSearchType: (state, action) => {
      state.searchType = action.payload;
    },
    setDateMin: (state, action) => {
      state.searchDateMin = action.payload;
    },
    setDateMax: (state, action) => {
      state.searchDateMax = action.payload;
    },
    setPerPage: (state, action) => {
      state.perPage = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setCategories: (state, action) => {
      state.categories = [state.categories, ...action.payload]
    },
    setTypes: (state, action) => {
      state.types = [state.types, ...action.payload]
    },
    setSelectedCategories: (state, action) => {
      state.selectedCategories = action.payload;
    },
    setSelectedTypes: (state, action) => {
      state.selectedTypes = action.payload;
    },
    resetParams: (state, action) => {
      state.sortDirection = null;
      state.column = null;
      state.searchName = '';
      state.searchExplanation = '';
      state.searchSource = '';
      state.searchCategory = [];
      state.searchDateMin = null;
      state.searchDateMax = null;
      state.perPage = 100;
      state.totalRows = 0;
      state.loading = false;
      state.loadingCategories = false;
      state.dataWarehouse = [];
      state.categories = [];
    }
  },
  extraReducers(builder) {
    builder
      .addCase(fetchDataWarehouse.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(fetchDataWarehouse.fulfilled, (state, action) => {
        if (action.payload?.data) {
          state.dataWarehouse = [{id: 0}, ...action.payload?.data];
          state.totalRows = action.payload.total;
        }
        state.loading = false;
        
      })
      .addCase(fetchDataWarehouse.rejected, (state, action) => {
        state.loading = false;
        if (action.error.message !== 'axios request canceled') {
          state.error = action.error.message;
        }
      })
      .addCase(fetchCategories.pending, (state, action) => {
        state.loadingCategories = true;
      })
      .addCase(fetchCategories.fulfilled, (state, action) => {
        state.categories = action.payload;
        state.loadingCategories = false;
      })
      .addCase(fetchCategories.rejected, (state, action) => {
        state.loading = false;
        if (action.error.message !== 'axios request canceled') {
          state.error = action.error.message;
        }
      })
      .addCase(fetchTypes.pending, (state, action) => {
        state.loadingCategories = true;
      })
      .addCase(fetchTypes.fulfilled, (state, action) => {
        state.types = action.payload;
        state.loadingCategories = false;
      })
      .addCase(fetchTypes.rejected, (state, action) => {
        state.loading = false;
        if (action.error.message !== 'axios request canceled') {
          state.error = action.error.message;
        }
      })
      .addCase(createDataWarehouse.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(createDataWarehouse.fulfilled, (state, action) => {
        state.loading = false;
        state.dataWarehouse = [{id:0}, action.payload, ...state.dataWarehouse.filter((item) => item.id !== 0)];
      })
      .addCase(createDataWarehouse.rejected, (state, action) => {
        state.loading = false;
        if (action.error.message !== 'axios request canceled') {
          state.error = action.error.message;
        }
      })
      .addCase(updateDataWarehouse.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(updateDataWarehouse.fulfilled, (state, action) => {
        state.loading = false;
        let index = state.dataWarehouse.findIndex((item) => item.id === action.payload.id);
        if (index > -1) {
          state.dataWarehouse[index] = action.payload;
        }
      })
      .addCase(updateDataWarehouse.rejected, (state, action) => {
        state.loading = false;
        if (action.error.message !== 'axios request canceled') {
          state.error = action.error.message;
        }
      })
      .addCase(createCategory.pending, (state, action) => {
        state.loadingCategories = true;
      })
      .addCase(createCategory.fulfilled, (state, action) => {
        state.loadingCategories = false;
        state.categories = [...state.categories, action.payload];
        state.selectedCategories = [...state.selectedCategories, {'label': action.payload.name, 'value': action.payload.id}];
      })
      .addCase(createCategory.rejected, (state, action) => {
        state.loadingCategories = false;
        if (action.error.message !== 'axios request canceled') {
          state.error = action.error.message;
        }
      })
      .addCase(removeWarehouse.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(removeWarehouse.fulfilled, (state, action) => {
        state.loading = false;
        state.dataWarehouse = state.dataWarehouse.filter((item) => item.id !== action.payload);
      })
      .addCase(removeWarehouse.rejected, (state, action) => {
        state.loading = false;
        if (action.error.message !== 'axios request canceled') {
          state.error = action.error.message;
        }
      })
  },
});

export const { resetParams, setSortDirection, setColumn, setSearchName, setSearchExplanation, setSearchSource, setPerPage, setLoading, setPlacementsMax, setPlacementsMin, setDateMax, setDateMin, setSearchCategory, setSearchType, setCategories, setSelectedCategories, setSelectedTypes } = dataWarehouseSlice.actions;
export const getError = (state) => state.dataWarehouse.error;
export const getDataWarehouse = (state) => state.dataWarehouse.dataWarehouse;
export const getPerPage = (state) => state.dataWarehouse.perPage;
export const getSortDirection = (state) => state.dataWarehouse.sortDirection;
export const getSearchCategory = (state) => state.dataWarehouse.searchCategory;
export const getSearchType = (state) => state.dataWarehouse.searchType;
export const getColumn = (state) => state.dataWarehouse.column;
export const getTotalRows = (state) => state.dataWarehouse.totalRows;
export const getLoading = (state) => state.dataWarehouse.loading;
export const getLoadingCategories = (state) => state.dataWarehouse.loadingCategories;
export const getCategories = (state) => state.dataWarehouse.categories;
export const getTypes = (state) => state.dataWarehouse.types;
export const getSelectedCategories = (state) => state.dataWarehouse.selectedCategories;
export const getSelectedTypes = (state) => state.dataWarehouse.selectedTypes;
export const getParamsForURLQuery = (state) =>  ({
  'sortDirection': state.dataWarehouse.sortDirection,
  'column': state.dataWarehouse.column,
  'searchName': state.dataWarehouse.searchName,
  'searchExplanation': state.dataWarehouse.searchExplanation,
  'searchSource': state.dataWarehouse.searchSource,
  'searchCategory': state.dataWarehouse.searchCategory?.map(c => c.value).join(),
  'searchType': state.dataWarehouse.searchType?.map(c => c.value).join(),
  'perPage': state.dataWarehouse.perPage,
  'searchDateMin': state.dataWarehouse.searchDateMin,
  'searchDateMax': state.dataWarehouse.searchDateMax
});

export default dataWarehouseSlice.reducer;
