import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchCmCampaignStatusHistory, fetchCmCampaignStatuses, getCmCampaignStatusHistory, getCmCampaignStatuses, getLoading, removeStatusHistory, storeCmCampaignStatusHistory } from "./campaignStatusesSlice";
import axios from "axios";
import { Loading } from "../loader/Loading";
import { checkPermission, formatDateMedium } from "../../help/help";
import { Tooltip } from "../../components/Tooltip";
import Modal from "../../components/Modal";
import { useNavigate, useLocation } from "react-router";
import Select from 'react-select';
import './campaignStatuses.scss';

export function CampaignStatuses({id, editCardRef, remount, setRemount}) {

    const dispatch = useDispatch();
    const statuses = useSelector(getCmCampaignStatuses);
    const statusHistory = useSelector(getCmCampaignStatusHistory);
    const cancelToken = axios.CancelToken;
    const source = cancelToken.source();
    const loading = useSelector(getLoading);
    const comment = useRef(null);
    let navigate = useNavigate();
    let location = useLocation();
    const urlSearchParams = new URLSearchParams(location.search);
    const selectedStatus = useRef(null);

    useEffect(() => {
        let statuses = urlSearchParams.get('statuses');
        if (statuses && id) {
            dispatch(fetchCmCampaignStatuses({source}));
            dispatch(fetchCmCampaignStatusHistory({id, source}));
        }
        return (() => {
            source.cancel('axios request canceled');
        });
    }, [urlSearchParams.get('statuses'), id]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        const myModal = document.getElementById('status_card');
        if (myModal) {
            myModal.addEventListener('hidden.bs.modal', onCloseModal);
        }
        return (() => {
            source.cancel('axios request canceled');
            if (myModal) {
                myModal.removeEventListener('hidden.bs.modal', onCloseModal);
            }
        });
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const onCloseModal = () => {
        let searchParams = new URLSearchParams(document.location.search)
        searchParams.delete('statuses')
        editCardRef?.current?.click();
        navigate(`./?${searchParams}`)  
    }

    const statusCardModal = {
        title: "Statuses",
        target: "status_card",
        size: 'xl',
        disableFooter: true
    };

    const add = async (id) => {
        let status = selectedStatus.current?.getValue();
        let status_value = status.length > 0 ? status[0]?.value : null;
        if (status_value) {
            let data = {
                comment: comment.current.value,
                cm_campaign_status_id: status_value,
                cm_campaign_id: id,
            }
            await dispatch(storeCmCampaignStatusHistory({data, source})).unwrap();
            setRemount(remount + 1);
            comment.current.value = '';
            selectedStatus.current.setValue(null);
        }

    }

    const onDeleting = (id) => {
        if (window.confirm('Are you sure you want to delete status?')) {
            dispatch(removeStatusHistory({source, id}));
        }
    }

    const formatOptionLabel = ({ value, label, color }) => (
        <div className="d-flex align-items-center">
            <span style={{backgroundColor: color}} className="wrapper-cm-status">
                {label}
            </span>
        </div>
    );

    return (<>
        <Modal props={statusCardModal} loading={loading}>
            <div className="table-responsive">
                {loading ? <Loading fullScreen="true" /> :
                    <table className="table table-striped table-campaign-statuses">
                        <thead>
                            <tr>
                                <th scope="col" className='row-7'>Name</th>
                                <th scope="col" className='row-7'>Created at</th>
                                <th scope="col" className='row-35'>Comment</th>
                                <th scope="col">Status history</th>
                                {checkPermission('role_pr', 'fe-successful-campaigns') ? <>
                                <th scope="col">Act.</th>
                                </> : <></>}
                            </tr>
                        </thead>
                        <tbody>
                            {statusHistory?.map((data, index) =>
                                <tr key={index}>
                                    <td className='row-7'><div>{data?.user?.name}</div></td>
                                    <td className='row-7'><div>{formatDateMedium(data.created_at)}</div></td>
                                    <td className='row-35'>
                                        <Tooltip text={data.comment ? data.comment : ''}>
                                            <div>{data.comment}</div>
                                        </Tooltip>
                                    </td>
                                    <td className='row-13'>
                                        <div title={data?.status?.name}>
                                            <span style={{backgroundColor: data?.status?.color}} className="wrapper-cm-status">
                                                {data?.status?.name}
                                            </span>
                                        </div>
                                    </td>
                                    {checkPermission('role_pr', 'fe-successful-campaigns') ? <>
                                    <td >{index > 0 ? <i className="bi bi-x text-danger" onClick={() => onDeleting(data.id)}></i> : ''}</td>
                                    </> : <></>}
                                </tr>
                            )}
                        </tbody>
                    </table>
                }
            </div>
            <div className='mb-3'>
                <label>Comment:</label>
                <textarea ref={comment} className="form-control"></textarea>
            </div>
            <div className='mb-3 d-flex'>
                <Select options={statuses.map(c => ({'label': c.name, 'value': c.id, 'color': c.color}))} className="me-1 flex-grow-1 flex-shrink-1" ref={selectedStatus} formatOptionLabel={formatOptionLabel}/>
                <button className="btn btn-primary" onClick={() => add(id)}>Add</button>
            </div>    
        </Modal>
    </>)
}