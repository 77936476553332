import axios from 'axios';
import React from 'react';

import AsyncCreatableSelect from 'react-select/async-creatable';
import { createCategory, syncCategories } from './campaignIdeasAPI';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { getCategories, getLoading } from './campaignIdeasSlice';

export default function SelectCategories({selectedCategories, id}) {
    const [getSelectedCategories, setSelectedCategories] = useState(selectedCategories?.map(c => ({'label': c.name, 'value': c.id})));
    const categories = useSelector(getCategories);
    const loading = useSelector(getLoading);
    const [loadingSelect, setLoadingSelect] = useState(false);
    const cancelToken = axios.CancelToken;
    const source = cancelToken.source();

    const handleChange = (newValue) => {
        setSelectedCategories(newValue);
    };


    const categoriesOptions = () => {
        return categories.map(c => ({'label': c.name, 'value': c.id}));
    }

    const promiseOptions = async (inputValue) => {
        if (inputValue === '') {
            return categoriesOptions();
        }
    };
    
    const addCategory = async (inputValue) => {
        try {
            setLoadingSelect(true);
            const newCat = {'name': inputValue, 'type': 1};
            const res = await createCategory(newCat, source);
            setSelectedCategories([...getSelectedCategories, {'label': res.name, 'value': res.id}]);
            setLoadingSelect(false);
        } catch (error) {
            setLoadingSelect(false);
        }
    }

    const onBlurSelect = async () => {
        try {
            setLoadingSelect(true);
            await syncCategories(getSelectedCategories.map(s => s.value).join(), id, source);
            setLoadingSelect(false);
        } catch (error) {
            setLoadingSelect(false);
        }
    }

return (
    <>
    <AsyncCreatableSelect
        cacheOptions
        className='categories-select'
        isClearable
        onChange={handleChange}
        isMulti
        isLoading={loading || loadingSelect}
        value={getSelectedCategories}
        defaultOptions
        loadOptions={promiseOptions}
        onCreateOption={addCategory}
        onBlur={onBlurSelect}
        menuPosition={'fixed'}
        menuPlacement="auto"
    />
    
    </>
);

}