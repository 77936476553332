import apiClient from '../../services/api';

export async function storeFiles(files, press_release_id) {
    try {
        const response = await apiClient.post(`/api/press-releases-media/${press_release_id}`, files);
        return response.data;
    } catch (error) {
        if (error?.response?.data?.message) {
            throw new Error(error?.response?.data?.message);
        }
    }
}

export async function getAllImages(press_release_id) {
    try {
        const response = await apiClient.get(`/api/press-releases-media/allImages/${press_release_id}`);
        return response.data;
    } catch (error) {
        if (error?.response?.data?.message) {
            throw new Error(error?.response?.data?.message);
        }
    }
}

export async function createDuplicatePR(pr) {
    try {
        const response = await apiClient.post(`/api/press-releases/duplicate-pr`, pr);
        return response.data;
    } catch (error) {
        if (error?.response?.data?.message) {
            throw new Error(error?.response?.data?.message);
        }
    }
}