
import React, { useState, useEffect, useRef } from 'react';
import { createList, status, updateFolder, menuItems, setItems, loading } from '../features/lists/listNavigationSlice';
import { useSelector, useDispatch } from 'react-redux';
import "bootstrap-icons/font/bootstrap-icons.css";
import { useParams, useNavigate } from 'react-router';
import { Loading } from '../features/loader/Loading';
import Modal from './Modal';
import { deleteItem, searchItem } from '../features/lists/listNavigationSlice';
import { ClientInformation } from '../features/client-information/ClientInformation';
import { checkActiveModule } from '../help/help';
import axios from 'axios';
import { createDuplicatePR } from '../features/press-releases/pressReleasesAPI';
import { _getUser } from '../features/auth/loginSlice';
import { setError } from '../features/error-handling/errorHandlingSlice';

export default function SidebarLeft({ typeOfList }) {
    const userLocalStorage = useSelector(_getUser);
    const dispatch = useDispatch();
    let navigate = useNavigate();
    const localStatus = useSelector(status);
    const localMenuItems = useSelector(menuItems);
    const localLoadingItems = useSelector(loading);
    const [nameFolder, setNameFolder] = useState('');
    const [nameItem, setNameItem] = useState('');
    const [isLoading, setLoading] = useState(false);
    const [isOpen, setOpen] = useState(false);
    const { id } = useParams();
    const [folderId, setFolderId] = useState('');
    const [firstLoad, setFirstLoad] = useState(true);
    const [mirrorOnCampaign, setMirrorOnCampaign] = useState(true);
    const [filteredItems, setFilteredItems] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [clientName, setClientName] = useState('');
    const [selectedItem, setSelectedItem] = useState(null);
    const [scrollTo, setScrollTo] = useState(null);
    const [duplicatePR, setDuplicatePR] = useState(false);
    const [showFilterUser, setShowFilterUser] = useState(false);
    const [searchByUser, setSearchByUser] = useState('');
    const cancelToken = axios.CancelToken;
    const source = cancelToken.source();

    useEffect(() => {
        if (localMenuItems && id) {
            let campaignSelected = localMenuItems.children.find(c => c.id === parseInt(id));
            if (campaignSelected?.is_folder === 1 || (localMenuItems.id === parseInt(id) && localMenuItems.is_folder === 1) || localMenuItems.id === 0) {
                openSidebarLeft();
            }
        }
        if (localLoadingItems) {
            setLoading(true);
        } else {
            setLoading(false);
        }
        if (scrollTo && localMenuItems && localMenuItems.children.find(c => c.id === parseInt(scrollTo))) {
            scrollToEl(scrollTo);
        }
    }, [localMenuItems, localLoadingItems, scrollTo]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (checkLoginCurrentDay()) {
            setOpen(true);
            clickBtnSidebar.current.click();
        }
        return (() => {
            source.cancel('axios request canceled');
        });
    }, [typeOfList]) // eslint-disable-line react-hooks/exhaustive-deps


    useEffect(() => {
        return (() => {
            setFilteredItems(null);
            setSearchTerm('');
            setSearchByUser('');
            setShowFilterUser(false);
            setScrollTo(null);
        });
    }, [navigate])

    useEffect(() => {
        if (firstLoad && id) {
            setFirstLoad(false);
        }
    }, [localMenuItems, firstLoad]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (!showFilterUser) {
            setSearchByUser('');
        }
    }, [showFilterUser]) // eslint-disable-line react-hooks/exhaustive-deps

    const openSidebarLeft = () => {
        if (!isOpen && checkLoginCurrentDay()) {
            clickBtnSidebar.current.click();
            setOpen(true);
        }        
    }

    const checkLoginCurrentDay = () => {
        return (parseInt(localStorage.getItem('first_login_current_day')) === 0 || !checkActiveModule(userLocalStorage, 'training-items'));
    }

    const onClickItem = (id) => {
        navigate(`/${typeOfList}/${id}`)
    }

    const addNewFolder = async () => {
        let data = {
            name: nameFolder,
            is_folder: 1,
            parent_id: localMenuItems.id,
            user_id: userLocalStorage.id,
            mirrorOnCampaign: typeOfList === 'press-releases' && mirrorOnCampaign ? true : false,
            node_level: localMenuItems.node_level + 1
        }
        let validation = validFolderNameOnRoot();
        if (data.name && validation) {
            setLoading(true);
            try {
                const res = await dispatch(createList({ data, typeOfList })).unwrap();
                if (res.id) {
                  setScrollTo(res.id);
                }
                setLoading(false);
            } catch (error) {
                dispatch(setError(error?.message));
                setLoading(false);
            }
        }
    };

    const addNewListItem = async () => {
        let data = {
            name: nameItem,
            is_folder: 0,
            parent_id: localMenuItems.id,
            user_id: userLocalStorage.id,
            mirrorOnCampaign: typeOfList === 'press-releases' && mirrorOnCampaign ? true : false,
            node_level: localMenuItems.node_level
        }
        if (data.name && !duplicatePR) {
            setLoading(true);
            try {
                const res = await dispatch(createList({ data, typeOfList })).unwrap();
                if (res) {
                    setItems(res);
                    navigate(`/${typeOfList}/${res.id}`)
                    setLoading(false);
                }
            } catch (error) {
                setLoading(false);
                dispatch(setError(error?.message));
            }
        }
        if (duplicatePR) {
            setLoading(true);
            try {
                const res = await createDuplicatePR({id: duplicatePR, name: nameItem, mirrorOnCampaign: mirrorOnCampaign});
                if (res) {
                    setItems(res);
                    navigate(`/${typeOfList}/${res.id}`)
                    setLoading(false);
                }
            } catch (error) {
                setLoading(false);
                dispatch(setError(error?.message));
            }
        }
    };

    const editFolderName = async () => {
        const data = {
            name: nameFolder
        }
        let validation = validFolderNameOnRoot();
        if (data.name && validation) {
            setLoading(true);
            try {
                await dispatch(updateFolder({ id: folderId, data, typeOfList })).unwrap();
                setLoading(false);
            } catch (error) {
                setLoading(false);
                dispatch(setError(error?.message));
            }
        }
    };
    

    const validFolderNameOnRoot = () => {
        if (nameFolder === '') {
            return true;
        }
        const isOnRoot = isOnRootCampaignAndPR();
        if (isOnRoot && !/^((?!-))(xn--)?[a-z0-9][a-z0-9-_]{0,61}[a-z0-9]{0,}\.?((xn--)?([a-z0-9\-.]{1,61}|[a-z0-9-]{0,30})\.[a-z-1-9]{2,})$/.test(nameFolder)) {
            return false;
        }
        return true;
    }

    const isOnRootCampaignAndPR = () => {
        if ((typeOfList === 'press-releases' || typeOfList === 'campaigns') && localMenuItems?.name === 'root') {
            return true;
        }
        return false;
    }

    const newFolder = {
        title: 'Add folder',
        target: 'newFolder',
        canSave: validFolderNameOnRoot(),
        add: addNewFolder
    }

    const editFolder = {
        title: 'Edit folder name',
        target: 'edit_folder',
        canSave: validFolderNameOnRoot(),
        add: editFolderName
    }

    const newListItem = {
        title: 'Add new item',
        target: 'newListItem',
        add: addNewListItem
    }

    const resetFields = () => {
        setNameFolder('');
        setNameItem('');
        setDuplicatePR(null);
        document.querySelector("#newListItem #exampleModalLabel").innerHTML = `Add new item`;
        setMirrorOnCampaign(true);
    };
    
    const onClickEditFolder = (data) => {
        setFolderId(data.id);
        setNameFolder(data.name);
    }

    const onClickCampaignInfo = (name) => {
        setClientName(name);
        document.getElementById('exampleModalLabel').innerHTML = `Client Information - ${name}`;
    }

    const clickBtnSidebar = useRef(null);

    const search = async () => {
        setLoading(true);
        try {
            if (searchTerm?.length > 0 || searchByUser?.length > 0) {
                const res = await dispatch(searchItem({searchTerm, searchByUser, typeOfList})).unwrap();
                setFilteredItems(res);
            } else {
                setFilteredItems(null);
            }
            
            setLoading(false);
        } catch (error) {
            setLoading(false);
            dispatch(setError(error?.message));
        }
    };

    const onEnterSearchInput = () => {
        search();
    }

    const onClickCleanInput = () => {
        setSearchTerm('');
        setSearchByUser('');
        setFilteredItems(null);
    }

    const clientInfoPropModal = {
        title: 'Client Information',
        target: 'clientInformation',
        size: 'xl',
        disableFooter: true,
        add: () => {}
    }

    const deleteItemPropModal = {
        title: 'Delete Item',
        target: 'delete_item',
        nameSaveButton: 'DELETE',
        danger: true,
        add: async () => {
            if (selectedItem?.id) {
                await deleteItemById(selectedItem.id, typeOfList);
            }
        }
    }

    const deleteItemById = async (itemId, typeOfList) => {
        try {
            setLoading(true);
            await dispatch(deleteItem({id: itemId, typeOfList: typeOfList})).unwrap();
            const _childrenItems = localMenuItems.children.filter(i => i.id.toString() !== itemId.toString());
            dispatch(setItems({...localMenuItems, children: _childrenItems}));
            setLoading(false);
            if (parseInt(selectedItem.id) === parseInt(id)) {
                navigate(`/${typeOfList}/${selectedItem.parent_id}`);
                setOpen(true);
            }
        } catch (error) {
            dispatch(setError(error?.message));
            setLoading(false);
        }
    }

    function scrollToEl(el){
        const divElement = document.getElementsByClassName(`item-${el}`)[0];
        if (divElement) {
            divElement.scrollIntoView({ block: 'start', behavior: 'smooth'});
        }
    }

    const duplicatePressRelease = (e, id, name) => {
        e.stopPropagation();
        e.preventDefault();
        setNameItem(name + ' copy');
        document.querySelector("#newListItem #exampleModalLabel").innerHTML = `Duplicate pr- ${name}`;
        setDuplicatePR(id);
    }

    return (<>
        <Modal props={clientInfoPropModal}>
            <ClientInformation data={clientName} key={clientName}/>
        </Modal>
        <Modal props={deleteItemPropModal}>
            Are you sure you want to delete <i>{selectedItem?.name}</i>?
        </Modal>
        <button className={`btn btn-primary btn-sidebar ${localStatus === 'loading' ? 'disabled' : ''}`} ref={clickBtnSidebar} type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasExample" aria-controls="offcanvasExample">
            <i className="bi bi-chevron-right"></i>
        </button>

        <div className="offcanvas offcanvas-start sidebar-left" subindex="-1" id="offcanvasExample" aria-labelledby="offcanvasExampleLabel">
            <div className="offcanvas-header flex-column">
                <div className='d-flex justify-content-between align-items-center' style={{width: '100%'}}>
                    <div className='d-flex'>
                        <h5 className="offcanvas-title" id="offcanvasExampleLabel">Menu</h5>
                        <div className='btn-group me-auto ms-2'>
                            <input type="text" className="form-control" placeholder="Search" onChange={(e) => setSearchTerm(e.target.value)} onKeyPress={(e) => e.key === 'Enter' && onEnterSearchInput(e.target.value)} value={searchTerm}/>
                            {filteredItems ? <i className="bi bi-x text-danger clean-input cursor-pointer" onClick={onClickCleanInput}></i> : ''}
                            <button className="btn btn-primary" type="button" onClick={search}><i className="bi bi-search"></i></button>
                        </div>
                        <i className={`bi ${showFilterUser ? 'bi-person-fill' : 'bi-person'} cursor-pointer m-auto ms-2`} onClick={() => setShowFilterUser(!showFilterUser)}></i>
                    </div>
                    <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                </div>
                {showFilterUser ? <input type="text" className="form-control mt-1" placeholder="Name" onChange={(e) => setSearchByUser(e.target.value)} onKeyPress={(e) => e.key === 'Enter' && onEnterSearchInput(e.target.value)} value={searchByUser}/> : ''}
                
            </div>
            <div className="offcanvas-body pt-0">
                <div>
                    <Modal props={newFolder}>
                        <label>Name</label>
                        <input type="text" className={`form-control ${validFolderNameOnRoot() ? '' :'is-invalid'}`} onChange={(e) => setNameFolder(e.target.value)} value={nameFolder} placeholder={isOnRootCampaignAndPR() ? 'domain.com': ''}/>
                        <div className='text-danger' style={{minHeight: 25}}>{!validFolderNameOnRoot() ? <i>Please enter a valid domain name</i>: ''}</div> 
                        { typeOfList === 'press-releases' ?
                            <div className="form-check mt-1">
                                <input className="form-check-input" type="checkbox" value={mirrorOnCampaign} onChange={() => setMirrorOnCampaign(!mirrorOnCampaign)} checked={mirrorOnCampaign}/>
                                <label className="form-check-label">
                                    Mirror folder structure on campaign
                                </label>
                            </div> : <></>}
                    </Modal>
                    <Modal props={editFolder}>
                        <label>Name</label>
                        <input type="text" className={`form-control ${validFolderNameOnRoot() ? '' :'is-invalid'}`} onChange={(e) => setNameFolder(e.target.value)} value={nameFolder} />
                    </Modal>
                    <Modal props={newListItem}>
                        <label>Name</label>
                        <input type="text" className="form-control" onChange={(e) => setNameItem(e.target.value)} value={nameItem} />
                        { typeOfList === 'press-releases' ?
                            <div className="form-check mt-1">
                                <input className="form-check-input" type="checkbox" value={mirrorOnCampaign} onChange={() => setMirrorOnCampaign(!mirrorOnCampaign)} checked={mirrorOnCampaign}/>
                                <label className="form-check-label">
                                    Mirror item structure on campaign
                                </label>
                            </div> : <></>}
                    </Modal>
                    {isLoading ? <Loading fullScreen="true" /> : ''}
                    { filteredItems ? <>
                    {!isLoading && filteredItems.length === 0 ? <div className='box-list'>Not Found</div> : ''}
                    {!isLoading && filteredItems.length > 0 && filteredItems.map(
                            b => <div key={b?.id} className="box-list">
                                <a className={`list-items d-flex p-2 align-items-center`} style={{color: 'black'}} href={`/${typeOfList}/${b.id}`} target='_blank' rel="noreferrer">
                                <span className="pe-2">
                                    <i className={`bi ${b.is_folder ? 'bi-folder' : 'bi-card-text'}`}></i>
                                </span>
                                <div style={{flex: 1, width: 0}}>
                                        <div className="path-select" style={{maxWidth: '95%'}}>{b?.path}</div>
                                        <div style={{ display: "flex" }}>
                                            <div className='text-box-sidebar' style={{maxWidth: '95%'}}>{b?.name}</div>
                                        </div>
                                </div>
                                {(typeOfList === 'lists' || typeOfList === 'campaigns') && b.count > -1 && !b.is_folder ?
                                    <div className="ms-auto">{b.count}
                                        <i className="ms-1 bi bi-person-fill"></i>
                                        {typeOfList === 'campaigns' && b.finish && b.start ? <i className="ms-1 bi text-success bi-send-check-fill"></i> : ''}
                                        {typeOfList === 'campaigns' && !b.finish && b.start ? <i className="ms-1 bi text-warning bi-send"></i> : ''}
                                    </div> : ''}
                            </a></div>)
                    }
                    
                    </> : <>
                    {!isLoading && localMenuItems ? <div className="d-flex flex-row align-items-center mb-1 top-sidebar">
                        {<button className={`${localMenuItems.id === 0 ? 'disabled' : ''} btn btn-up me-2`} onClick={() => onClickItem(localMenuItems.parent_id)}><i className="bi bi-arrow-90deg-up"></i></button>}
                        <div className={`${localMenuItems.id === 0 ? 'disabled' : ''} btn btn-up me-2`} onClick={() => onClickItem(localMenuItems.parent_id)}>{localMenuItems.name}</div>
                        <div className="ms-auto p-2" data-bs-toggle="modal" data-bs-target="#newFolder" onClick={() => resetFields()}><i className="bi bi-folder-plus icons"></i></div>
                        {(localMenuItems.node_level > 1) || (localMenuItems.node_level !== 0 && typeOfList === 'lists' )  ? 
                            <div className="p-2" data-bs-toggle="modal" data-bs-target="#newListItem" onClick={() => resetFields()}><i className="bi bi-plus-square icons"></i></div> : ''}
                    </div> : ''}
                    {
                        !isLoading && localMenuItems !== undefined && localMenuItems?.children ? localMenuItems.children.map(
                            b => <div key={b?.id} className={`box-list item-${b?.id}`}>
                                {b && b.is_folder && b.parent_id === 0 && typeOfList === 'campaigns' ? <div className="camp-information"><i className="bi bi-info-circle" data-bs-toggle="modal" data-bs-target="#clientInformation" onClick={() => onClickCampaignInfo(b.name)}></i></div> : ''}
                                {b?.is_folder ? <div className="edit-folder"><i className="bi bi-pencil-fill" data-bs-toggle="modal" data-bs-target="#edit_folder" onClick={() => onClickEditFolder(b)}></i></div> : ''}
                                <div className="delete-item"><i className="bi bi-x text-danger" data-bs-toggle="modal" data-bs-target="#delete_item" onClick={() => setSelectedItem(b)}></i></div>
                                <div className={`list-items d-flex p-2 ${b.id.toString() === id ? 'active' : ''} `} onClick={(e) => onClickItem(b.id)}>
                                {b?.is_folder ? <span className="pe-2 "><i className="bi bi-folder"></i></span> : <span className="pe-2"><i className="bi bi-card-text"></i></span>}
                                <div className='text-box-sidebar'>{b?.name}</div>
                                {(typeOfList === 'lists' || typeOfList === 'campaigns') && b.count > -1 && !b.is_folder ?
                                    <div className="ms-auto me-2">{b.count}
                                        <i className="ms-1 bi bi-person-fill"></i>
                                        {typeOfList === 'campaigns' && b.finish && b.start ? <i className="ms-1 bi text-success bi-send-check-fill"></i> : ''}
                                        {typeOfList === 'campaigns' && !b.finish && b.start ? <i className="ms-1 bi text-warning bi-send"></i> : ''}
                                        
                                    </div> : ''}
                                {(typeOfList === 'press-releases' && !b.is_folder) ? <div className="ms-auto me-2">
                                        <i className="bi bi-file-earmark-plus" data-bs-toggle="modal" data-bs-target="#newListItem" onClick={(e) => duplicatePressRelease(e, b.id, b?.name)}></i>
                                    </div> : ''}
                            </div></div>) : ''
                    }
                    </>}
                    
                </div>
            </div>
        </div></>)
}