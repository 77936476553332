import apiClient from '../../services/api';

export async function fetchTrainingItems(source) {
    try {
        const response = await apiClient.get(`/api/training-items`, { cancelToken: source.token });
        return response.data;
    } catch (error) {
        if (error?.response?.data?.message) {
            throw new Error(error?.response?.data?.message);
        }
    }
}

export async function fetchDailyTrainingItem(source) {
    try {
        const response = await apiClient.get(`/api/daily-training-item`, { cancelToken: source.token });
        return response.data;
    } catch (error) {
        if (error?.response?.data?.message) {
            throw new Error(error?.response?.data?.message);
        }
    }
}