import React, { useState } from 'react';
import Tabs from '../../components/Tabs';
import { ClientCommunicationTab } from './ClientCommunicationTab';
import { ClientDetailsTab } from './ClientDetailsTab';
import './clientInformation.scss';
import { LatestInvoicesTab } from './LatestInvoicesTab';
import { LatestPlacementsTab } from './LatestPlacementsTab';
export function ClientInformation({data}) {

    const TABS = ['Communication', 'Latest Campaigns', 'Latest Placements', 'Latest Invoices'];
    const [selectedTab, setSelectedTab] = useState(TABS[0]);
    

    return (<>
        <Tabs tabs={TABS} selectedTab={selectedTab} setSelectedTab={setSelectedTab}></Tabs>
        <ClientDetailsTab selectedTab={selectedTab} data={data} tab={TABS[1]}/>
        <ClientCommunicationTab selectedTab={selectedTab} data={data} tab={TABS[0]}/> 
        <LatestPlacementsTab selectedTab={selectedTab} data={data} tab={TABS[2]}/>
        <LatestInvoicesTab selectedTab={selectedTab} data={data} tab={TABS[3]}/>
    </>
    );
}
