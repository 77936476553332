import axios from 'axios';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { formatDateShort } from '../../help/help';
import { fetchExternalCampaigns, getParamsForURLQuery, setDateMin, setDateMax } from './campaignIdeasSlice';

export default function FilterDate() {

    const dispatch = useDispatch();
    const cancelToken = axios.CancelToken;
    const source = cancelToken.source();
    const params = useSelector(getParamsForURLQuery);

    const onBlurInput = (val, type) => {
        val = val ? val : null;
        if (params.searchDateMin !== val && type === 'min') {
            dispatch(setDateMin(val ? formatDateShort(val) : null));
            dispatch(fetchExternalCampaigns({
                page: 1,
                ...params,
                searchDateMin: val ? formatDateShort(val) : null,
                source}));
        }
		if (params.searchDateMax !== val && type === 'max') {
            dispatch(setDateMax(val ? formatDateShort(val) : null));
            dispatch(fetchExternalCampaigns({
                page: 1,
                ...params,
                searchDateMax: val ? formatDateShort(val) : null,
                source}));
        }
	}

    return (<div className="d-flex flex-column">
        <input type="date" className="form-control mb-1 mt-1" placeholder="Min" style={{width: '100%'}} onKeyPress={(e) => e.key === 'Enter' && onBlurInput(e.currentTarget.value, 'min')} onBlur={(e) => onBlurInput(e.currentTarget.value, 'min')} defaultValue={params.searchDateMin ? params.searchDateMin : ''}/>
        <input type="date" className="form-control mb-1" placeholder="Max" style={{width: '100%'}} onKeyPress={(e) => e.key === 'Enter' && onBlurInput(e.currentTarget.value, 'max')} onBlur={(e) => onBlurInput(e.currentTarget.value, 'max')} defaultValue={params.searchDateMax ? params.searchDateMax : ''}/>
    </div>)
}