import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiClient from '../../services/api';

export const fetchJournalist = createAsyncThunk(
  'api/fetchJournalist',
  async ({inputValue, source}) => {
    try {
      const response = await apiClient.get(`/api/journalist-filter/${inputValue}`, { cancelToken: source.token });
      return response.data;
    } catch (error) {
      if (error?.response?.data?.message) {
        throw new Error(error?.response?.data?.message);
      }
    }
  }
);

export const createList = createAsyncThunk("api/createList", async ({ data, typeOfList }) => {
  try {
    const response = await apiClient.post(`/api/${typeOfList}`, data);
    return response.data;
  } catch (error) {
    if (error?.response?.data?.message) {
      throw new Error(error?.response?.data?.message);
    }
  }
});

export const fetchItemById = createAsyncThunk(
  'api/fetchItemById',
  async ({id, typeOfList, source}) => {
    try {
      const response = await apiClient.get(`/api/menu/${typeOfList}/${id}`, { cancelToken: source.token });
      return response.data;
    } catch (error) {
      if (error?.response?.data?.message) {
        throw new Error(error?.response?.data?.message);
      }
    }
  }
);

export const updateFolder = createAsyncThunk("api/updateFolder", async ({ id, data, typeOfList }) => {
  try {
    const response = await apiClient.put(`/api/${typeOfList}/${id}`, data);
    return response.data;
  } catch (error) {
    if (error?.response?.data?.message) {
      throw new Error(error?.response?.data?.message);
    }
  }
});

export const deleteItem = createAsyncThunk(
  'api/deleteItem',
  async ({id, typeOfList}) => {
    const response = await apiClient.delete(`/api/${typeOfList}/${id}`);
    // The value we return becomes the `fulfilled` action payload
    return response.data;
  }
);

export const searchItem = createAsyncThunk(
  'searchItem',
  async ({searchTerm, searchByUser, typeOfList}) => {
    const response = await apiClient.post(`/api/${typeOfList}/search`, {
      search_term: searchTerm,
      search_by_user: searchByUser
    });
    // The value we return becomes the `fulfilled` action payload
    return response.data;
  }
);

const initialState = {
  items: null,
  breadcrumbs: [],
  status: "idle",
  error: '',
  loading: false
};

const sortSidebar = (payload) => {
  const allFolder = payload?.children.filter(f => f.is_folder === 1 && !f.is_deleted)
      .sort((a, b) => {
          return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
      });
  const allItems = payload?.children.filter(f => f.is_folder === 0 && !f.is_deleted)
      .sort((a, b) => {
          return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
      });
  if (allFolder?.length > 0 && allItems?.length > 0) {
    return { ...payload, children: allFolder.concat(allItems) };
  }
  if (allFolder?.length > 0) {
    return { ...payload, children: allFolder };
  }
  if (allItems?.length > 0) {
    return { ...payload, children: allItems };
  }
  return payload;
};

const listNavigation = createSlice({
  name: "listNavigation",
  initialState,
  reducers: {
    resetItems(state) {
      state.items = null;
    },
    setItems(state, action) {
      state.items = action.payload;
    },
    updateItems(state, action) {
      const indexEl = state.items?.children?.findIndex(i => i.id.toString() === action.payload?.id.toString());
      if (indexEl > -1) {
        state.items.children[indexEl] = {...state.items.children[indexEl], ...action.payload}
      }
    },
  },
  extraReducers(builder) {
    builder
      .addCase(createList.fulfilled, (state, action) => {
        state.items.children.push(action.payload);
        state.items = sortSidebar(state.items);
      })
      .addCase(updateFolder.fulfilled, (state, action) => {
        state.status = "succeeded";
        const indexEl = state.items.children?.findIndex(i => i.id.toString() === action.payload.id.toString());
        if (indexEl > -1) {
          state.items.children[indexEl] = {...state.items.children[indexEl], ...action.payload}
        }
      })
      .addCase(fetchItemById.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(fetchItemById.fulfilled, (state, action) => {
        if (action.payload) {
          state.status = "succeeded";
          state.items = sortSidebar(action.payload);
          state.loading = false;
        } else {
          state.items = null;
        }
      })
      .addCase(fetchItemById.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
        state.loading = true;
      })
  },
});
export const { resetItems, setItems, updateItems } = listNavigation.actions;
export const status = (state) => state.listNavigation.status;
export const menuItems = (state) => state.listNavigation.items;
export const loading = (state) => state.listNavigation.loading;

export default listNavigation.reducer;