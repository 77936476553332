import { Tooltip as BsTooltip } from "bootstrap/dist/js/bootstrap.min.js"
import React, { useEffect, useRef } from "react"

export const Tooltip = (p) => {
    const childRef = useRef(undefined)

    useEffect(() => {
        const t = new BsTooltip(childRef.current, {
            title: p.text,
            placement: "bottom",
            trigger: "hover",
            html: true
        })
        return () => t.dispose()
    }, [p.text])

    return React.cloneElement(p.children, { ref: childRef })
}