import axios from 'axios';
import React from 'react';
import { useRef } from 'react';
import { insertPlacements } from './campaignIdeasAPI';

export default function AddLinks({onClickNameRow, selectedRow, setLoadingTab, setError}) {
    const cancelToken = axios.CancelToken;
    const source = cancelToken.source();
    const textareaRef = useRef(null);

    const addPlacements = async () => {
        setLoadingTab(true);
        let data = {
            campaign_id: selectedRow?.id,
            links: textareaRef.current.value.split(/\n/)
        }
        try {
            await insertPlacements(data, source);
            setLoadingTab(false);
            onClickNameRow(selectedRow);
        } catch (error) {
            setError(error);
            setLoadingTab(false);
        }
    }
    
    return (<div className="d-flex flex-column add-links">
        <textarea ref={textareaRef}></textarea>
        <button className="btn btn-primary button-add-links" onClick={addPlacements}>Add</button>
    </div>)
}