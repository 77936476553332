import axios from 'axios';
import React from 'react';
import { useEffect } from 'react';
import { useRef } from 'react';
import { useDispatch } from 'react-redux';
import Modal from '../../components/Modal';
import { createExternalCampaign } from './campaignIdeasSlice';
import SelectClient from './SelectClient';

export default function AddCampaign({resetModal}) {
    const dispatch = useDispatch();
    const cancelToken = axios.CancelToken;
    const source = cancelToken.source();
    const textareaRef = useRef(null);
    const campaignNameRef = useRef(null);
    const clientRef = useRef(null);

    useEffect(() => {
        clientRef.current.clearValue();
        campaignNameRef.current.value = '';
        textareaRef.current.value = '';
    }, [resetModal])

    const modalIdeaMachineProp = {
		title: 'Create new campaign',
        target: 'new_campaign',
        nameSaveButton: 'Add campaign',
        size: 'lg',
		add: () => {
            let client = clientRef.current.getValue();
            let data = {
                'name': campaignNameRef.current.value,
                'type': 3,
                'user_id': 0,
                'client': client[0]?.value ? client[0].value : '',
                'links': textareaRef.current.value?.split(/\n/)
            }
			dispatch(createExternalCampaign({source, data}));
		}
	}
    
    return (<>  
    <Modal props={modalIdeaMachineProp}>
        <div className='mt-1'>
            <label htmlFor="campaign-name">Campaign Name</label>
            <input type="text" id="campaign-name" className="form-control" ref={campaignNameRef}/>
        </div>
        <div className='mt-2'>
            <label>Select Client</label>
            <SelectClient clientRef={clientRef}/>
        </div>
        <div className='mt-2'>
            <label>Links</label>
            <textarea className="form-control" ref={textareaRef} style={{minHeight: '150px'}}></textarea>
        </div>
    </Modal>
    </>)
}