import axios from 'axios';
import React from 'react';
import { useState } from 'react';
import { useRef } from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { fetchClients, fetchExternalCampaigns, getClients, getParamsForURLQuery, getSearchClient, setSearchClient } from './campaignIdeasSlice';

export default function FilterClient() {

    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const clients = useSelector(getClients);
    const cancelToken = axios.CancelToken;
    const source = cancelToken.source();
    const params = useSelector(getParamsForURLQuery);
    const searchClient = useSelector(getSearchClient);
    const clientsValues = useRef();
    

    useEffect(() => {
        if (!clients) {
            loadClients();
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const loadClients = async () => {
        setLoading(true);
        await dispatch(fetchClients(source)).unwrap();
        setLoading(false);
    };
    
    const onBlurSelect = () => {
        const currentValue = clientsValues.current.getValue();
        if (JSON.stringify(searchClient) !== JSON.stringify(currentValue)) {
            dispatch(setSearchClient(currentValue));
            dispatch(fetchExternalCampaigns({
                page: 1,
                ...params,
                searchClient: currentValue.map(c => c.value).join(),
                source}));
        }
	}

    return (
        <Select
            ref={clientsValues}
            className='categories-select'
            cacheOptions
            isLoading={loading}
            defaultOptions
            isMulti
            isClearable
            options={clients}
            defaultValue={searchClient}
            onBlur={onBlurSelect}
            onKeyDown={(e) => e.key === 'Enter' && onBlurSelect()}
        />)
        
}