import React, {useState, useEffect} from 'react';
import { Link } from "react-router-dom";
import Logout from '../features/auth/Logout';
import { useNavigate } from 'react-router';
import NotificationsMenuItem from '../features/notifications/NotificationsMenuItem';
import { useSelector } from 'react-redux';
import { _getUser } from '../features/auth/loginSlice';
// import { checkPermission } from '../help/help';

export default function Navigation() {
    const user = useSelector(_getUser);
    let navigate = useNavigate();
    const [pathName, setPathName] = useState('');
    useEffect(() => {
        setPathName(window.location.pathname);
    }, [navigate])
    return (
        <nav className="navbar navbar-expand-lg navbar-light bg-light shadow">
            <div className="container-fluid">
                <Link to="/">JournoResearch</Link>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarNavDropdown">
                    <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                        <li className="nav-item">
                            <Link to="/" className={`nav-link ${pathName === '/' ? 'active' : ''}`}>Home</Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/lists" className={`nav-link ${pathName.includes('lists') ? 'active' : ''}`}>Lists</Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/press-releases" className={`nav-link ${pathName.includes('press-releases') ? 'active' : ''}`}>Press Releases</Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/campaigns" className={`nav-link ${pathName.includes('campaigns') && !pathName.includes('-manager') ? 'active' : ''}`}>Campaigns</Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/blocked-emails" className={`nav-link ${pathName.includes('blocked-emails') ? 'active' : ''}`}>Blocked Emails</Link>
                        </li>
                        {/* <li className="nav-item">
                            <Link to="/pr-review" className={`nav-link ${pathName.includes('pr-review') ? 'active' : ''}`}>PR Review</Link>
                        </li> */}
                        {/* <li className="nav-item">
                            <Link to="/work-flow" className={`nav-link ${pathName.includes('work-flow') ? 'active' : ''}`}>Workflow Manager</Link>
                        </li> */}
                        {/* {checkPermission('role_pr', 'fe-access-campaign-manager') ? <>
                        <li className="nav-item">
                            <Link to="/campaigns-manager" className={`nav-link ${pathName.includes('/campaigns-manager') ? 'active' : ''}`}>Campaigns Manager</Link>
                        </li>
                        </> : <></>} */}
                        <li className="nav-item">
                            <Link to="/idea-machine" className={`nav-link ${pathName.includes('/idea-machine') ? 'active' : ''}`}>Idea Machine</Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/mails-statistics" className={`nav-link ${pathName.includes('/mails-statistics') ? 'active' : ''}`}>Mails Statistics</Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/press-release-approval" className={`nav-link ${pathName.includes('/press-release-approval') ? 'active' : ''}`}>Press release approval</Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/data-approval" className={`nav-link ${pathName.includes('/data-approval') ? 'active' : ''}`}>Data approval</Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/data-warehouse" className={`nav-link ${pathName.includes('/data-warehouse') ? 'active' : ''}`}>Data warehouse</Link>
                        </li>
                    </ul>
                    <span className="me-5">
                        <Link to="/notifications">
                            <NotificationsMenuItem />
                        </Link>
                    </span>
                    <span className="navbar-text pe-2">
                        {user?.name}
                    </span>
                    <span className="navbar-item">
                        <Logout />
                    </span>
                </div>
            </div>
        </nav>
    );
}