import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiClient from '../../services/api';

export const fetchPressReleasesById = createAsyncThunk(
  'api/fetchPressReleasesById',
  async ({id, source}) => {
    try {
      const response = await apiClient.get(`/api/press-releases/${id}`, { cancelToken: source.token });
      return response.data;
    } catch (error) {
      if (error?.response?.data?.message) {
        throw new Error(error?.response?.data?.message);
      }
    }
  }
);

export const updatePressRelease = createAsyncThunk(
  'api/updatePressRelease',
  async ({ id, data }) => {
    try {
      const response = await apiClient.put(`/api/press-releases/${id}`, data);
      return response.data;
    } catch (error) {
      if (error?.response?.data?.message) {
        throw new Error(error?.response?.data?.message);
      }
    }
  }
);

export const previewPressRelease = createAsyncThunk(
  'api/previewPressRelease',
  async ({ id, data }) => {
    try {
      const response = await apiClient.post(`/api/press-releases/preview/${id}`, data);
      return response.data;
    } catch (error) {
      if (error?.response?.data?.message) {
        throw new Error(error?.response?.data?.message);
      }
    }
  }
);

export const fetchTemplates = createAsyncThunk(
  'api/templates',
  async ({source}) => {
    try {
      const response = await apiClient.get(`/api/templates/press-release`, { cancelToken: source.token });
      return response.data;
    } catch (error) {
      if (error?.response?.data?.message) {
        throw new Error(error?.response?.data?.message);
      }
    }
  }
);

export async function recordPressRelease(data) {
  try {
      const response = await apiClient.post(`/api/press-release-records`, data);
      return response.data;
  } catch (error) {
      if (error?.response?.data?.message) {
          throw new Error(error?.response?.data?.message);
      }
  }
}

export async function pressReleaseRecords(id) {
  try {
      const response = await apiClient.get(`/api/press-release-records/${id}`);
      return response.data;
  } catch (error) {
      if (error?.response?.data?.message) {
          throw new Error(error?.response?.data?.message);
      }
  }
}

export async function getLatestRecord(id) {
  try {
      const response = await apiClient.get(`/api/press-release-records/latest/${id}`);
      return response.data;
  } catch (error) {
      if (error?.response?.data?.message) {
          throw new Error(error?.response?.data?.message);
      }
  }
}

export async function getRecordById(id) {
  try {
      const response = await apiClient.get(`/api/press-release-records/record/${id}`);
      return response.data;
  } catch (error) {
      if (error?.response?.data?.message) {
          throw new Error(error?.response?.data?.message);
      }
  }
}

const initialState = {
  status: "idle",
  pressRelease: null,
  error: ''
};

const pressReleasesSlice = createSlice({
  name: "pressReleases",
  initialState,
  reducers: {
    setPressRelease(state, action) {
      state.pressRelease = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchPressReleasesById.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchPressReleasesById.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.pressRelease = action.payload;
      })
      .addCase(fetchPressReleasesById.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(updatePressRelease.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.pressRelease = action.payload;
      });
  },
});
export const { setPressRelease } = pressReleasesSlice.actions;
//export const campaign = (state) => state.pressReleases.campaign;
export const pressRelease = (state) => state.pressReleases.pressRelease;

export default pressReleasesSlice.reducer;