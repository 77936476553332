import React, { useEffect, useState } from 'react';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { fetchTrainingItems } from './trainingItemsAPI';
import axios from 'axios';
import Modal from '../../components/Modal';
import './training-items.scss';
import { Loading } from '../loader/Loading';
import ReactHtmlParser from 'html-react-parser';
import { setError } from '../error-handling/errorHandlingSlice';
import { useDispatch } from 'react-redux';

export function TrainingItems() {
	const [isLoading, setLoading] = useState(false);
	const [trainingItems, setTrainingItems] = useState([]);
	const [selectedItem, setSelectedItem] = useState(null);
	const cancelToken = axios.CancelToken;
	const source = cancelToken.source();
	const dispatch = useDispatch();

	useEffect(() => {
		getTrainingItems();
		const myModal = document.getElementById('slider');
        myModal.addEventListener('hidden.bs.modal', onCloseModal);
        return (() => {
            source.cancel('axios request canceled');
            setSelectedItem(null);
			setTrainingItems([]);
            myModal.removeEventListener('hidden.bs.modal', onCloseModal);
        });
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	const onCloseModal = () => {
		setSelectedItem(null);
		let videoElement = document.getElementById('playback-video');
		if (videoElement) {
			videoElement.pause();
			videoElement.removeAttribute('src'); // empty source
			videoElement.load();
		}
	}

	const getTrainingItems = async () => {
		try {
			setLoading(true);
			const items = await fetchTrainingItems(source);
			setTrainingItems(items);
			setLoading(false);
		} catch (error) {
			if (error?.message !== 'axios request canceled') {
				dispatch(setError(error?.message));
				setLoading(false);
			}
		}
	}

	const settings = {
		dots: true,
		infinite: true,
		speed: 500,
		slidesToShow: trainingItems?.length > 5 ? 6 : trainingItems.length,
		slidesToScroll: trainingItems?.length > 5 ? 6 : trainingItems.length,
		autoplay: true,
		autoplaySpeed: 4900,
		responsive: [{
				breakpoint: 1980,
				settings: {
					slidesToShow: trainingItems?.length > 4 ? 4 : trainingItems.length,
					slidesToScroll: trainingItems?.length > 4 ? 4 : trainingItems.length,
					infinite: true,
					dots: true
				}
			},
			{
				breakpoint: 1824,
				settings: {
					slidesToShow: trainingItems?.length > 3 ? 3 : trainingItems.length,
					slidesToScroll: trainingItems?.length > 3 ? 3 : trainingItems.length,
					infinite: true,
					dots: true
				}
			},
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: trainingItems?.length > 2 ? 2 : trainingItems.length,
					slidesToScroll: trainingItems?.length > 2 ? 2 : trainingItems.length,
					infinite: true,
					dots: true
				}
			},
			{
				breakpoint: 480,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
					dots: false
				}
			}
			// You can unslick at a given breakpoint now by adding:
			// settings: "unslick"
			// instead of a settings object
		]
	}

	const slidePropModal = {
		title: '',
      target: 'slider',
		disableFooter: true,
		size: 'lg'
	}

	return (<div className='mb-5 me-md-3'>
		<Modal props={slidePropModal}>
			{ selectedItem ? <>
			<h4>{selectedItem.title}</h4>
			<p>{selectedItem.subtitle}</p>
			<p>Category: {selectedItem.category_name}</p>
			<div className='d-flex justify-content-center mb-4'>
			{(parseInt(selectedItem.video_type) === 1 && selectedItem.video) ?
				<iframe width="560" height="315" src={`https://www.youtube.com/embed/${selectedItem.video}`} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe> : ''}
			{(parseInt(selectedItem.video_type) !== 1 && selectedItem.video) ? <>
				<video id="playback-video" width="560" height="315" controls><source src={selectedItem.video} type="video/mp4" /></video></> : ''}
			</div>
			<div>{ReactHtmlParser(selectedItem?.description ? selectedItem.description : '')}</div>
			</> : '' }			
		</Modal>
		{isLoading ? <Loading fullScreen="true"/> : 
			<Slider {...settings}>
				{trainingItems.map((t) => <div key={t.id}>
					<div className='box-training-image' data-bs-toggle="modal" data-bs-target="#slider" onClick={() => setSelectedItem(t)}>
						{(t.video_type !== 1 || t.video_thumbnail?.includes('journo'))?
						<img src={t.video_thumbnail}  alt={t.title} /> : ''}
						{(t.video_type === 1 && !t.video_thumbnail?.includes('journo')) ?
						<img src={`https://img.youtube.com/vi/${t.video}/hqdefault.jpg`} alt={t.title} /> : ''}
					</div>
					<div className='box-training-details'>
						{t.title}
					</div>					
				</div>)}
			</Slider>
		}
	</div>
  );
}
