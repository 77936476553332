import React, { useEffect } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchNotifications,
  getStatus,
  getError,
  getUnreadCount,
  fetchSilentNotification,
  getNotifications,
  setSelectedNotification,
  getSelectedNotification
} from "./notificationsSlice";
import "./notifications.scss";
import notification from "../../notification.svg";
import Alert from "../../components/Alert";
import { Loading } from "../loader/Loading";
import { useLocation } from "react-router-dom";

export default function NotificationsMenuItem() {
  const cancelToken = axios.CancelToken;
  const source = cancelToken.source();

  const dispatch = useDispatch();
  const status = useSelector(getStatus);
  const error = useSelector(getError);
  const unreadCount = useSelector(getUnreadCount);
  const notifications = useSelector(getNotifications);
  const selectedNotification = useSelector(getSelectedNotification);
  const location = useLocation();

  useEffect(() => {
    if (location?.pathname === '/notifications') {
      dispatch(fetchNotifications({source}));
    } else {
      dispatch(fetchSilentNotification({ source }));
    }
    
    const interval = setInterval(() => {
        if (status !== 'loading') {
          let modal = document.querySelectorAll(".modal.show");
          if (modal.length === 0) {
            dispatch(fetchSilentNotification({ source }));
          }
        }
    }, 60000);
    
    return () => {
      source.cancel("axios request canceled");
      clearInterval(interval);
    };
  }, [location.pathname]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
      const notificationAutoShowModal = notifications.find(n => n.auto_show_modal && !n.read_at);
      let modal = document.querySelectorAll(".modal.show");
      if (notifications && notificationAutoShowModal && modal.length === 0 && (location?.pathname === '/notifications' || location?.pathname === '/')) {
        dispatch(setSelectedNotification(notificationAutoShowModal));
      }
    
  }, [notifications]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (selectedNotification && selectedNotification?.auto_show_modal) {
      openModal();
    }
  }, [selectedNotification]); // eslint-disable-line react-hooks/exhaustive-deps

  const openModal = async () => {
    const notificationButton = document.getElementById('notification-button-modal');
    notificationButton.click();
  }

  return (
    <>
    {error ? <Alert error={error}></Alert> : ""}
      {status !=='succeeded' ? (
        <Loading fullScreen="true" />
      ) :
    <div className="notifications-menu-item">
        <div className="icon" id="bell" style={{width: '26px', margin: '0 auto'}}>
          <img src={notification} alt="notification" />
          {unreadCount ? (
            <div className="unread-count"><span>{unreadCount}</span></div>
          ) : (
            <></>
          )}
        </div>
    </div> }
    </>
  );
}
