
import React from 'react';

export default function Nav({ selectedListItem, children, typeOfList, spaceBetween = true }) {
    return (<ul className="nav nav-pills pt-3 pb-3 align-items-center">
        <li className="nav-item pe-2">
            <h3 className="pb-0 mb-0">{selectedListItem?.name}</h3>
            {selectedListItem?.path ? <p className="text-muted mb-0">{selectedListItem?.path}</p> : ''}
        </li>
        <li className={`nav-item pe-2 ${spaceBetween ? 'ms-auto' : ''}`}></li>
        {children}
    </ul>)
}