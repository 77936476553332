import React, { useState, useEffect } from 'react';
import { EditorTinyMCE } from './EditorTinyMCE';
import { UserSelect } from './UserSelect';

export function DataCol({selectedItem, setSelectedItem, users}) {
    // const [name, setName] = useState('');
    // const [url, setUrl] = useState('');
    const [data] = useState(selectedItem?.data ? selectedItem.data: []);

    // const createNewEntry = () => {
    //     if (name && url) {
    //         const foundUrl = data.find(e => (e.url.toLowerCase().trim() === url.toLowerCase().trim()));
    //         if (!foundUrl) {
    //             setData([{name: name, url: url}, ...data]);
    //         }
    //     }
    //     document.getElementById('d-name').value = '';
    //     document.getElementById('d-url').value = '';
    // };

    // const deleteItem = (idx) => {
    //     let d = data.filter((v, index) => idx !== index);
    //     setData(d);
    // }

    useEffect(() => {
        setSelectedItem({...selectedItem, data: data});
    }, [data]) // eslint-disable-line react-hooks/exhaustive-deps

    // const onChangeBrief = (text) => {
    //     setSelectedItem({...selectedItem, date_brief: text});
    // }

    return (<>
        <EditorTinyMCE selectedItem={selectedItem} setSelectedItem={setSelectedItem} type={'data_description'} label={'Data description'}/>
        {/* <div className="table-responsive mt-3">
            <table className="table table-striped">
                <thead>
                    <tr>
                        <th scope="col">Name</th>
                        <th scope="col">URL</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr key='add'>
                        <td><input className="form-control" id="d-name" onChange={(e) => setName(e.target.value)}></input></td>
                        <td><input className="form-control" id="d-url" onChange={(e) => setUrl(e.target.value)}></input></td>
                        <td><i className={`bi bi-plus-lg text-primary ps-1 pe-1 ${name && url ? '' : 'disabled'}`} onClick={() => createNewEntry()}/></td>
                    </tr>
                    {data?.map((data, index) =>
                        <tr key={index}>
                            <td>{data.name}</td>
                            <td><a href={data.url} target="blank">{data.url}</a></td>
                            <td><i className="bi bi-dash text-danger ps-1 pe-1" onClick={() => deleteItem(index)}/></td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div> */}
        <UserSelect selectedItem={selectedItem} setSelectedItem={setSelectedItem} itemKey='data_user' users={users}></UserSelect>   
        {/* <label className='mt-3'>Data brief</label>
        <input type="text" className="form-control" onChange={e => onChangeBrief(e.target.value)} value={selectedItem?.date_brief}></input> */}
    </>);
}
