import React from 'react';
import { useSelector } from 'react-redux';
import CreatableSelect from 'react-select/creatable';
import { getClients, getLoadingClients, getSearchClient } from './campaignIdeasSlice';

export default function SelectClient({clientRef}) {

    const clients = useSelector(getClients);
    const searchClient = useSelector(getSearchClient);
    const loading = useSelector(getLoadingClients);
    
    return (
        <CreatableSelect
            ref={clientRef}
            className='categories-select'
            cacheOptions
            isLoading={loading}
            defaultOptionss
            isClearable
            options={clients}
            defaultValue={searchClient}
        />)
        
}