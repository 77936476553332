import axios from 'axios';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchDataWarehouse, getParamsForURLQuery, setSearchSource  } from './dataWarehouseSlice';

export default function FilterSource() {

    const cancelToken = axios.CancelToken;
    const source = cancelToken.source();
    const dispatch = useDispatch();
    const params = useSelector(getParamsForURLQuery); 

    const onBlur = (val) => {
        if (params.searchSource !== val) {
            dispatch(setSearchSource(val));
            dispatch(fetchDataWarehouse({
                page: 1,
                ...params,
                searchSource: val,
                source}));
        }
	}
    
    return (<input type="text"
        className="form-control"
        placeholder="Search by source"
        onBlur={(e) => onBlur(e.currentTarget.value)}
        defaultValue={params.searchSource}
        onKeyPress={(e) => e.key === 'Enter' && onBlur(e.currentTarget.value)}
    />)
}