import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    error: null,
  };
  
  const errorHandling = createSlice({
    name: "errorHandling",
    initialState,
    reducers: {
      resetError(state) {
        state.error = null;
      },
      setError(state, action) {
        state.error = action.payload;
      },
    },
  });
  export const { resetError, setError } = errorHandling.actions;
  export const getError = (state) => state.errorHandling.error;
  
  export default errorHandling.reducer;