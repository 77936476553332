import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { fetchUser, fetchLogin } from "./authAPI";
import * as Sentry from "@sentry/react";

const initialState = {
    value: false,
    user: null,
    status: "idle",
};

export const signIn = createAsyncThunk(
    "login/signIn",
    async (data, { rejectWithValue }) => {
        try {
            const response = await fetchLogin(data);
            return { data: response.data, status: response.status };
        } catch (err) {
            if (!err.response) {
                throw err;
            }

            return rejectWithValue(err.response.data);
        }
    }
);

export const getUser = createAsyncThunk(
    "login/getUser",
    async (any, { rejectWithValue }) => {
        try {
            const response = await fetchUser();
            return response.data;
        } catch (err) {
            if (!err.response) {
                throw err;
            }

            return rejectWithValue(err.response.data);
        }
    }
);

export const loginSlice = createSlice({
    name: "login",
    initialState,
    reducers: {
        setLoggedIn: (state) => {
            state.value = true;
        },
        setLoggedOut: (state) => {
            state.value = false;
            state.user = null;
            localStorage.clear();
            // localStorage.setItem(
            //     "loggedIn",
            //     JSON.stringify({ loggedIn: false, user: null })
            // );
            document.cookie =
                "XSRF-TOKEN=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
            document.cookie =
                "laravel_session=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
        },
    },
    extraReducers: (builder) => {
        // Add reducers for additional action types here, and handle loading state as needed
        builder.addCase(getUser.fulfilled, (state, action) => {
            // Add user to the state array
            state.user = action.payload;
            state.value = true;
            // localStorage.clear();
            // localStorage.setItem(
            //     "loggedIn",
            //     JSON.stringify({ loggedIn: true, user: action.payload })
            // );
        });
        builder.addCase(getUser.rejected, (state, action) => {
            // Add user to the state array
            state.user = null;
            state.value = false;

            // localStorage.clear();
            // localStorage.setItem(
            //     "loggedIn",
            //     JSON.stringify({ loggedIn: false, user: null })
            // );
        });
    },
});

export const { setLoggedIn, setLoggedOut } = loginSlice.actions;

export const isLoggedIn = (state) => {
    const user = state.login.user;
    if (user) {
        Sentry.setUser({
            id: user?.id,
            username: user?.name,
            email: user?.email,
            ip_address: "{{auto}}"
        });
    }
    return (
        state.login.value
    );
};
export const _getUser = (state) => state.login.user;

export default loginSlice.reducer;
