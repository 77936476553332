import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiClient from '../../services/api';

export const fetchCampaignsRun = createAsyncThunk(
  'api/fetchCampaignsRun',
  async (id) => {
    try {
      const response = await apiClient.get(`/api/campaigns/run/${id}`);
      return response.data;
    } catch (error) {
      if (error?.response?.data?.message) {
        throw new Error(error?.response?.data?.message);
      }
    }
  }
);

export const fetchCampaignsEmails = createAsyncThunk(
  'api/campaigns-emails',
  async ({id, source}) => {
    try {
      const response = await apiClient.get(`/api/campaigns-emails/${id}`, { cancelToken: source.token });
      return response.data;
    } catch (error) {
      if (error?.response?.data?.message) {
        throw new Error(error?.response?.data?.message);
      }
    }
  }
);

export const updateCampaignsEmails = createAsyncThunk(
  'api/updateCampaignsEmails',
  async ({ id, data }) => {
    try {
      const response = await apiClient.post(`/api/campaigns-emails/${id}`, data);
      return response.data;
    } catch (error) {
      if (error?.response?.data?.message) {
        throw new Error(error?.response?.data?.message);
      }
    }
  }
);

export const fetchCampaignById = createAsyncThunk(
  'api/fetchCampaignById',
  async ({ id, source }) => {
    try {
      const response = await apiClient.get(`/api/campaigns/${id}`, { cancelToken: source.token });
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (error) {
      if (error?.message === 'axios request canceled') {
        throw new Error(error?.message);
      }
      if (error?.response?.data?.message) {
        throw new Error(error?.response?.data?.message);
      }
    }
  }
);

export const updateCampaignById = createAsyncThunk(
  'api/updateCampaignById',
  async ({ id, data }) => {
    try {
    const response = await apiClient.put(`/api/campaigns/${id}`, data);
    // The value we return becomes the `fulfilled` action payload
    return response.data;
    } catch (error) {
      if (error?.response?.data?.message) {
        throw new Error(error?.response?.data?.message);
      }
    }
  }
);

export const skipEmailToSend = createAsyncThunk(
  'api/skipEmailToSend',
  async ({id, skip}) => {
    const response = await apiClient.put(`/api/campaigns-emails/skipEmailToSend/${id}/${skip}`);
    // The value we return becomes the `fulfilled` action payload
    return response.data;
  }
);

export const fetchStatistics = createAsyncThunk(
  'fetchStatistics',
  async ({id, step, source}) => {
    const response = await apiClient.get(`/api/campaigns-emails/statistics/${id}/${step}`, { cancelToken: source.token });
    // The value we return becomes the `fulfilled` action payload
    return response.data;
  }
);

export const fetchOpenStatistics = createAsyncThunk(
  'fetchOpenStatistics',
  async ({id, resolution = 0, source}) => {
    const response = await apiClient.get(`/api/campaigns-emails/openStatistics/${id}/${resolution}` , { cancelToken: source.token });
    // The value we return becomes the `fulfilled` action payload
    return response.data;
  }
);

export const lastOpenedDate = createAsyncThunk(
  'lastOpenedDate',
  async ({id, source}) => {
    const response = await apiClient.get(`/api/campaigns-emails/lastOpenedDate/${id}`, { cancelToken: source.token });
    // The value we return becomes the `fulfilled` action payload
    return response.data;
  }
);

const initialState = {
  campaign: null,
  campaignsEmails: [],
  unsavedCampaignsEmails: [],
  status: "idle",
  campaignById: null,
  error: '',
  statistics: null,
  openStatistics: null,
};

const campaignsSlice = createSlice({
  name: "campaigns",
  initialState,
  reducers: {
    initPushCampaignsEmails(state, action) {
      const mails = [...state.unsavedCampaignsEmails, ...action.payload];
      state.unsavedCampaignsEmails = mails;
    },
    pushCampaignsEmails(state, action) {
      const mails = [...state.unsavedCampaignsEmails, ...action.payload];
      if (mails?.filter(m => !m.deleted)?.length > 5000) {
        alert('You can only have 5,000 emails in one full sending list.\nPlease consider better targeting with the journalists.');
        return;
      }
      state.unsavedCampaignsEmails = mails;
    },
    deleteCampaignsEmails(state, action) {
      let idx = -1;
      idx = state.unsavedCampaignsEmails.findIndex(e => e.email === action.payload);
      if (idx > -1) {
        if (state.unsavedCampaignsEmails[idx].notSaved) {
          state.unsavedCampaignsEmails.splice(idx, 1);
        } else {
          state.unsavedCampaignsEmails[idx].deleted = 1;
        }
      }
      
    },
    resetCampaignsEmails(state) {
      state.campaignsEmails = null;
      state.unsavedCampaignsEmails = [];
    },
    resetCampaign(state) {
      state.campaign = null;
    },
    setCampaign(state, action) {
      state.campaign = action.payload;
    },
    updateCampaignEmail(state, action) {
      state.campaignsEmails[action.payload.idx] = {...state.campaignsEmails[action.payload.idx], ...action.payload.value}; 
    },
    resetStatistics(state) {
      state.statistics = null;
    },
    resetOpenStatistics(state) {
      state.openStatistics = null;
    }
  },
  extraReducers(builder) {
    builder
      .addCase(fetchCampaignById.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchCampaignById.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.campaign = action.payload;
      })
      .addCase(fetchCampaignById.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(updateCampaignById.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.campaign = action.payload;
      })
      .addCase(fetchCampaignsEmails.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.campaignsEmails = action.payload;
      })
      .addCase(updateCampaignsEmails.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.campaignsEmails = action.payload;
      })
      .addCase(fetchStatistics.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.statistics = action.payload;
      })
      .addCase(fetchOpenStatistics.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.openStatistics = action.payload;
      })
  },
});
export const { initPushCampaignsEmails, pushCampaignsEmails, unshiftCampaignsEmails, deleteCampaignsEmails, resetCampaignsEmails, resetCampaign, setCampaign, updateCampaignEmail, resetStatistics, resetOpenStatistics } = campaignsSlice.actions;
export const campaign = (state) => state.campaigns.campaign;
export const campaignsEmails = (state) => state.campaigns.campaignsEmails;
export const unsavedCampaignsEmails = (state) => state.campaigns.unsavedCampaignsEmails;
export const campaignById = (state) => state.campaigns.campaignById;
export const statistics = (state) => state.campaigns.statistics;
export const openStatistics = (state) => state.campaigns.openStatistics;

export default campaignsSlice.reducer;