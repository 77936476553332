import React from "react";
import ReactHtmlParser from 'html-react-parser';

export default function PreviewEmailContent({ emailBody, emailSubject, signature, icon, previewDisplay, setPreviewDisplay}) {

  return (
    <>
        <div className="preview-email-wrapper">
          {icon ? <>
            <i className={`bi bi-phone ${previewDisplay === 'smartphone' ? 'active' : ''}`} onClick={() => setPreviewDisplay('smartphone')}></i>
            <i className={`bi bi-tablet ${previewDisplay === 'tablet' ? 'active' : ''}`} onClick={() => setPreviewDisplay('tablet')}></i>
          </> : <></>}
          <div className={previewDisplay}>
              <div className="content">
                <p className="email-subjects">{emailSubject}</p>
                <div className="email-body">
                  {ReactHtmlParser(emailBody ? emailBody : '')}
                </div>
                <div className="email-body">
                  {ReactHtmlParser(signature ? signature : '')}
                </div>
              </div>
          </div>
        </div>
    </>
  );
}
