
import axios from 'axios';
import React, {useEffect, useState}  from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReactHtmlParser from 'html-react-parser';
import { createUserLog } from '../features/home/eventsAPI';
import { getSelectedNotification, markNotificationAsRead, setSelectedNotification, setTimeSleepModal } from '../features/notifications/notificationsSlice';
import { _getUser } from '../features/auth/loginSlice';
import { setError } from '../features/error-handling/errorHandlingSlice';

export default function DailyVideo({trainingItem}) {
    const userData = useSelector(_getUser);
    const [videoStarted, setVideoStarted] = useState(false);
    const cancelToken = axios.CancelToken;
    const source = cancelToken.source();
    const dispatch = useDispatch();
    let player = null;
    const selectedNotification = useSelector(getSelectedNotification);

    useEffect(() => {
        if (!window.YT) {
            var tag = document.createElement('script');
            tag.src = 'https://www.youtube.com/iframe_api';
            window.onYouTubeIframeAPIReady = createYT;
            var firstScriptTag = document.getElementsByTagName('script')[0];
            firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (videoStarted) {
            onStartVideo();
        }
    }, [videoStarted]); // eslint-disable-line react-hooks/exhaustive-deps

    const createYT = () => {
        if (window.YT) {
            player = new window.YT.Player('player', {
                videoId: 'M7lc1UVf-VE',
                playerVars: { 'autoplay': 1, 'controls': 0 },
                events: {
                    onReady: onPlayerReady,
                    onStateChange: onPlayerStateChange
                }
            });
        }
    }

    const onPlayerStateChange = (event) => {
        if (event.data === window.YT.PlayerState.PLAYING && !videoStarted) {
            setVideoStarted(true);
        }
      }

    const onPlayerReady = async (event) => {
        dispatch(setTimeSleepModal(event.target.getDuration() * 1000 * 0.75));
        autoStartVideo();
    };

    const autoStartVideo = () => {
        let videoElement = document?.getElementById('playback-video');
        let YTElement = document?.getElementById('player');
		if (videoElement) {
			videoElement.play();
		}
        if (player && YTElement) {
            player.playVideo();
        }
    }


    useEffect(() => {
        const myModal = document.getElementById('notification');
        window.addEventListener('blur', onBlurBrowserTab);
        window.addEventListener('focus', onFocusBrowserTab);
        if (myModal) {
            myModal.addEventListener('hidden.bs.modal', onCloseModal);
        }
        return (() => {
            source.cancel('axios request canceled');
            if (myModal) {
                myModal.removeEventListener('hidden.bs.modal', onCloseModal);
            }
            window.removeEventListener('blur', onBlurBrowserTab)
            window.removeEventListener('focus', onFocusBrowserTab)
        });
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const onBlurBrowserTab = (event) => {
        event.preventDefault();
        let videoElement = document.getElementById('playback-video');
        let YTElement = document.getElementById('player');
		if (videoElement) {
			videoElement.pause();
		}
        if (player && YTElement) {
            player.pauseVideo();
        }
    };
    const onFocusBrowserTab = (event) => {
        event.preventDefault();
        autoStartVideo();
    }; 

    const onCloseModal = () => {
		let videoElement = document.getElementById('playback-video');
		if (videoElement) {
            if (!selectedNotification?.read_at) {
                dispatch(markNotificationAsRead({id: selectedNotification?.id, source}));
            }
			videoElement.pause();
			videoElement.removeAttribute('src'); // empty source
			videoElement.load();
            videoElement.remove();
            dispatch(setSelectedNotification(null));
		}
	}

    const onLoadedMetadata = async (data) => {
        let video = document.getElementById('playback-video');
        if (video) {
            dispatch(setTimeSleepModal(data.target.duration * 1000 * 0.75))
            video.play();
            video.onplay = (event) => {
                setVideoStarted(true);
            };
        }
       
    }

    const onStartVideo = async () => {
        try {
            await createUserLog('TRAINING VIDEO VIEWED', source);
        }
        catch (error) {
            dispatch(setError(error?.message));
        }
    }

    return <>
        {userData ? <>
            {trainingItem ? <>
                <div id={`youtube-player`} />
                <h4>{trainingItem.title}</h4>
                <p>{trainingItem.subtitle}</p>
                {trainingItem.category_name ? <p>Category: {trainingItem.category_name}</p> : ''}
                <div className='d-flex justify-content-center mb-4'>
                {(parseInt(trainingItem.video_type) === 1 && trainingItem.video) ?
                    <iframe width="560" height="315" id="player" src={`https://www.youtube.com/embed/${trainingItem.video}?enablejsapi=1`} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe> : ''}
                {(parseInt(trainingItem.video_type) !== 1 && trainingItem.video) ? <>
                    <video id="playback-video" onLoadedMetadata={onLoadedMetadata} width="560" height="315" controls><source src={trainingItem.video} type="video/mp4" /></video></> : ''}
                </div>
                <div>{ReactHtmlParser(trainingItem?.description ? trainingItem.description : '')}</div>
            </> : ''}
        </> : ''}
       
    </>
}