import axios from 'axios';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchExternalCampaigns, getParamsForURLQuery, setPlacementsMax, setPlacementsMin } from './campaignIdeasSlice';

export default function FilterPlacements() {

    const cancelToken = axios.CancelToken;
    const source = cancelToken.source();
    const dispatch = useDispatch();
    const params = useSelector(getParamsForURLQuery);

    const onBlurInput = (val, type) => {
        val = val ? val : null;
        if (params.searchPlacementsMin !== val && type === 'min') {
            dispatch(setPlacementsMin(val));
            dispatch(fetchExternalCampaigns({
                page: 1,
                ...params,
                searchPlacementsMin: val,
                source}));
        }
		if (params.searchPlacementsMax !== val && type === 'max') {
            dispatch(setPlacementsMax(val));
            dispatch(fetchExternalCampaigns({
                page: 1,
                ...params,
                searchPlacementsMax: val,
                source}));
        }
	}

    return (<div className="d-flex flex-column">
        <input type="text" className="form-control mb-1 mt-1" placeholder="Min" style={{width: '100%'}} onBlur={(e) => onBlurInput(e.currentTarget.value, 'min')} onKeyPress={(e) => e.key === 'Enter' && onBlurInput(e.currentTarget.value, 'min')} defaultValue={params.searchPlacementsMin ? params.searchPlacementsMin : ''} />
        <input type="text" className="form-control mb-1" placeholder="Max" style={{width: '100%'}} onBlur={(e) => onBlurInput(e.currentTarget.value, 'max')} onKeyPress={(e) => e.key === 'Enter' && onBlurInput(e.currentTarget.value, 'max')} defaultValue={params.searchPlacementsMax ? params.searchPlacementsMax : ''} />
    </div>)
}