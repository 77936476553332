import React from 'react';

import CreatableSelect from 'react-select/creatable';
// import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getTypes, getLoadingCategories, setSelectedTypes } from './dataWarehouseSlice';

export default function SelectCategories({id, isDisabled= false, selectedTypes }) {
    const dispatch = useDispatch();
    const types = useSelector(getTypes);
    const loading = useSelector(getLoadingCategories);
    // const [loadingSelect, setLoadingSelect] = useState(false);

    const handleChange = (newValue) => {
        dispatch(setSelectedTypes(newValue));
    };


    const typesOptions = () => {
        return types?.map(c => ({'label': c.name, 'value': c.id}));
    }


return (
    <>
    <CreatableSelect
        isDisabled={isDisabled}
        cacheOptions
        className='categories-select'
        isClearable
        onChange={handleChange}
        options={typesOptions()}
        isLoading={loading}
        value={selectedTypes}
        menuPosition={'fixed'}
        menuPlacement="auto"
    />
    
    </>
);

}