
import React, { memo } from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCmCampaignStatuses, getCmCampaignStatuses } from '../features/campaign-statuses/campaignStatusesSlice';
import axios from 'axios';

const CardLegendStatuses = () => {
    const dispatch = useDispatch();
    const statuses = useSelector(getCmCampaignStatuses);
    const cancelToken = axios.CancelToken;
    const source = cancelToken.source();

    useEffect(() => {
        dispatch(fetchCmCampaignStatuses({source}));
        return (() => {
            source.cancel('axios request canceled');
        });
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
        {statuses.length ? <div className='d-flex mt-1' style={{width: '100%'}}>Legend:</div> : ''}
        <div className='legend d-flex gap-2 flex-wrap mb-5 mt-2'>
            {statuses?.map((status, index) => {
                return (
                    <div key={index} className='legend-item'>
                        <div className='legend-color ps-2 pe-2 rounded-3' style={{backgroundColor: status.color}}>
                            <span className='legend-name' style={{color: status.text_color ? status.text_color : 'black' }}>{status.name}</span>
                        </div>
                        
                    </div>
                )
            })
            }
        </div>
        </>
    )
}

export default memo(CardLegendStatuses);