import React from 'react';
import { formatDateMedium } from '../../help/help';
import { Link } from "react-router-dom";
import { Tooltip } from '../../components/Tooltip';

export function LatestActivityCampaigns({data}) {
  return (
    <>
        {data?.length > 0 ?
            <div className="table-responsive table-max-height">
                <table className="table table-striped">
                    <thead>
                        <tr>
                            <th scope="col" className='name-col'>Name</th>
                            <th scope="col">Send</th>
                            <th scope="col" className="text-center">Send nr</th>
                            <th scope="col">Sent time</th>
                            <th scope="col" className="text-center">Nr. of emails</th>
                            <th scope="col" className="text-center">Successful</th>
                            <th scope="col" className="text-center">Open rate</th>
                            <th scope="col" className="text-center">Bounced</th>
                            <th scope="col" className="text-center">Unsubscribed</th>
                            <th scope="col" className="text-center">Failed</th>
                            <th scope="col" className="text-center">Complained</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.map((data, index) =>
                            <tr key={index}>
                                <td className='name-col'>{data.user_name}</td>
                                <td><div className='wrapper-path-td'><Link to={`/campaigns/${data.id}`}>{data.path}<i>{data.path ? ` > ${data.name}` : data.name}</i></Link></div></td>
                                <td className="text-center">{data.step + 1}
                                    { data.step === 0 ? <Tooltip text={`First send`}>
                                    <i className="bi bi-circle-fill text-primary ms-1"></i>
                                    </Tooltip> : ''}
                                </td>
                                <td>{formatDateMedium(data.send_date)}</td>
                                <td className="text-center">{data.statistics.items_count}</td>
                                <td className="text-center">{data.statistics.sent_count}</td>
                                <td className="text-center">{data.statistics.opened_percentage}%</td>
                                <td className="text-center">{data.statistics.bounce_count}</td>
                                <td className="text-center">{data.statistics.unsubscribed_count}</td>
                                <td className={`text-center ${data.statistics.failed_count > 0 ? 'text-danger fw-bold' : ''}`}>{data.statistics.failed_count}</td>
                                <td className={`text-center ${data.statistics.complaint_count > 0 && data.complaint_on_current_step ? 'text-danger fw-bold' : ''}`}>{data.statistics.complaint_count}</td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        : ''}
    </>
  );
}
