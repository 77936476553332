import apiClient from "../../services/api";

export async function fetchFcmTokens(source) {
    try {
        const response = await apiClient.get(`/api/fcm`, {
            cancelToken: source.token,
        });
        return response.data;
    } catch (error) {
        if (error?.response?.data?.message) {
            throw new Error(error?.response?.data?.message);
        }
        throw error;
    }
}

export async function storeFcmToken(source, data) {
    try {
        const response = await apiClient.post(`/api/fcm`, data, {
            cancelToken: source.token,
        });
        return response.data;
    } catch (error) {
        if (error?.response?.data?.message) {
            throw new Error(error?.response?.data?.message);
        }
        throw error;
    }
}

export async function deleteFcmTokens(source) {
    try {
        const response = await apiClient.delete(`/api/fcm`, null, {
            cancelToken: source.token,
        });
        return response.data;
    } catch (error) {
        if (error?.response?.data?.message) {
            throw new Error(error?.response?.data?.message);
        }
        throw error;
    }
}
