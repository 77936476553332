import apiClient from '../../services/api';

export async function fetchWorkFlowItems(per_page, page, filter = [], searchTerm = '', source) {
    try {
        let params = new URLSearchParams({page, per_page});
        filter.forEach(f => {
            params.append('filter[]', f);
        });
        if (searchTerm) {
            params.append('searchTerm', searchTerm);
        }
        const response = await apiClient.get(`/api/work-flow-items?${params}`, { cancelToken: source.token });
        return response.data;
    } catch (error) {
        if (error?.response?.data?.message) {
            throw new Error(error?.response?.data?.message);
        }
    }
}

export async function fetchWorkFlowItem(id, source) {
    try {
        const response = await apiClient.get(`/api/work-flow-items/${id}`, { cancelToken: source.token });
        return response.data;
    } catch (error) {
        if (error?.response?.data?.message) {
            throw new Error(error?.response?.data?.message);
        }
    }
}

export async function addWorkFlowItem(data) {
    try {
        const response = await apiClient.post(`/api/work-flow-items`, data);
        return response.data;
    } catch (error) {
        if (error?.response?.data?.message) {
            throw new Error(error?.response?.data?.message);
        }
    }
}

export async function deleteWorkFlowItem(id) {
    try {
        const response = await apiClient.delete(`/api/work-flow-items/${id}`,);
        return response.data;
    } catch (error) {
        if (error?.response?.data?.message) {
            throw new Error(error?.response?.data?.message);
        }
    }
}

export async function editWorkFlowItem(id, data) {
    try {
        const response = await apiClient.put(`/api/work-flow-items/${id}`, data);
        return response.data;
    } catch (error) {
        if (error?.response?.data?.message) {
            throw new Error(error?.response?.data?.message);
        }
    }
}

export async function fetchClients(source) {
    try {
        const response = await apiClient.get(`/api/work-flow-items/clients`, { cancelToken: source.token });
        return response.data;
    } catch (error) {
        if (error?.response?.data?.message) {
            throw new Error(error?.response?.data?.message);
        }
    }
}

export async function fetchUsers(source) {
    try {
        const response = await apiClient.get(`/api/users`, { cancelToken: source.token });
        return response.data;
    } catch (error) {
        if (error?.response?.data?.message) {
            throw new Error(error?.response?.data?.message);
        }
    }
}