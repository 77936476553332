import { configureStore } from '@reduxjs/toolkit';
import loginReducer from '../features/auth/loginSlice';
import loadingReducer from '../features/loader/loadingSlice';
import listNavigationReducer from '../features/lists/listNavigationSlice';
import listsSlice from '../features/lists/listsSlice';
import campaignsSlice from '../features/campaigns/campaignsSlice';
import pressReleasesSlice from '../features/press-releases/pressReleasesSlice';
import notificationsSlice from '../features/notifications/notificationsSlice';
import campaignIdeasSlice from '../features/campaign-ideas/campaignIdeasSlice';
import approvalItemsSlice from '../features/approval-items/approvalItemsSlice';
import dataWarehouseSlice from '../features/data-warehouse/dataWarehouseSlice';
import campaignStatusesSlice from '../features/campaign-statuses/campaignStatusesSlice';
import errorHandlingSlice from '../features/error-handling/errorHandlingSlice';

export const store = configureStore({
  reducer: {
    login: loginReducer,
    loading: loadingReducer,
    listNavigation: listNavigationReducer,
    lists: listsSlice,
    campaigns: campaignsSlice,
    pressReleases: pressReleasesSlice,
    notifications: notificationsSlice,
    campaignIdeas: campaignIdeasSlice,
    approvalItems: approvalItemsSlice,
    dataWarehouse: dataWarehouseSlice,
    campaignStatuses: campaignStatusesSlice,
    errorHandling: errorHandlingSlice
  },
});
