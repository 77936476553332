import React, { memo, useEffect, useRef, useState } from "react";
import axios from "axios";
import { Loading } from "../loader/Loading";
import "./assigned-campaigns.scss";
import { CampaignSelect } from "../campaigns-manager/CampaignSelect";
import {
  updateCampaign,
  fetchCampaign,
  fetchNotionCampaigns,
} from "../campaigns-manager/campaignsManagerAPI";
import Modal from "../../components/Modal";
import { AssignedCampaignsList } from "./AssignedCampaignsList";
import CampaignTimeline from "../campaigns-manager/CampaignTimeline";
import CampaignLinks from "../campaigns-manager/CampaignLinks";
import { CampaignStatuses } from "../campaign-statuses/CampaignStatuses";
import { useNavigate } from "react-router";
import { getCmCampaignStatusHistory } from "../campaign-statuses/campaignStatusesSlice";
import { useDispatch, useSelector } from "react-redux";
import { setError } from "../error-handling/errorHandlingSlice";

const AssignedCampaigns = () => {
  const dispatch = useDispatch();
  const cancelToken = axios.CancelToken;
  const source = cancelToken.source();
  const [campaign, setCampaign] = useState(null);
  const [isCampaignLoading, setCampaignLoading] = useState(false);
  const [notionCampaigns, setNotionCampaigns] = useState(null);
  const [remount, setRemount] = useState(0);
  const [isLoading, setLoading] = useState(false);
  let navigate = useNavigate();
  const editCardRef = useRef();
  const statusHistory = useSelector(getCmCampaignStatusHistory);

  const getCampaign = async (id) => {
    try {
      setCampaignLoading(true);
      const res = await fetchCampaign(id, source);
      const resNotionCampaigns = await fetchNotionCampaigns(res.site, source);
      let name = document.getElementById('edit_card');
      if (name) {
          name.querySelector('#exampleModalLabel').innerHTML = `Edit card ${res.site} (${res.invoice}) <span style="background-color: ${res?.status_history?.color}; padding: 1px 7px; font-size: 14px; border-radius: 5px;">${res?.status_history?.name ? res.status_history.name : ''}</span>`;
      }
      setNotionCampaigns(resNotionCampaigns);
      setCampaign(res);
      setCampaignLoading(false);
    } catch (error) {
      if (error?.message !== "axios request canceled") {
        dispatch(setError(error?.message));
      }
    }
  };

  useEffect(() => {
    if (campaign?.id && statusHistory?.length > 0) {
      let name = document.getElementById('edit_card');
      if (name) {
          name.querySelector('#exampleModalLabel').innerHTML = `Edit card ${campaign.site} (${campaign.invoice}) <span style="background-color: ${statusHistory[statusHistory.length - 1]?.status?.color}; padding: 1px 7px; font-size: 14px; border-radius: 5px;">${statusHistory[statusHistory.length - 1]?.status?.name ? statusHistory[statusHistory.length - 1].status.name : ''}</span>`;
      }
    }
  }, [statusHistory?.length]) // eslint-disable-line react-hooks/exhaustive-deps

  const editCardModal = {
    title: "Edit card",
    target: "edit_card",
    size: 'xl',
    add: () => { updateCard() }
  };

  const updateCard = async (submitForApproval = false) => {
    try {
      setLoading(true);
      let data = {
        notion_campaigns: campaign.notion_campaigns,
        completed: campaign.completed ? 1 : 0,
        manual_override: campaign.manual_override ? 1 : 0,
        notes: campaign.notes,
        min_ht_links: campaign.min_ht_links,
        min_links: campaign.min_links,
        updated_at: campaign.updated_at,
        submit_for_approval: submitForApproval
      };
      if (campaign.manual_override) {
        data.ht_links = parseInt(campaign.ht_links);
        data.links = parseInt(campaign.links);
      }
      await updateCampaign(data, campaign.id, source);
      setRemount(remount + 1);
      setLoading(false);
    } catch (error) {
      if (error?.message !== "axios request canceled") {
        dispatch(setError(error?.message));
        setLoading(false);
      }
    }
  }

  const messageConfirm = 'Are you sure that the campaign has a min of 8 links, non syndicated and at least 2 HT links?'

  const onClickSubmitForApproval = async () => {
    if (window.confirm(messageConfirm)) {
      updateCard(true);
    }
  }

  const onClickStatuses = () => {
    let searchParams = new URLSearchParams(document.location.search)
    searchParams.delete('statuses')
    searchParams.append('statuses', 1);
    navigate(`./?${searchParams}`)
  }

  const submitForApproval = () => {
    return <div className="me-auto d-flex align-items-center">{!campaign?.submitted_date ? <button className="btn btn-warning me-1" onClick={onClickSubmitForApproval} data-bs-toggle="modal"
    data-bs-target="#edit_card">Submit for review</button> : <div className="me-1">Is marked for review</div>}
    <button className="btn btn-dark me-auto" data-bs-toggle="modal" data-bs-dismiss="modal"
    data-bs-target="#status_card" onClick={onClickStatuses}>Statuses</button>
    </div>
  }

  return (
    <div className="user-assigned-campaigns">
      <button type="button" style={{display: 'none'}} data-bs-toggle="modal" data-bs-target="#edit_card" ref={editCardRef}></button>
      <CampaignStatuses id={campaign?.id} editCardRef={editCardRef} remount={remount} setRemount={setRemount}/>
      <Modal props={editCardModal} footer={submitForApproval()} loading={isCampaignLoading}>
        {isCampaignLoading ? (
          <Loading fullScreen="true" />
        ) : (
          <div className="d-flex flex-wrap">
              <div style={{flex: '1 1 30%', padding: '5px'}} className='wrapper-campaign-select'>
                  <CampaignSelect campaign={campaign} notionCampaigns={notionCampaigns} setCampaign={setCampaign}/>
              </div>
              <div style={{flex: '1 1 70%', overflow: 'hidden', padding: '1.2rem', paddingBottom: '3rem'}} className="wrapper-campaign-links">
                  {campaign ? <CampaignTimeline campaign={campaign} setCampaign={setCampaign} /> : ''}
                  {campaign?.campaign_links ? <CampaignLinks links={campaign.campaign_links} site={campaign?.site}/> : 'Links not found'}
              </div>
          </div>
        )}
      </Modal>
      <div className="row">
        {isLoading ? <Loading fullScreen="true" /> : ""}
        <div className="d-flex flex-wrap p-1 wrapper-user-cards mb-4">
          <AssignedCampaignsList getCampaign={getCampaign} key={remount} />
        </div>
      </div>
    </div>
  );
}
export default memo(AssignedCampaigns)