import axios from 'axios';
import React from 'react';
import { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { fetchDataWarehouse, getTypes, getParamsForURLQuery, getSearchType, setSearchType, getLoadingCategories } from './dataWarehouseSlice';


export default function FilterCategory() {

    const dispatch = useDispatch();
    const searchType = useSelector(getSearchType);
    const types = useSelector(getTypes);
    const params = useSelector(getParamsForURLQuery);
    const cancelToken = axios.CancelToken;
    const source = cancelToken.source();
    const typeValues = useRef();
    const loadingCategories = useSelector(getLoadingCategories);
    
    
    const onBlurSelect = () => {
        const currentValue = typeValues.current.getValue();
        if (JSON.stringify(searchType) !== JSON.stringify(currentValue)) {
            dispatch(setSearchType(currentValue));
            dispatch(fetchDataWarehouse({
                page: 1,
                ...params,
                searchType: currentValue.map(c => c.value).join(),
                source}));
        }
	}
    
    return (<>
        <Select
            ref={typeValues}
            className='categories-select'
            isLoading={loadingCategories}
            cacheOptions
            defaultOptions
            isMulti
            isClearable
            options={types?.map(c => ({'label': c.name, 'value': c.id}))}
            defaultValue={searchType}
            onBlur={onBlurSelect}
            onKeyDown={(e) => e.key === 'Enter' && onBlurSelect()}
        />
        </>)
}