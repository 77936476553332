import React, {useState, useEffect} from 'react';
import apiClient from '../../services/api';
import { useDispatch } from 'react-redux';
import './Login.scss';
import { setLoggedOut, signIn, isLoggedIn} from './loginSlice';
import { toggleLoading } from '../loader/loadingSlice';
import { useNavigate, useLocation } from 'react-router';
import { Loading } from '../loader/Loading';
import { useSelector } from 'react-redux';
import { isLoading } from '../loader/loadingSlice';
 
export default function Login() {
    let navigate = useNavigate();
    let location = useLocation();
    const [errorMessage, setErrorMessage] = useState('');
    let from = location.state?.from?.pathname || "/";
    const dispatch = useDispatch();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const loggedIn = useSelector(isLoggedIn);

    useEffect(() => {
        document.title = 'Login - JournoResearch';
    }, [])

    useEffect(() => {
        if (loggedIn) {
            navigate('/');
        }
    }, [loggedIn, navigate])
   
    const handleSubmit = async (e) => {
        e.preventDefault();
        dispatch(toggleLoading());
        try {
            const resCookie =  await apiClient.get('/sanctum/csrf-cookie');
            if (resCookie.status === 204) {
                await apiClient.post("/logout");
                const resLogin = await dispatch(signIn({email, password})).unwrap();
                if (resLogin.status === 204) {
                    dispatch(setLoggedOut());
                }
                if (resLogin.status === 200) {
                    navigate(from, { replace: true });
                }
            }
        } catch (error) {
            dispatch(toggleLoading());
            dispatch(setLoggedOut());
            setErrorMessage(error?.message);
        }
        
    }
    const loading = useSelector(isLoading);
    return (
        <section className="gradient-custom">
            <div className="container py-5 h-100">
                <div className="row d-flex justify-content-center align-items-center">
                    <div className="col-12 col-md-8 col-lg-6 col-xl-5">
                        <div className="card bg-dark text-white">
                            <div className="card-body p-5 pb-3 text-center">
                                <div className="mb-md-5 mt-md-4 pb-1">
                                    <h2 className="fw-bold mb-2 text-uppercase">Login</h2>
                                    <p className="text-white-50 mb-5">Please enter your login and password!</p>
                                    <form onSubmit={handleSubmit}>
                                        <div className="form-outline form-white mb-4">
                                            <input
                                                type="email"
                                                name="email"
                                                placeholder="Email"
                                                value={email}
                                                className="form-control form-control-lg"
                                                onChange={e => setEmail(e.target.value)}
                                                required
                                            />
                                        </div>
                                        <div className="form-outline form-white mb-4">
                                            <input
                                                type="password"
                                                name="password"
                                                placeholder="Password"
                                                value={password}
                                                className="form-control form-control-lg"
                                                onChange={e => setPassword(e.target.value)}
                                                required
                                            />
                                        </div>
                                        {!loading ? <div><button type="submit" className="btn btn-outline-light btn-lg px-5">Login</button><div>{errorMessage}</div></div> : <Loading fullScreen='false'/>}
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}