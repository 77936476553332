import React, { useEffect, useState } from 'react';
import './statistics.scss'
import { DoughnutChart } from '../../components/DoughnutChart';
import { fetchStatistics, statistics, resetStatistics, fetchOpenStatistics, openStatistics, resetOpenStatistics } from './campaignsSlice';
import Select from 'react-select';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { Loading } from '../loader/Loading';
import { LineChart } from '../../components/LineChart';
import { diffBetweenDates } from '../../help/help';
import axios from 'axios';

export function Statistics(props) {
    const dispatch = useDispatch();
    const [isLoading, setLoading] = useState(false);
    const [isDonutLoading, setDonutLoading] = useState(false);
    const localStatistics = useSelector(statistics);
    const localOpenStatistics = useSelector(openStatistics);
    const [endDate, setEndDate] = useState(Date.now());
    const { id } = useParams();
    const [resolution, setResolution] = useState(null);
    const cancelToken = axios.CancelToken;
    const source = cancelToken.source()
    const options = [
        { value: 'all', label: 'All' },
        { value: 0, label: 'Step 1' },
        { value: 1, label: 'Step 2' },
        { value: 2, label: 'Step 3' },
        { value: 3, label: 'Step 4' },
        { value: 4, label: 'Step 5' },
        { value: 5, label: 'Step 6' },
        { value: 6, label: 'Step 7' },
        { value: 7, label: 'Step 8' },
        { value: 8, label: 'Step 9' },
        { value: 9, label: 'Step 10' },
    ];

    const getStatistics = async (start_date, step) => {
        try {
            setLoading(true)
            await Promise.all([dispatch(fetchStatistics({id, step, source})).unwrap(), getChartStatistics(start_date)]);
            setLoading(false)
        } catch (error) {
            if (error?.message !== 'axios request canceled') {
                setLoading(false);
            }
        }
    }

    const getChartStatistics = async () => {
        try {
            setLoading(true)
            const openStatistics = await dispatch(fetchOpenStatistics({id, resolution: 0, source})).unwrap();
            if (openStatistics) {
                setResolution(openStatistics.resolution)
                setEndDate(openStatistics.result[openStatistics.result.length - 1].x);
            }
            setLoading(false)
        } catch (error) {
            if (error?.message !== 'axios request canceled') {
                setLoading(false);
            }
        }
    }

    const handleSelectStep = async (step) => {
        try {
            setDonutLoading(true)
            await dispatch(fetchStatistics({id, step, source})).unwrap()
            setDonutLoading(false)
        } catch (error) {
            if (error?.message !== 'axios request canceled') {
                setLoading(false);
            }
        }
    }

    const setChartData = (chartData, percentage, labels = ["Unopened", "Opened"]) => {
        return ({
            labels,
            datasets: [{
                data: chartData,
                backgroundColor: ["#0000001a", "#0d6efd"],
                text: percentage + '%'
            }],
        });
    }
    const formatDate = (date) => {
        const dateFormat = new Date(date)
        return new Intl.DateTimeFormat('en-GB',{'dateStyle':'medium', 'timeStyle':'medium'}).format(dateFormat)
    }

    const setLineChartData = (chartData) => {
        return ({
            label: 'Opened',
            borderColor: 'blue',
            orderWidth: 2,
            pointRadius: 1,
            hoverPointRadius: 2,
            data: chartData?.map((s) => ({x: formatDate(s.x), y: s.y}))
        });
    }

    const handleChangeTime = async (time) => {
        try {
            setResolution(time);
            dispatch(resetOpenStatistics());
            setLoading(true)
            await dispatch(fetchOpenStatistics({id, resolution: time, source})).unwrap()
            setLoading(false)
        } catch (error) {
            if (error?.message !== 'axios request canceled') {
                setLoading(false);
            }
        }
    }

    const disableByTime = (time) => {
        let period  = diffBetweenDates(props.start_date, endDate);
        if (period > 1 && time < 30) {
            return true;
        }
        if (period > 2 && time < 60) {
            return true;
        }
        return false;
    }

    useEffect(() => {
        if (props.start_date && props.is_folder === 0) {
            getStatistics(props.start_date, 'all');
        }
        return (() => {
            dispatch(resetStatistics());
            dispatch(resetOpenStatistics());
            source.cancel('axios request canceled');
        })
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        return (() => {
            dispatch(resetStatistics());
            dispatch(resetOpenStatistics());
            source.cancel('axios request canceled');
        })
    }, [id]) // eslint-disable-line react-hooks/exhaustive-deps


    return (
        localStatistics && props.start_date ? 
        <div className="row">
            <div className="col-lg-12">
                <div className="wrapper-statistics">
                    <h5>Statistics</h5>
                    <div className="pb-4 d-flex">   
                        <Select className="select" options={options.slice(0, props.max_steps === 1 ? props.max_steps : props.max_steps + 1)} onChange={(o) => handleSelectStep(o.value)} defaultValue={options[0]}/>
                        {isLoading && !localStatistics ? <Loading fullScreen="true" default="true"/> : <></>}
                    </div>
                        <div className="wrapper-charts" style={{width: '100%'}}>
                            {isDonutLoading ? <Loading fullScreen="true" default="true"/> : <>
                                <div className="chart">
                                    <p>Opened x1</p>
                                    <DoughnutChart data={setChartData([localStatistics.items_count - localStatistics.opened_count, localStatistics.opened_count], localStatistics.opened_percentage)}/>
                                </div>
                                <div className="chart">
                                    <p>Opened x2</p>
                                    <DoughnutChart data={setChartData([localStatistics.items_count - localStatistics.opened_2_count, localStatistics.opened_2_count], localStatistics.opened_2_percentage)}/>
                                </div>
                                <div className="chart">
                                    <p>Opened x3</p>
                                    <DoughnutChart data={setChartData([localStatistics.items_count - localStatistics.opened_3_count, localStatistics.opened_3_count], localStatistics.opened_3_percentage)}/>
                                </div>
                                <div className="chart">
                                    <p>Opened > x4</p>
                                    <DoughnutChart data={setChartData([localStatistics.items_count - localStatistics.opened_4_count, localStatistics.opened_4_count], localStatistics.opened_4_percentage)}/>
                                </div>
                                <div className="divider">
                                </div>
                                <div className="chart">
                                    <p>Bounced</p>
                                    <DoughnutChart data={setChartData([localStatistics.items_count - localStatistics.bounce_count, localStatistics.bounce_count], localStatistics.bounce_percentage, ['Not Bounced','Bounced'])}/>
                                </div>
                                <div className="chart">
                                    <p>Unsubscribed</p>
                                    <DoughnutChart data={setChartData([localStatistics.items_count - localStatistics.unsubscribed_count, localStatistics.unsubscribed_count], localStatistics.unsubscribed_percentage, ['Not Unsubscribed','Unsubscribed'])}/>
                                </div>
                                <div className="divider"></div>
                            </>}
                            {isLoading && !localOpenStatistics?.result ? <Loading fullScreen="true" default="true"/>  :
                                !isDonutLoading ?
                                    <div className="wrapper-open-stats">
                                        <LineChart data={setLineChartData(localOpenStatistics.result)} resolution={resolution}/>
                                        <div className='ms-2 mt-4 wrapper-btns'>
                                            <button className={`btn btn-primary mb-1 ${parseInt(resolution) === 10 ? 'active' : ''}`} onClick={() => handleChangeTime(10)}  disabled={disableByTime(10)}>10 min</button>
                                            <button className={`btn btn-primary mb-1 ${parseInt(resolution) === 30 ? 'active' : ''}`} onClick={() => handleChangeTime(30)} disabled={disableByTime(30)}>30 min</button>
                                            <button className={`btn btn-primary mb-1 ${parseInt(resolution) === 60 ? 'active' : ''}`} onClick={() => handleChangeTime(60)} disabled={disableByTime(60)}>1 hour</button>
                                            <button className={`btn btn-primary mb-1 ${parseInt(resolution) === 4 * 60 ? 'active' : ''}`} onClick={() => handleChangeTime(4 * 60)} disabled={disableByTime(4 * 60)}>4 hours</button>
                                            <button className={`btn btn-primary mb-1 ${parseInt(resolution) === 24 * 60 ? 'active' : ''}`} onClick={() => handleChangeTime(24 * 60)} disabled={disableByTime(24 * 60)}>1 day</button>
                                        </div>
                                    </div> : ''
                            }
                        </div>
                </div>
            </div>
        </div>
        : <></>
    )
}