import { initializeApp } from "firebase/app";
import {
    getToken,
    getMessaging,
    onMessage,
    isSupported,
} from "firebase/messaging";

const firebaseConfig = {
    apiKey: "AIzaSyCPsNeaQODZpAyFXBdW0FYbHssiHPA993I",
    authDomain: "journoresearch-2ded8.firebaseapp.com",
    projectId: "journoresearch-2ded8",
    storageBucket: "journoresearch-2ded8.appspot.com",
    messagingSenderId: "295289458692",
    appId: "1:295289458692:web:fd4fa856e3b6cfa55a2551",
};

const firebaseApp = initializeApp(firebaseConfig);

export const getFirebaseMessaging = async () => {
    const _isSupported = await isSupported();
    if (!_isSupported) throw new Error("Firebase messaging is not supported");
    return getMessaging(firebaseApp);
};

export const getFirebaseToken = (messaging) =>
    getToken(messaging, {
        vapidKey:
            "BGS5zTmTTg5PjsiQXKks8imywuuVlMxx3S8R6JeK6Q0edoqdYVVCDoL_2ksYxwjYWFi3DLx_RPxSx0bHjxIBaN4",
    });

export const setFirebaseOnMessage = (messaging) => {
    onMessage(messaging, (payload) => {
        console.log("Message received. ", payload);
        new Notification(payload.data.title, {
            body: payload.data.body,
            icon: payload.data.icon,
        });
    });
};
