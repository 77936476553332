import axios from 'axios';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchDataWarehouse, getParamsForURLQuery, setSearchName  } from './dataWarehouseSlice';

export default function FilterName() {

    const cancelToken = axios.CancelToken;
    const source = cancelToken.source();
    const dispatch = useDispatch();
    const params = useSelector(getParamsForURLQuery); 

    const onBlurFilterName = (val) => {
        if (params.searchName !== val) {
            dispatch(setSearchName(val));
            dispatch(fetchDataWarehouse({
                page: 1,
                ...params,
                searchName: val,
                source}));
        }
	}
    
    return (<input type="text"
        className="form-control"
        placeholder="Search by name"
        onBlur={(e) => onBlurFilterName(e.currentTarget.value)}
        defaultValue={params.searchName}
        onKeyPress={(e) => e.key === 'Enter' && onBlurFilterName(e.currentTarget.value)}
    />)
}