
import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Nav from '../../components/Nav';
import { fetchSites, getLoadingSites, getSites, storeApprovalItem, getLoading, getError, fetchManagers, getLoadingManagers, getManagers, getLoadingCategories, getCategories, getQuestions, fetchApprovalItemQuestions, getLoadingQuestions, fetchClients, getClients, getLoadingClients } from './approvalItemsSlice';
import Select from 'react-select';
import TableApprovalItems from './TableApprovalItems';
import Alert from '../../components/Alert';
import Modal from '../../components/Modal';
import Wrapper from '../../components/Wrapper';
import { Loading } from '../loader/Loading';


export default function ItemType({type}) {

    const cancelToken = axios.CancelToken;
    const source = cancelToken.source();
    const TYPES = {'Press Release': 1, 'Data': 2}
    const dispatch = useDispatch();
    const sites = useSelector(getSites);
    const clients = useSelector(getClients);
    const managers = useSelector(getManagers);
    const categories = useSelector(getCategories);
    const questions = useSelector(getQuestions);
    const loading = useSelector(getLoading);
    const loadingSites = useSelector(getLoadingSites);
    const loadingManagers = useSelector(getLoadingManagers);
    const loadingCategories = useSelector(getLoadingCategories);
    const loadingQuestions = useSelector(getLoadingQuestions);
    const loadingClients = useSelector(getLoadingClients);
    const selectedSite = useRef(null);
    const selectedManager = useRef(null);
    const selectedCategory = useRef(null);
    const url = useRef(null);
    const description = useRef(null);
    const error = useSelector(getError);
    const [answers, setAnswers] = useState([]);
    const _questions = useRef([])
    const [detailAnswer, setDetailAnswer] = useState(null);
    const [isValidSendForApproval, setIsValidSendForApproval] = useState(false);
    const [remount, setRemount] = useState(0);
    const [isTermsAccepted, setIsTermsAccepted] = useState(false);

    const _getSites = () => {
        dispatch(fetchSites({source}))
    }

    const _getManagers = () => {
        dispatch(fetchManagers({source, itemType: TYPES[type]}))
    }

    const _getQuestions = () => {
        dispatch(fetchApprovalItemQuestions({source, itemType: TYPES[type]}));
    }

    const sendForApproval = async () => {
        if (allAnswersYes()) {
            let site = selectedSite.current?.getValue();
            let manager = selectedManager.current?.getValue();
            let categories = selectedCategory.current?.getValue()
            let _url = url.current?.value
            let data = {
                'item_type_id': TYPES[type],
                'site': site.length > 0 ? site[0]?.label : '',
                'url': _url,
                'assigned_user': manager.length > 0 ? manager[0]?.value : '',
                'description': description.current?.value,
                'categories': categories?.map(s => s.value).join()
            }
            if (site.length > 0 && _url.length > 0 && manager.length > 0) {
                dispatch(fetchClients({source, site: data.site}));
                dispatch(storeApprovalItem({source, data}));
            }
        }
        
    }
    
    const formatOptionLabelSites = ({ value, label, company }) => (
        <div>
            <div style={{ display: "flex" }}>
                <div style={{whiteSpace: "nowrap"}}>{label}</div>
                <div className="wrapper-text-company">
                    <span style={{color: 'silver'}}>{company ? '(' + company + ')' : ''}</span>
                </div>
            </div>
        </div>
    );

    const formatOptionLabelManagers = ({ value, label, count }) => (
        <div>
            <div style={{ display: "flex" }}>
                <div style={{whiteSpace: "nowrap"}}>{label}</div>
                <div className="wrapper-text-company">
                    <span style={{color: 'silver'}}>{'(' + count + ')'}</span>
                </div>
            </div>
        </div>
    );

    const filterOption = ({data}, input) => {
        return data.label.toLowerCase().includes(input.toLowerCase()) || data.company.toLowerCase().includes(input.toLowerCase());
    };

    useEffect(() => {
        _getSites();
        _getManagers();
        _getQuestions();
        return (() => {
            source.cancel('axios request canceled');
        })
    }, [type]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        let site = selectedSite.current?.getValue();
        let manager = selectedManager.current?.getValue();
        let _url = url.current?.value
        if (site?.length > 0 && _url?.length > 0 && manager?.length > 0) {
            setIsValidSendForApproval(true);
        } else {
            setIsValidSendForApproval(false)
        }
    }, [remount]); // eslint-disable-line react-hooks/exhaustive-deps

    const modalCheckListProps = {
        title: 'Check List',
        target: 'check-list',
        disableFooter: true,
        size: 'lg'
    }

    const secondModalProps = {
        title: 'Suggestions',
        target: 'second-modal',
        size: 'lg',
        nameSaveButton: 'unset'
    }

    const detailModalProps = {
        title: 'Info',
        target: 'detail-modal',
        size: 'lg',
        disableFooter: true
    }

    const nextStepsModalProps = {
        title: 'Next Steps',
        target: 'next-steps-modal',
        size: 'lg',
        disableFooter: true
    }

    const onChangeValue = (event, question) => {
        _questions.current = _questions.current.filter(q => q.id !== question.id);
        _questions.current.push({
            ...question,
            checked: event.target.value === 'NO' ? false : true
        });
        setAnswers(_questions.current);
    }

    const resetAnswers = () => {
        document.querySelectorAll('.question').forEach(q => q.checked = false);
        _questions.current = [];
        setAnswers([]);
        setIsTermsAccepted(false);
    }

    const allAnswersYes = () => {
        return answers.filter(a => a.checked === false).length === 0;
    }

    return (<>  
        {error ? <Alert error={error} ></Alert> : ''}
        <Modal props={modalCheckListProps}>
                {loadingQuestions ?<Loading fullScreen="true" /> : <>
                    <div className='d-grid mb-4' style={{gridTemplateColumns: "1fr 45px 45px", gap: "7px"}}>
                        <div><b>Answers:</b></div>
                        <div className='text-center'><b>YES</b></div>
                        <div className='text-center'><b>NO</b></div>
                        {questions?.map((q,idx) =><Wrapper key={idx}>
                            <div>{q.question}</div>
                            <div className='text-center'><input type="radio" className="question form-check-input" name={`${q.id}-question`} onChange={(e) => onChangeValue(e , q)} value="YES" /></div>
                            <div className='text-center'><input type="radio" className="question form-check-input" name={`${q.id}-question`} onChange={(e) => onChangeValue(e , q)} value="NO" /></div>
                            </Wrapper>
                        )}
                    </div>
                    <div className='d-grid mb-4 pt-4' style={{gridTemplateColumns: "1fr 45px 45px", gap: "7px", borderTop: '1px dashed gray'}}>
                        <div>Manager will check your release in 24-48-h (during work week)</div>
                        <div className='text-center'><input type="checkbox" className="form-check-input" onChange={() => setIsTermsAccepted(!isTermsAccepted)} value="Ok"  checked={isTermsAccepted}/><span className='ms-1'><b>Ok</b></span></div>
                    </div>
                    <div className='d-flex justify-content-end'>
                        <button className="btn btn-primary ms-2" disabled={(answers.length !== questions.length) || answers.length === 0 || !isTermsAccepted} data-bs-dismiss="modal" data-bs-target={allAnswersYes() ? '#next-steps-modal' : '#second-modal'} data-bs-toggle="modal" onClick={sendForApproval}>Send for approval</button>
                    </div>
                </>
                }
            </Modal>
            <Modal props={secondModalProps}>
                <div>Your {type} can’t be check.</div>
                <div className='mb-2'>What to do:</div>
                {
                    answers?.map((a,idx) => {
                        return !a.checked ? <div key={idx} className='d-flex align-items-center mb-2 justify-content-between'>
                            <div className='me-2'>{a.suggestion}</div>
                            <i className="bi bi-info-circle" data-bs-dismiss="modal" data-bs-target="#detail-modal" data-bs-toggle="modal" onClick={() => setDetailAnswer(a.detail)}/>
                        </div> : ''
                    })
                }
            </Modal>
            <Modal props={detailModalProps}>
                <div>{detailAnswer}</div>
                <div className='d-flex justify-content-end'>
                    <button className="btn btn-primary ms-2" disabled={(answers.length !== questions.length) || answers.length === 0} data-bs-dismiss="modal" data-bs-target="#second-modal" data-bs-toggle="modal">Back</button>
                </div>
            </Modal>
            <Modal props={nextStepsModalProps}>
            {loadingClients ?<Loading fullScreen="true" /> : <div className='mb-4'>
                    <h5>You can follow work on:</h5>
                    {clients?.map((c,idx) => <li key={idx} className="ms-3">{c.site}</li>)}
                    {clients?.length === 0 ? <div className='d-flex'>No clients found</div> : ''}
                </div>
            }
            </Modal>
        {!loading ? 
        <div className='approval-item-add'>
            <Nav selectedListItem={{'name': type + ' Approval'}}/>
            <div className='d-flex align-items-center add-item'>
                <div className='d-flex align-items-center flex-col flex-wrap flex-xl-nowrap' style={{flex: 1}}>
                    <Select className="select me-2 mb-2" options={sites ? sites.map((c) => ({value: c.site, label: c.site, company: c.company})): ''} ref={selectedSite} placeholder="Websites" isLoading={loadingSites} formatOptionLabel={formatOptionLabelSites}
                        filterOption={filterOption} onChange={() => setRemount(remount + 1)}/>
                    <div className="me-2 input mb-2"><input type="text" placeholder={`Link to ${type}`} ref={url} className="form-control" required onChange={() => setRemount(remount + 1)}></input></div>
                    <div className="me-2 input input-comment mb-2"><textarea ref={description} className="me-2 form-control" placeholder='Comments'></textarea></div>
                    <Select className="select me-2 mb-2 select-category" 
                        options={categories ? categories.map((c) => ({value: c.id, label: c.name})): ''}
                        ref={selectedCategory}
                        placeholder="Categories"
                        isLoading={loadingCategories}
                        isMulti
                        onChange={() => setRemount(remount + 1)}
                        />
                    <Select className="select me-2 mb-2 select-manager"
                        options={managers ? managers.map((c) => ({value: c.id, label: c.name, count: c.count})): ''}
                        ref={selectedManager}
                        placeholder="Managers"
                        isLoading={loadingManagers}
                        formatOptionLabel={formatOptionLabelManagers}
                        onChange={() => setRemount(remount + 1)}/>
                </div>
                <button className="btn btn-primary ms-2" onClick={resetAnswers} data-bs-target="#check-list" data-bs-toggle="modal" data-bs-dismiss="modal" disabled={!isValidSendForApproval}>Send for approval</button>
            </div>
        </div> : ''}
        <TableApprovalItems type={TYPES[type]} key={TYPES[type]}/>
    </>)
}