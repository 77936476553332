import React from 'react';
import { EditorTinyMCE } from './EditorTinyMCE';
import { UserSelect } from './UserSelect';

export function PitchCol({selectedItem, setSelectedItem, users}) {

    return (<>
        <EditorTinyMCE selectedItem={selectedItem} setSelectedItem={setSelectedItem} type={'pitch_description'} label={'Pitch description'}/>
        <UserSelect selectedItem={selectedItem} setSelectedItem={setSelectedItem} itemKey='pitch_user' users={users}></UserSelect>
    </>);
}
