import { Line } from "react-chartjs-2";
import React from "react";
import 'chart.js/auto';
export function LineChart(props) {
    const weekday = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

    const formatTime = (time, resolution) => {
      const data = new Date(time)
      const hour = ("0" + data.getHours()).slice(-2);
      const minutes = ("0" + data.getMinutes()).slice(-2);
      const day = data.getDay();
      if (resolution < 60) {
        return hour + ':' + minutes;
      }
      return weekday[day] + ',' + hour + ':' + minutes;
    }

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        interaction: {
          mode: "index",
          intersect: false,
        },
        scales: {
          y: {
            ticks: {
                beginAtZero:true
            }
          },
          x: {
              ticks: {
                // For a category axis, the val is the index so the lookup via getLabelForValue is needed
                callback: function(val, index) {
                  // Hide the label of every 2nd dataset
                  let step = 1;
                  switch(props.resolution) {
                    case 10:
                      step = 4;
                      break;
                    case 30:
                      step = 4;
                      break;
                    case 60:
                      step = 2;
                      break;
                    case 240:
                      step = 2;
                      break;
                    case 1440:
                      step = 1;
                      break;
                    default:
                       step = 1;
                  }
                  return index % step === 0 ? formatTime(this.getLabelForValue(val), props.resolution) : '';
                },
              }
          }   
        },
        // plugins: {
        //     zoom: {
        //       zoom: {
        //         wheel: {
        //           enabled: true,
        //         },
        //         pinch: {
        //           enabled: true
        //         },
        //         mode: 'xy',
        //       }
        //         // pan: {
        //         //     enabled: true,
        //         //     mode: "x",
        //         //     // speed: 100
        //         // }
        //     }
        // }
    };
        return (
          <div style={{width: "75%", minWidth: '320px', maxWidth:'770px'}}>
            <Line
              type="line"
              data= { {datasets: [props.data]} }
              options={options}
              style={{width:"100%", height: "320px"}}
              height={320}
            />
          </div>
        );
      };